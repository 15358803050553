import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Rating from "@mui/material/Rating";
import { Carousel } from "react-responsive-carousel";
import Stack from "@mui/material/Stack";
import { GoLocation } from "react-icons/go";
import { BiPhoneCall } from "react-icons/bi";
import Loader from "../../Loader/Loader";
import { animalsbuylistdetails } from "../../Redux/Actions/actions";

function BuysellDetails() {
  const [show, setShow] = useState(false);
  const [load, setLoad] = useState(true);
  const [imageindex, setImageindex] = useState(0);
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    dispatch(animalsbuylistdetails({ id: id })).then((res) => {
      setLoad(false);
    });
  }, []);
  const animalbuydetails = useSelector(
    ({ animalsbuylistdetails }) => animalsbuylistdetails.payload
  );

  var tm = "tel:" + animalbuydetails?.mobile;
  return (
    <>
      {load ? (
        <>
          <Loader />
        </>
      ) : (
        <div>
          <section
            className="section banner"
            style={{ backgroundImage: "url('../Assests/upload/vet_bg.jpg')" }}
            data-img-width="1688"
            data-img-height="470"
            data-diff="100"
          ></section>
          <div class="page-title grey">
            <div class="container">
              <div class="title-area pull-left">
                <h2>BuySell Details</h2>
                <div class="bread">
                  <ol class="breadcrumb">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li class="active">BuySell Details</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <section class="section white">
            <div class="container">
              <div class="row">
                <div id="content" class="col-md-12 col-sm-12 col-xs-12">
                  <div class="row">
                    <div class="col-md-5 col-sm-5 col-xs-12">
                      <div class="product-images">
                        <a>
                          <Carousel emulateTouch={true}>
                            {Array.isArray(animalbuydetails?.photo) &&
                            animalbuydetails?.photo?.length > 0
                              ? animalbuydetails?.photo?.map(
                                  (dataimage, index) => (
                                    <img
                                      src={dataimage}
                                      width="40%"
                                      alt="Animal Image"
                                    />
                                  )
                                )
                              : false}
                          </Carousel>
                        </a>
                      </div>
                    </div>

                    <div class="col-md-7 col-sm-7 col-xs-12">
                      <div class="shop-desc">
                        <h3 style={{ textTransform: "capitalize" }}>
                          {animalbuydetails?.name}
                        </h3>
                        <small> ₹&nbsp;{animalbuydetails?.price}</small>
                        <p>{animalbuydetails?.about}</p>
                        <small style={{ color: "black" }}>
                          {animalbuydetails?.species} /{animalbuydetails?.breed}
                        </small>
                        <small
                          style={{
                            color: "black",
                            textTransform: "capitalize",
                          }}
                        >
                          {animalbuydetails?.gender} / {animalbuydetails?.age}
                        </small>
                        <div class="shopmeta">
                          <span style={{ fontWeight: "bold" }}>
                            <strong>
                              Phone &nbsp; &nbsp; &nbsp; : &nbsp;{" "}
                            </strong>
                            <a href={tm}>{animalbuydetails?.mobile}</a>
                          </span>
                          <span style={{ fontWeight: "bold" }}>
                            <strong>Address &nbsp; : &nbsp;</strong>
                            <a style={{ textTransform: "capitalize" }}>
                              {animalbuydetails?.street}{" "}
                              {animalbuydetails?.taluk}
                              {animalbuydetails?.city} &nbsp;
                            </a>
                            ,
                            <a style={{ textTransform: "capitalize" }}>
                              erode{" "}
                            </a>
                            ,
                            <a style={{ textTransform: "capitalize" }}>
                              {animalbuydetails?.district} -
                              {animalbuydetails?.pincode}
                            </a>
                          </span>
                        </div>
                        <button
                          style={{
                            margin: "15px 0px",
                            fontWeight: "bold",
                            backgroundColor: "orange",
                            color: "white",
                            fontSize: "25px",
                            borderRadius: "4px",
                            padding: "15px 100px",
                          }}
                        >
                          Buy
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr class="invis" />
          </section>
        </div>
      )}
    </>
  );
}

export default BuysellDetails;
