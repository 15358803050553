import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import { Link, useNavigate } from "react-router-dom";
import {
  addbuyanimals,
  animalslist,
  districtlist,
  taluklist,
} from "../../Redux/Actions/actions";

function Addbuysell() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const [photo, setPhoto] = useState([""]);
  const [districtvalue, setDistrictvalue] = useState("");
  const [breed, setBreed] = useState([]);
  const [species, setSpecies] = useState("");
  const [error, setError] = useState("");
  const [userdata, setUserdata] = useState({
    name: "",
    age: "",
    mobile: "",
    gender: "",
    photo: [],
    breed: "",
    about: "",
    price: "",
    doorno: "",
    street: "",
    taluk: "",
    city: "",
    district: "",
    pincode: "",
  });
  useEffect(() => {
    dispatch(animalslist());
    dispatch(districtlist());
    dispatch(taluklist());
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    Object.entries(userdata).map(([key, value]) => {
      if (key == "photo") {
        value.map((item, index) => {
          formData.append(`photo`, item);
        });
      } else {
        formData.append(key, value);
      }
    });
    setLoad(true);
    dispatch(addbuyanimals(formData))
      .then((res) => {
        setLoad(false);
        if (res?.data === "otp sent successfully") {
          localStorage.setItem("animalsellid", res?.id);
          navigate("/animals/AnimalOtp/" + userdata.mobile);
        }
      })
      .catch((err) => {
        setLoad(false);
        setError(err.response.data.data);
      });
  };

  const handledistrictChange = (e) => {
    setUserdata({
      ...userdata,
      [e.target.name]: e.target.value,
    });
    setDistrictvalue(e.target.value);
  };
  const resourceimage3 = (e, index) => {
    if (e.target.files && e.target.files.length > 0) {
      userdata[e.target.name][index] = e.target.files[0];
      const updatedPhoto = [...userdata[e.target.name]];
      setUserdata({
        ...userdata,
        [e.target.name]: updatedPhoto,
      });
      photo[index] = URL.createObjectURL(e.target.files[0]);
      const imageUrls = [...photo];
      setPhoto(imageUrls);
    }
  };
  const handleselectChange = (e) => {
    setBreed(e.target.value.breed);
    setSpecies(e.target.value.pet);
  };
  const handleChange = (e) => {
    setUserdata({
      ...userdata,
      species,
      [e.target.name]: e.target.value,
    });
  };
  const handleAddImage = () => {
    if (photo[photo.length - 1]) {
      photo.push("");
      const imageUrls = [...photo];
      setPhoto(imageUrls);
    }
  };
  const districtlistt = useSelector(({ districtlist }) => districtlist.payload);
  const taluklistt = useSelector(({ taluklist }) => taluklist.payload);
  const animallist = useSelector(({ animalslist }) => animalslist.payload);
  const err = useSelector(({ userlogin }) => userlogin.payload);

  return (
    <div>
      <section
        className="section banner"
        style={{ backgroundImage: "url('../Assests/upload/vet_bg.jpg')" }}
        data-img-width="1688"
        data-img-height="470"
        data-diff="100"
      ></section>
      <div className="page-title grey">
        <div className="container">
          <div className="title-area pull-left">
            <h2>Add Buy Sell</h2>
            <div className="bread">
              <ol className="breadcrumb">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="active">Add Buy Sell</li>
              </ol>
            </div>
            {/* <!-- end bread --> */}
          </div>
          {/* <!-- /.pull-right --> */}
          <div className="search pull-right">
            <form>{/* <!-- /input-group --> */}</form>
          </div>
          {/* <!-- /.pull-right --> */}
        </div>
      </div>
      {/* <!-- end page-title --> */}
      <Container>
        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4}>
              <Grid item xs={12} sm={12} md={12}>
                <Box
                  sx={{
                    mt: 8,
                    mx: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div style={{ fontWeight: "bold" }}>Personal Information</div>

                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    type="text"
                    onChange={handleChange}
                    id="name"
                    label="Name"
                    name="name"
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    type="number"
                    onChange={handleChange}
                    id="mobile"
                    label="Mobile Number"
                    name="mobile"
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    type="number"
                    onChange={handleChange}
                    id="age"
                    label="Age"
                    name="age"
                  />
                  <FormControl fullWidth style={{ marginTop: "10px" }}>
                    <InputLabel id="demo-simple-select-label">
                      Gender
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Gender"
                      name="gender"
                      onChange={handleChange}
                    >
                      <MenuItem selected>Select Gender</MenuItem>
                      <MenuItem value="male">Male</MenuItem>
                      <MenuItem value="female">Female</MenuItem>
                      <MenuItem value="others">Others</MenuItem>
                    </Select>
                  </FormControl>
                  <br />
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Species
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Species"
                      name="species"
                      onChange={handleselectChange}
                    >
                      <MenuItem>Select Species</MenuItem>
                      {Array.isArray(animallist) && animallist.length > 0
                        ? animallist.map((list) => (
                            <MenuItem value={list}>{list?.pet}</MenuItem>
                          ))
                        : false}
                    </Select>
                  </FormControl>
                  <br />
                  <FormControl fullWidth style={{ marginTop: "10px" }}>
                    <InputLabel id="demo-simple-select-label">Breed</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="breed"
                      name="breed"
                      onChange={handleChange}
                    >
                      <MenuItem selected>Select Breed</MenuItem>
                      {breed?.length > 0
                        ? breed?.map((list) => (
                            <MenuItem value={list?.breed}>
                              {list?.breed}
                            </MenuItem>
                          ))
                        : false}
                    </Select>
                  </FormControl>
                  <br />

                  <TextField
                    margin="normal"
                    fullWidth
                    required
                    type="text"
                    onChange={handleChange}
                    id="price"
                    label="Price "
                    name="price"
                  />
                  <span
                    style={{
                      color: "red",
                      marginTop: "10px",
                    }}
                  >
                    {error ? (
                      <Stack direction="row" spacing={1}>
                        <Chip label={error} color="#ED6C10" />
                      </Stack>
                    ) : (
                      false
                    )}
                  </span>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Grid item xs={12} sm={12} md={12}>
                <Box
                  sx={{
                    mt: 8,
                    mx: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div style={{ fontWeight: "bold" }}>Address Information</div>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    type="number"
                    onChange={handleChange}
                    id="doorno"
                    label="Door No"
                    name="doorno"
                  />
                  <TextField
                    margin="normal"
                    fullWidth
                    required
                    type="text"
                    onChange={handleChange}
                    id="street"
                    label="Street Name "
                    name="street"
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    type="text"
                    onChange={handleChange}
                    id="city"
                    label="City Name"
                    name="city"
                  />
                  <FormControl fullWidth>
                    <InputLabel id="daemo-simple-select-label">
                      District
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="district"
                      label="district"
                      onChange={(e) => handledistrictChange(e)}
                    >
                      <MenuItem selected>Select District</MenuItem>
                      {Array.isArray(districtlistt) && districtlistt.length > 0
                        ? districtlistt.map((Item) => (
                            <MenuItem value={Item?.district}>
                              {Item?.district}
                            </MenuItem>
                          ))
                        : false}
                    </Select>
                  </FormControl>
                  <br />
                  <FormControl fullWidth>
                    <InputLabel id="daemo-simple-select-label">
                      Taluk
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="taluk"
                      label="Taluk"
                      onChange={handleChange}
                    >
                      <MenuItem selected>Select Taluk</MenuItem>
                      {Array.isArray(taluklistt) && taluklistt.length > 0
                        ? taluklistt
                            .filter((data) => data.district === districtvalue)
                            .map((Item) => (
                              <MenuItem value={Item?.taluk}>
                                {Item?.taluk}
                              </MenuItem>
                            ))
                        : false}
                    </Select>
                  </FormControl>
                  <br />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    type="number"
                    onChange={handleChange}
                    id="pincode"
                    label="Pincode "
                    name="pincode"
                  />
                  <span
                    style={{
                      color: "red",
                      marginTop: "10px",
                    }}
                  >
                    {err ? err : false}
                  </span>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Grid item xs={12} sm={12} md={12}>
                <Box
                  sx={{
                    mt: 8,
                    mx: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {photo.map((dataimage, index) => (
                    <label htmlFor={"upload-resource-image" + index}>
                      <input
                        id={"upload-resource-image" + index}
                        name="photo"
                        onChange={(e) => resourceimage3(e, index)}
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                      />
                      <Button
                        color="primary"
                        variant="contained"
                        component="span"
                      >
                        {dataimage ? "Change Image" : "Select image"}
                      </Button>
                      {photo[index] ? (
                        <div
                          style={{
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={photo[index]}
                            style={{
                              width: "85px",
                              marginTop: "20px",
                              color: "red",
                            }}
                            alt="example"
                          />
                        </div>
                      ) : (
                        false
                      )}
                    </label>
                  ))}
                  {photo.length < 3 && photo[photo.length - 1] && (
                    <Button
                      color="primary"
                      onClick={handleAddImage}
                      variant="contained"
                      component="span"
                    >
                      Add Another image
                    </Button>
                  )}
                  <span
                    style={{
                      color: "red",
                      marginTop: "10px",
                    }}
                  >
                    {err ? err : false}
                  </span>
                </Box>
              </Grid>
            </Grid>
            <Grid xs={12} sm={12} md={12}>
              <Box
                sx={{
                  mx: 6,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  type="text"
                  onChange={handleChange}
                  id="About"
                  label="About"
                  name="about"
                />
              </Box>
            </Grid>
            <Grid xs={12} sm={12} md={12}>
              <Box
                sx={{
                  mb: 8,
                  mx: 6,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {load ? (
                  <Button
                    type="submit"
                    fullWidth
                    disabled
                    style={{ backgroundColor: "#ED6C10", color: "white" }}
                  >
                    Loading
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    fullWidth
                    style={{ backgroundColor: "#ED6C10", color: "white" }}
                  >
                    Submit
                  </Button>
                )}

                <Grid />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
}

export default Addbuysell;
