import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { FaStar } from "react-icons/fa";
import { BsFillStarFill } from "react-icons/bs";

import { resourcetypelist } from "../../Redux/Actions/actions";
import Loader from "../../Loader/Loader";
const Petclinic = () => {
  const [search, SetSearch] = useState("erode");
  const [load, setLoad] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resourcetypelist({ type: "PET_CLINIC" })).then((res) => {
      setLoad(false);
    });
  }, []);
  const handlesubmit = (e) => {
    e.preventDefault();
    e.currentTarget.reset();
    dispatch(resourcetypelist({ district: search, type: "PET_CLINIC" }));
  };
  const petlist = useSelector(
    ({ resourcetypelist }) => resourcetypelist.payload
  );
  return (
    <div>
      <>
        {load ? (
          <>
            <Loader />
          </>
        ) : (
          <div>
            <section
              className="section banner"
              style={{ backgroundImage: "url('../Assests/upload/vet_bg.jpg')" }}
              data-img-width="1688"
              data-img-height="470"
              data-diff="100"
            ></section>
            <div className="page-title grey">
              <div className="container">
                <div className="title-area pull-left">
                  <h2>Pet Clinic</h2>
                  <div className="bread">
                    <ol className="breadcrumb">
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li className="active">Pet Clinic</li>
                    </ol>
                  </div>
                  {/* <!-- end bread --> */}
                </div>
                {/* <!-- /.pull-right --> */}
                <div className="search pull-right">
                  <form onSubmit={handlesubmit}>
                    <div className="input-group">
                      <input
                        className="form-control"
                        type="search"
                        onChange={(e) => SetSearch(e.target.value)}
                        name="search"
                        placeholder=" Search... "
                      />
                      <span className="input-group-btn">
                        <button type="submit" className="btn btn-primary">
                          <i className="fa fa-search"></i>
                        </button>
                      </span>
                    </div>
                    {/* <!-- /input-group --> */}
                  </form>
                </div>
                {/* <!-- /.pull-right --> */}
              </div>
            </div>
            {/* <!-- end page-title --> */}

            <section className="section white">
              <div className="container">
                <div className="shop-top clearfix">
                  {/* <!-- /.pull-right --> */}

                  {/* <!-- /.pull-right --> */}
                </div>
                {/* <!-- end shop top --> */}

                <div className="row module-wrapper shop-layout text-center">
                  {Array.isArray(petlist) && petlist.length > 0 ? (
                    petlist.map((clinic) => (
                      <div className="col-md-3 col-sm-6 wdtShop grid cs-style-3">
                        <div className="img-wrap">
                          <Link to={`/Resourcedetails/${clinic?.id}`}>
                            <img
                              alt="clinic images"
                              src={clinic?.photos[0]}
                              className="img-responsive"
                            />
                          </Link>
                        </div>
                        <h4 style={{ padding: "10px  0 0px" }}>
                          <Link to={`/Resourcedetails/${clinic?.id}`}>
                            {clinic?.name}
                          </Link>
                        </h4>
                        <div
                          className="mt-2"
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                          }}
                        >
                          <p
                            style={{
                              textTransform: "capitalize",
                              fontWeight: "bold",
                            }}
                          >
                            {clinic?.district}
                          </p>
                          <p
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <span style={{ color: "orange" }}>
                              <BsFillStarFill />
                            </span>
                            <span
                              style={{
                                marginLeft: "10px",
                                color: "orange",
                                fontWeight: "bold",
                              }}
                            >
                              {clinic?.rating}
                            </span>
                          </p>
                        </div>
                      </div>
                    ))
                  ) : (
                    <h1>No Clinic Found</h1>
                  )}
                </div>
                <hr className="invis" />
              </div>
            </section>
          </div>
        )}
      </>
    </div>
  );
};

export default Petclinic;
