import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Grid,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Container,
  Card,
  Select,
  Button,
  Typography,
} from "@mui/material";
import { useNavigate, Link } from "react-router-dom";

function Ruminant() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userdata, setUserdata] = useState({
    phone: "",
    otp: "",
  });
  const [error, setError] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    if (userdata.phone.length != 10) {
      setError("Please enter valid phone number");
    } else {
      e.currentTarget.reset();
      navigate("/Doctorotp/" + userdata.phone);
      // dispatch(userlogin(userdata)).then((res) => {
      //   console.log(res);
      // });
    }
  };
  const handleChange = (e) => {
    setUserdata({
      ...userdata,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div>
      <section
        className="section banner"
        style={{ backgroundImage: "url('../Assests/upload/vet_bg.jpg')" }}
        data-img-width="1688"
        data-img-height="470"
        data-diff="100"
      ></section>
      <div class="page-title grey">
        <div class="container">
          <div class="title-area pull-left">
            <h2> Ruminant</h2>
            <div class="bread">
              <ol class="breadcrumb">
                <li>
                  <a href="/">Home</a>
                </li>
                <li class="active"> Ruminant</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <Container className="ml-5 mr-5 my-5 py-5">
        <h2 style={{ fontWeight: "bold" }}>Ruminant</h2>
      </Container>
    </div>
  );
}

export default Ruminant;
