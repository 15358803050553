//Customer
export const user = {
  login: {
    success: "userloginsuccess",
    error: "userloginerror",
  },
  usersendotp: {
    success: "usersendotpsuccess",
    error: "usersendotperror",
  },
  sendotp: {
    success: "userotpsuccess",
    error: "userotperror",
  },
  register: {
    success: "userresgitersuccess",
    error: "userregistererror",
  },
  getprofile: {
    success: "usergetprofilesuccess",
    failure: "usergetprofileerror",
  },

  resources: {
    success: "userresourcesuccess",
    error: "userresourceerror",
  },
  editrequest: {
    success: "usereditrequestsuccess",
    error: "usereditrequesterror",
  },
};
//Doctor
export const doctor = {
  login: {
    success: "userloginsuccess",
    error: "userloginerror",
  },
  signup: {
    success: "usersignupsuccess",
    error: "usersignuperror",
  },
  instantapproval: {
    success: "userinstantsuccess",
    error: "userinstanterror",
  },
  sendotp: {
    success: "userotpsuccess",
    error: "userotperror",
  },

  get: {
    success: "usergetsuccess",
    error: "usergeterror",
  },
  addrating: {
    success: "userratingsuccess",
    error: "userratingerror",
  },
};
export const newsfeed = {
  search: {
    success: "newsfeedsearchsuccess",
    failure: "newsfeedsearchfailure",
  },
  desc: {
    success: "newsfeeddescsuccess",
    failure: "newsfeeddescfailure",
  },
};
export const designation = {
  list: {
    success: "designnationsuccess",
    failure: "designationfailure",
  },
};
export const nearbydoctor = {
  doclist: {
    success: "findnearbydoclistsuccess",
    failure: "findnearbydoclistfailure",
  },
  doctordetails: {
    success: "findnearbydoctordetailssuccess",
    failure: "findnearbydoctordetailsfailure",
  },
  resources: {
    success: "findnearbyresourcessuccess",
    failure: "findnearbyresourcesfailure",
  },
  resourcesdetails: {
    success: "findnearbyresourcesdetailssuccess",
    failure: "findnearbyresourcesdetailsfailure",
  },
};
export const nearbyanimals = {
  buy: {
    success: "buyanimalsuccess",
    failure: "buyanimalfailure",
  },
};

export const district = {
  get: {
    success: "districtgetsuccess",
    failure: "districtgetfailure",
  },
};
export const taluk = {
  get: {
    success: "talukgetsuccess",
    failure: "talukgetfailure",
  },
};
export const resources = {
  add: {
    success: "resourcesaddsuccess",
    failure: "resourcesaddfailure",
  },
  list: {
    success: "resourceslistsuccess",
    failure: "resourceslistfailure",
  },
  typelist: {
    success: "resourcestypelistsuccess",
    failure: "resourcestypelistfailure",
  },
  details: {
    success: "resourcesdetailssuccess",
    failure: "resourcesdetailsfailure",
  },
  listdetails: {
    success: "resourceslistdetailsssuccess",
    failure: "resourceslistdetailsfailure",
  },
  addrating: {
    success: "findnearbyaddratingsuccess",
    failure: "findnearbyaddratingfailure",
  },
};
export const Animals = {
  addbuy: {
    success: "animalsbuyaddsuccess",
    failure: "animalsbuyaddfailure",
  },
  addadoption: {
    success: "animalsaddadoptionsuccess",
    failure: "animalsaddadoptionfailure",
  },
  addmatchfind: {
    success: "animalsaddmatchfindsuccess",
    failure: "animalsaddmatchfindfailure",
  },
  verifysell: {
    success: "animalsverifysellsuccess",
    failure: "animalsverifysellfailure",
  },
  list: {
    success: "animallistsuccess",
    failure: "animallistfailure",
  },
  buy: {
    success: "animlsbuysuccess",
    failure: "animlsbuyfailure",
  },
  adoption: {
    success: "animlsadoptsuccess",
    failure: "animlsadoptfailure",
  },
  matchfind: {
    success: "animlsmatchfindsuccess",
    failure: "animlsmatchfindfailure",
  },
};
export const Animaldetails = {
  buy: {
    success: "animlsbuydetailssuccess",
    failure: "animlsbuydetailsfailure",
  },
  adoption: {
    success: "animlsadoptdetailssuccess",
    failure: "animlsadoptdetailsfailure",
  },
  matchfind: {
    success: "animlsmatchfinddetailssuccess",
    failure: "animlsmatchfinddetailsfailure",
  },
};
export const RecentNews = {
  get: {
    success: "recentnewsgetsuccess",
    failure: "recentnewsgetfailure",
  },
};
export const Whatsup = {
  get: {
    success: "whatsupgetsuccess",
    failure: "whatsupgetfailure",
  },
};
export const FeedFormulation = {
  getlist: {
    success: "feedformulationgetsuccess",
    failure: "feedformulationgetfailure",
  },
};
//digirecords
export const digirecords = {
  polutrylist: {
    success: "digirecordslistsuccess",
    failure: "digirecordslistfailure",
  },
};
export const adddigianimals = {
  polutrylist: {
    success: "digianimalslistsuccess",
    failure: "digianimalslistfailure",
  },
};
export const editdigianimals = {
  polutrylist: {
    success: "digianimalslistsuccess",
    failure: "digianimalslistfailure",
  },
};
export const createdigirecords = {
  polutrylist: {
    success: "digianimalslistsuccess",
    failure: "digianimalslistfailure",
  },
};
