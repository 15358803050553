import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Chip, Grid } from "@mui/material";
import join from "./join.svg";
import { Link } from "react-router-dom";
import { Whatsuplink } from "../../Redux/Actions/actions";
function FormProjectGuide() {
  const [whatsapplink, setWhatsapplink] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(Whatsuplink()).then((res) => {
      setWhatsapplink(res?.data?.link);
    });
  }, []);
  console.log(whatsapplink);
  const whatslink = useSelector(({ Whatsuplink }) => Whatsuplink.payload);
  console.log("link", whatslink);
  return (
    <div>
      <section
        className="section banner"
        style={{ backgroundImage: "url('../Assests/upload/vet_bg.jpg')" }}
        data-img-width="1688"
        data-img-height="470"
        data-diff="100"
      ></section>
      <div class="page-title grey mb-5">
        <div class="container">
          <div class="title-area pull-left">
            <h2>Form Project Guide</h2>
            <div class="bread">
              <ol class="breadcrumb">
                <li>
                  <a href="/">Home</a>
                </li>
                <li class="active">Form Project Guide</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <Grid
        className="mb-5 pb-5 mt-5 pt-5"
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <div className="mb-5 pb-5 mt-5 pt-5">
          <img src={join} style={{ width: "300px" }} />
        </div>
        <Typography className="mb-5 pb-5" variant="h5" component="h2">
          Join our Farm Project whatsapp group at single click
        </Typography>
        <div className="mb-5 pb-5">
          <a href={whatsapplink} target="_blank">
            <Chip
              style={{
                color: "white",
                border: "none",
                backgroundColor: "#ED6C10",
                fontWeight: "bold",
                cursor: "pointer",
              }}
              size="medium"
              label="Join Farm project"
              variant="outlined"
            />
          </a>
        </div>
      </Grid>
    </div>
  );
}

export default FormProjectGuide;
