import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Paper,
} from "@mui/material";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import { BsPencil } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import {
  doctorsignup,
  designationlist,
  districtlist,
  taluklist,
} from "../../../Redux/Actions/actions";
import { usergetprofile } from "../../../Redux/Actions/actions";
function Editprofile() {
  useEffect(() => {
    dispatch(usergetprofile()).then((res) => {});
  }, []);
  const profile = useSelector(({ usergetprofile }) => usergetprofile.payload);
  console.log(profile);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [photo, setPhoto] = useState("");
  const [districtvalue, setDistrictvalue] = useState("");
  const [signature, setSignature] = useState("");
  const [error, setError] = useState("");
  const [userdata, setUserdata] = useState({
    name: "",
    regno: "",
    mobile: "",
    designation: "",
    currentlyWorking: "",
    photo: "",
    doorNo: "",
    street: "",
    taluk: "",
    city: "",
    district: "",
    signature: "",
    pincode: "",
  });
  useEffect(() => {
    dispatch(designationlist());
    dispatch(districtlist());
    dispatch(taluklist());
  }, []);
  const designlist = useSelector(
    ({ designationlist }) => designationlist.payload
  );
  const districtlistt = useSelector(({ districtlist }) => districtlist.payload);
  const taluklistt = useSelector(({ taluklist }) => taluklist.payload);

  const formData = new FormData();
  Object.entries(userdata).map(([key, value]) => formData.append(key, value));
  const handleSubmit = (e) => {
    e.preventDefault();
    if (userdata.phone.length != 10) {
      setError("Please enter valid phone number");
    } else {
      dispatch(doctorsignup(formData))
        .then((res) => {
          if (res.data.data.message == "Successfully signed up") {
            navigate("/Doctorotp/" + userdata.phone);
          }
        })
        .catch((err) => setError(err.response.data.data));
    }
  };
  const docimageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setUserdata({ ...userdata, [e.target.name]: e.target.files[0] });
      setPhoto(URL.createObjectURL(e.target.files[0]));
    }
  };
  const docsignChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSignature(URL.createObjectURL(e.target.files[0]));
      setUserdata({ ...userdata, [e.target.name]: e.target.files[0] });
    }
  };
  const handleChange = (e) => {
    setUserdata({
      ...userdata,
      [e.target.name]: e.target.value,
    });
  };

  const handledistrictChange = (e) => {
    setUserdata({
      ...userdata,
      [e.target.name]: e.target.value,
    });
    setDistrictvalue(e.target.value);
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  return (
    <div>
      <Container>
        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4}>
              <Grid item xs={12} sm={12} md={12}>
                <Box
                  sx={{
                    mt: 8,
                    mx: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div style={{ fontWeight: "bold" }}>Personal Information</div>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    type="text"
                    value={profile?.name}
                    onChange={handleChange}
                    id="name"
                    label="Enter Name"
                    name="name"
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    type="number"
                    onChange={handleChange}
                    id="regno"
                    value={profile?.doc_reg_no}
                    label="Register Number"
                    name="regno"
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    type="number"
                    onChange={handleChange}
                    id="phone"
                    value={profile?.mobile}
                    label="Mobile Number"
                    name="phone"
                  />
                  <br />
                  <FormControl fullWidth>
                    <InputLabel id="daemo-simple-select-label">
                      Designation
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="designation"
                      label="Designation"
                      value={profile?.designation}
                      onChange={handleChange}
                    >
                      <MenuItem selected>Select Designation</MenuItem>
                      {Array.isArray(designlist) && designlist.length > 0
                        ? designlist.map((Item) => (
                            <MenuItem value={Item?.designation}>
                              {Item?.designation}
                            </MenuItem>
                          ))
                        : false}
                    </Select>
                  </FormControl>

                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    type="text"
                    value={profile?.currently_working}
                    onChange={handleChange}
                    id="currentlyWorking"
                    label="Currently Working"
                    name="currently_working"
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Grid item xs={12} sm={12} md={12}>
                <Box
                  sx={{
                    mt: 8,
                    mx: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div style={{ fontWeight: "bold" }}>Address Information</div>
                  <Grid sm={12} md={12} style={{ display: "flex" }}>
                    <Grid md={12}>
                      <TextField
                        margin="normal"
                        required
                        type="number"
                        onChange={handleChange}
                        id="doorNo"
                        label="Door No"
                        name="doorNo"
                      />
                    </Grid>
                    <Grid md={12}>
                      <TextField
                        margin="normal"
                        required
                        value={profile?.street}
                        type="text"
                        onChange={handleChange}
                        id="streetName"
                        label="Street Name "
                        name="street"
                      />
                    </Grid>
                  </Grid>
                  <br />
                  <FormControl fullWidth>
                    <InputLabel id="daemo-simple-select-label">
                      District
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="district"
                      value={profile?.district}
                      label="district"
                      onChange={(e) => handledistrictChange(e)}
                    >
                      <MenuItem selected>Select District</MenuItem>
                      {Array.isArray(districtlistt) && districtlistt.length > 0
                        ? districtlistt.map((Item) => (
                            <MenuItem value={Item?.district}>
                              {Item?.district}
                            </MenuItem>
                          ))
                        : false}
                    </Select>
                  </FormControl>
                  <br />
                  <br />
                  <FormControl fullWidth>
                    <InputLabel id="daemo-simple-select-label">
                      Taluk
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="taluk"
                      value={profile?.taluk}
                      label="Taluk"
                      onChange={handleChange}
                    >
                      <MenuItem selected>Select Taluk</MenuItem>
                      {Array.isArray(taluklistt) && taluklistt.length > 0
                        ? taluklistt
                            .filter((data) => data.district === districtvalue)
                            .map((Item) => (
                              <MenuItem value={Item?.taluk}>
                                {Item?.taluk}
                              </MenuItem>
                            ))
                        : false}
                    </Select>
                  </FormControl>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    type="text"
                    onChange={handleChange}
                    id="city"
                    value={profile?.city}
                    label="City Name"
                    name="city"
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    type="number"
                    value={profile?.pincode}
                    onChange={handleChange}
                    id="pincode"
                    label="Pincode"
                    name="pincode"
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Grid item xs={12} sm={12} md={12}>
                <Box
                  sx={{
                    mt: 8,
                    mx: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <label htmlFor="upload-document">
                    <input
                      id="upload-document"
                      name="photo"
                      onChange={docimageChange}
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                    />
                    <Button
                      color="primary"
                      variant="contained"
                      component="span"
                    >
                      Select Doctor Image
                    </Button>
                    {profile?.photo ? (
                      <div
                        style={{
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={profile?.photo}
                          style={{
                            width: "100px",
                            marginTop: "20px",
                            color: "red",
                          }}
                          alt="example"
                        />
                      </div>
                    ) : (
                      false
                    )}
                  </label>
                  <label htmlFor="upload-image">
                    <input
                      id="upload-image"
                      name="signature"
                      onChange={docsignChange}
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                    />
                    <Button
                      color="primary"
                      variant="contained"
                      component="span"
                    >
                      Select Doctor Signature
                    </Button>
                    {profile?.sign ? (
                      <div
                        style={{
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={profile?.sign}
                          style={{
                            width: "150px",
                            marginTop: "20px",
                            color: "red",
                          }}
                          alt="example"
                        />
                      </div>
                    ) : (
                      false
                    )}
                  </label>

                  <span
                    style={{
                      color: "red",
                      marginTop: "10px",
                      textAlign: "center",
                    }}
                  >
                    {error ? (
                      <Stack direction="row" spacing={1}>
                        <Chip
                          style={{
                            color: "#ED6C10",
                            marginBottom: "10px",
                            border: "2px solid #ED6C10",
                            background: "white",
                          }}
                          label={error}
                        />
                      </Stack>
                    ) : (
                      false
                    )}
                  </span>
                </Box>
              </Grid>
            </Grid>
            <Grid xs={12} sm={12} md={12}>
              <Box
                sx={{
                  mb: 8,
                  mx: 4,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Button
                  type="submit"
                  style={{ backgroundColor: "#ED6C10", color: "white" }}
                >
                  Update Profile
                </Button>
                <Grid />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
}

export default Editprofile;
