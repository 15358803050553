import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { createStore, compose, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import Reducer from "./Redux/Reducers/index";
import App from "./App";
import { setHeaderToken } from "./Services";
const store = createStore(Reducer, compose(applyMiddleware(thunk)));

const adminToken =
  localStorage.getItem("AuthUserToken") || localStorage.getItem("AuthDoctoken");
if (adminToken) {
  setHeaderToken(adminToken);
}

ReactDOM.render(
  <Provider store={store}>
    <App />,
  </Provider>,
  document.getElementById("root")
);
