import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  Container,
  Select,
  Button,
  Typography,
  Box,
} from "@mui/material";

import { AiOutlineMinus } from "react-icons/ai";
import { GrAdd } from "react-icons/gr";
import { useNavigate, Link } from "react-router-dom";
import chick from "../chick.jpg";
import PoultryForm from "./PoultryForm";
import { digipoultrylist } from ".././../../Redux/Actions/actions";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
  Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Feed Formulation",
    },
  },
};
const labels = ["CP", "ME"];

var tmparr = [1];
var SumofCP;
var SumofME;
var SumofPRICE;
function Poultry() {
  var [count, setCount] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [addform, setAddform] = useState([1]);
  const [show, setShow] = useState(true);
  const [data, setData] = useState("");
  const [price, setPrice] = useState("");
  const [cp, setCp] = useState(null);
  const [me, setMe] = useState("");
  const [CP, setCP] = useState("");
  const [ME, setME] = useState("");
  const [PRICE, setPRICE] = useState("");
  const [poultry, setPoultry] = useState({});

  useEffect(() => {
    dispatch(digipoultrylist());
  }, []);
  const polutryvalues = useSelector(
    ({ digipoultrylist }) => digipoultrylist.payload
  );

  const handleAdd = () => {
    setCount(count + 1);
    tmparr.push(count++);
    setAddform(tmparr);
  };
  const handleSub = () => {
    if (count === 1) {
      return;
    }
    setAddform(tmparr);
    setCount(count - 1);
    tmparr.pop();
    cp.pop();
    me.pop();
    price.pop();
  };
  const dataa = {
    labels,
    datasets: [
      {
        label: "Standard",
        data: [poultry?.cp_standard, poultry?.me_standard],
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Calculated",
        data: [CP, ME],
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
  const animal = useSelector(({ digipoultrylist }) => digipoultrylist.payload);

  const handleResult = () => {
    if (poultry === {} || cp === null) {
    } else {
      setShow(false);
      const SumofCP = cp.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0
      );
      const SumofME = me.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0
      );
      const SumofPRICE = price.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0
      );
      setCP(SumofCP);
      setME(SumofME);
      setPRICE(SumofPRICE);
    }
  };
  const selectChange = (e) => {
    setPoultry(animal[e.target.value]);
  };

  //charts
  let i = 0;
  const chartConfig = {
    responsive: true,
    legend: {
      display: true,
      position: "top",
      labels: {
        filter: function (legendItem, chartData) {
          i++;
          return i <= 6;
        },
      },
      onClick: (e) => e.stopPropagation(),
    },
    scales: {
      yAxes: [
        {
          barPercentage: 1.0,
          categoryPercentage: 1.0,
          ticks: {
            callback: function (value, index, values) {
              return value + " lei";
            },
            fontColor: "#75c0cc",
            beginAtZero: true,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            fontColor: "#75c0cc",
          },
          gridLines: {
            offsetGridLines: false,
          },
        },
      ],
    },
  };
  return (
    <div>
      <section
        className="section banner"
        style={{ backgroundImage: "url('../Assests/upload/vet_bg.jpg')" }}
        data-img-width="1688"
        data-img-height="470"
        data-diff="100"
      ></section>
      <div class="page-title grey">
        <div class="container">
          <div class="title-area pull-left">
            <h2> Poultry</h2>
            <div class="bread">
              <ol class="breadcrumb">
                <li>
                  <a href="/">Home</a>
                </li>
                <li class="active"> Poultry</li>
              </ol>
            </div>
          </div>

          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Select Poultry Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="species"
              onChange={selectChange}
              label="Select Poultry Type"
            >
              <MenuItem>Select Species</MenuItem>
              {Array.isArray(animal) && animal.length > 0
                ? animal.map((data, index) => (
                    <MenuItem value={index}>{data?.type}</MenuItem>
                  ))
                : false}
            </Select>
          </FormControl>
        </div>
      </div>
      <Box>
        <Container
          className="ml-5 mr-5 my-5 py-5"
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {show ? (
            <>
              <h2 style={{ fontWeight: "bold" }}>Poultry</h2>
              <img src={chick} style={{ width: "200px" }} />
              <Typography variant="h5" component="h2">
                How many Ingredients do you have ?
              </Typography>
              <Grid>
                <Button
                  onClick={handleSub}
                  style={{
                    backgroundColor: "#ED6C10",
                  }}
                >
                  <AiOutlineMinus />
                </Button>
                <span
                  style={{
                    fontSize: "30px",
                    margin: " 0px 10px",
                    verticalAlign: "center",
                  }}
                >
                  {count}
                </span>
                <Button
                  onClick={handleAdd}
                  style={{
                    backgroundColor: "#ED6C10",
                    color: "white",
                  }}
                >
                  <GrAdd />
                </Button>
                <br />
                <br />
              </Grid>
              <Grid container spacing={2}>
                {addform.map((data) => (
                  <Grid xs={12} sm={6} md={4} lg={3}>
                    <PoultryForm
                      setPrice={setPrice}
                      setCP={setCp}
                      setMe={setMe}
                    />
                  </Grid>
                ))}
              </Grid>
              <Button
                style={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  backgroundColor: "#ED6C10",
                  color: "white",
                }}
                onClick={handleResult}
              >
                Show Result
              </Button>
            </>
          ) : (
            <div>
              <Bar options={options} data={dataa} />
            </div>
          )}
        </Container>
      </Box>
    </div>
  );
}

export default Poultry;
