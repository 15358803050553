import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import { Carousel } from "react-responsive-carousel";
import { GoLocation } from "react-icons/go";
import { BiPhoneCall } from "react-icons/bi";
import { animalsmatchdetails } from "../../Redux/Actions/actions";
export const Raating = () => {
  const [valuer, setValuer] = useState("3");
  return (
    <Stack spacing={1}>
      <h5> Your experience with his doctor</h5>
      <Rating
        name="half-rating-read"
        defaultValue={1}
        precision={0.5}
        onChange={(e) => setValuer(e.target.value)}
      />
      {valuer}
    </Stack>
  );
};
const rating = true;

function MatchfindDeatils() {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    dispatch(animalsmatchdetails({ id: id }));
  }, []);
  const Matchfinddetails = useSelector(
    ({ animalsmatchdetails }) => animalsmatchdetails.payload
  );

  var tm = "tel:" + Matchfinddetails?.mobile;
  return (
    <div>
      <section
        className="section banner"
        style={{ backgroundImage: "url('../Assests/upload/vet_bg.jpg')" }}
        data-img-width="1688"
        data-img-height="470"
        data-diff="100"
      ></section>
      <div class="page-title grey">
        <div class="container">
          <div class="title-area pull-left">
            <h2>MatchFind Details</h2>
            <div class="bread">
              <ol class="breadcrumb">
                <li>
                  <a href="/">Home</a>
                </li>
                <li class="active">Shop</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <section class="section white">
        <div class="container">
          <div class="row">
            <div id="content" class="col-md-12 col-sm-12 col-xs-12">
              <div class="row">
                <div class="col-md-5 col-sm-5 col-xs-12">
                  <div class="product-images">
                    <a>
                      <Carousel emulateTouch={true}>
                        {Array.isArray(Matchfinddetails?.photo) &&
                        Matchfinddetails?.photo?.length > 0
                          ? Matchfinddetails?.photo?.map((dataimage, index) => (
                              <img
                                src={dataimage}
                                width="40%"
                                alt="Animal Image"
                              />
                            ))
                          : false}
                      </Carousel>
                    </a>
                  </div>
                </div>

                <div class="col-md-7 col-sm-7 col-xs-12">
                  <div class="shop-desc">
                    <h3
                      style={{
                        textTransform: "capitalize",
                      }}
                    >
                      {Matchfinddetails?.name}
                    </h3>
                    <small> ₹&nbsp;{Matchfinddetails?.price}</small>
                    <p>{Matchfinddetails?.about}</p>
                    <small style={{ color: "black" }}>
                      {Matchfinddetails?.species} / {Matchfinddetails?.breed}
                    </small>
                    <small
                      style={{
                        color: "black",
                        textTransform: "capitalize",
                      }}
                    >
                      {Matchfinddetails?.gender} / {Matchfinddetails?.age}
                    </small>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <button
                        style={{
                          margin: "10px 0px",
                          fontWeight: "bold",
                          backgroundColor: "orange",
                          color: "white",
                          fontSize: "20px",
                          borderRadius: "4px",
                          padding: "15px 50px",
                        }}
                      >
                        Buy
                      </button>
                    </div>
                    <div>
                      <div>
                        <p
                          style={{
                            textTransform: "capitalize",
                            display: "flex",
                          }}
                        >
                          <span style={{ marginRight: "5px" }}>
                            <BiPhoneCall />
                          </span>
                          <span style={{ fontvariantNumeric: "normal" }}>
                            <a href={tm}>{Matchfinddetails?.mobile}</a>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="shopmeta">
                    <span style={{ fontWeight: "bold" }}>
                      <strong>Phone &nbsp; &nbsp; &nbsp; : &nbsp; </strong>
                      <a href={tm}>{Matchfinddetails?.mobile}</a>
                    </span>
                    <span style={{ fontWeight: "bold" }}>
                      <strong>Address &nbsp; : &nbsp;</strong>
                      <a style={{ textTransform: "capitalize" }}>
                        {Matchfinddetails?.street} {Matchfinddetails?.taluk}
                        {Matchfinddetails?.city} &nbsp;
                      </a>
                      ,<a style={{ textTransform: "capitalize" }}>erode </a>,
                      <a style={{ textTransform: "capitalize" }}>
                        {Matchfinddetails?.district} -
                        {Matchfinddetails?.pincode}
                      </a>
                    </span>
                  </div>
                  <button
                    style={{
                      margin: "15px 0px",
                      fontWeight: "bold",
                      backgroundColor: "orange",
                      color: "white",
                      fontSize: "25px",
                      borderRadius: "4px",
                      padding: "15px 100px",
                    }}
                  >
                    Match Find
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr class="invis" />
      </section>
    </div>
  );
}

export default MatchfindDeatils;
