import React from "react";
import { CircularProgress } from "@mui/material";
function Loader() {
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "70vh",
        }}
      >
        <CircularProgress size={50} />
      </div>
    </div>
  );
}

export default Loader;
