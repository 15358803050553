import {
  user,
  newsfeed,
  resources,
  Animals,
  Animaldetails,
  RecentNews,
  district,
  doctor,
  designation,
  taluk,
  Whatsup,
  digirecords,
  FeedFormulation,
} from "../Actionstype/Actionstype";
import { axiosInstance } from "../../Services";

export function usersendotp(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "/v1/customer/send_otp", data)
        .then((res) => {
          dispatch({
            type: user.usersendotp.success,
            data: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: user.usersendotp.error,
            data: err.response.data.data,
          });
          reject(err);
        })
    );
}
export function userlogin(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "/v1/customer/login", data)
        .then((res) => {
          localStorage.setItem("AuthUserToken", res.data.data);
          window.location.href = "/";
          dispatch({
            type: user.login.success,
            data: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: user.login.error,
            data: err.response.data.data,
          });
          reject(err);
        })
    );
}
export function usereditrequest(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "/v1/doctor/rise_query", data)
        .then((res) => {
          dispatch({
            type: user.editrequest.success,
            data: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: user.editrequest.error,
            data: err,
          });
          reject(err);
        })
    );
}
export function doctorlogin(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "/v1/doctor/login", data)
        .then((res) => {
          dispatch({
            type: doctor.login.success,
            data: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: doctor.login.error,
            data: err,
          });
          reject(err);
        })
    );
}
export function docinstantapproval(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "/v1/doctor/instant_approval", data)
        .then((res) => {
          dispatch({
            type: doctor.instantapproval.success,
            data: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: doctor.instantapproval.error,
            data: err,
          });
          reject(err);
        })
    );
}
export function Adddoctorrating(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "/v1/doctor/add_rating", data)
        .then((res) => {
          dispatch({
            type: doctor.addrating.success,
            data: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: doctor.addrating.error,
            data: err,
          });
          reject(err);
        })
    );
}
export function designationlist() {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("get", "/v1/doctor/designation")
        .then((res) => {
          dispatch({
            type: designation.list.success,
            data: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: designation.list.error,
            data: err,
          });
          reject(err);
        })
    );
}
export function districtlist() {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("get", "/v1/doctor/districts")
        .then((res) => {
          dispatch({
            type: district.get.success,
            data: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: district.get.error,
            data: err,
          });
          reject(err);
        })
    );
}
export function taluklist() {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("get", "/v1/doctor/district/list")
        .then((res) => {
          dispatch({
            type: taluk.get.success,
            data: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: taluk.get.error,
            data: err,
          });
          reject(err);
        })
    );
}
export function doctorsignup(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "/v1/doctor/signup", data)
        .then((res) => {
          localStorage.setItem("Id", res.data.data.id);
          dispatch({
            type: doctor.signup.success,
            data: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: doctor.signup.error,
            data: err,
          });
          reject(err);
        })
    );
}
export function sendotp(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "/v1/customer/verify_otp", data)
        .then((res) => {
          localStorage.setItem("AuthUserToken", res.data.token);
          window.location.href = "/";
          dispatch({
            type: user.sendotp.success,
            data: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: user.sendotp.error,
            data: err.response.data,
          });
          reject(err);
        })
    );
}
export function customersignup(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "/v1/customer/signup", data)
        .then((res) => {
          dispatch({
            type: user.register.success,
            data: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: user.register.error,
            data: err,
          });
          reject(err);
        })
    );
}
export function addresourcerating(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("get", "/v1/resource/rate", data)
        .then((res) => {
          dispatch({
            type: resources.addrating.success,
            data: res.data.data,
          });
          resolve(true);
        })
        .catch((err) => {
          dispatch({
            type: resources.addrating.error,
            data: err,
          });
          reject(err);
        })
    );
}
export function resourcelist(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "/v1/doctor/uploads/resource", data)
        .then((res) => {
          dispatch({
            type: user.resources.success,
            data: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: user.resources.error,
            data: err,
          });
          reject(err);
        })
    );
}
export function usergetprofile() {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("get", "/v1/doctor/profile")
        .then((res) => {
          dispatch({
            type: user.getprofile.success,
            data: res.data.data,
          });
          resolve(true);
        })
        .catch((err) => {
          dispatch({
            type: user.getprofile.error,
            data: err,
          });
          reject(err);
        })
    );
}
export function searchnewsfeedlist(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "/v1/customer/news/search", data)
        .then((res) => {
          dispatch({
            type: newsfeed.search.success,
            data: res.data.data,
          });
          resolve(false);
        })
        .catch((err) => {
          dispatch({
            type: newsfeed.search.error,
            data: err,
          });
          reject(err);
        })
    );
}

export function searchnewsdecription(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "/v1/customer/news/desc", data)
        .then((res) => {
          dispatch({
            type: newsfeed.desc.success,
            data: res.data.data,
          });
          resolve("true");
        })
        .catch((err) => {
          dispatch({
            type: newsfeed.desc.error,
            data: err,
          });
          reject(err);
        })
    );
}
export function addresource(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "/v1/resource/add", data)
        .then((res) => {
          dispatch({
            type: resources.add.success,
            data: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: resources.add.error,
            data: err,
          });
          reject(err);
        })
    );
}
export function adddigianimals(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "/v1/resource/add", data)
        .then((res) => {
          dispatch({
            type: resources.add.success,
            data: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: resources.add.error,
            data: err,
          });
          reject(err);
        })
    );
}
export function editdigianimals(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "/v1/resource/edit", data)
        .then((res) => {
          dispatch({
            type: resources.add.success,
            data: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: resources.add.error,
            data: err,
          });
          reject(err);
        })
    );
}
export function createdigirecords(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "/v1/resource/edit", data)
        .then((res) => {
          dispatch({
            type: resources.create.success,
            data: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: resources.create.error,
            data: err,
          });
          reject(err);
        })
    );
}
export function doctorresourcelist(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "/v1/doctor/find_nearby/doctors", data)
        .then((res) => {
          dispatch({
            type: resources.list.success,
            data: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: resources.list.error,
            data: err,
          });
          reject(err);
        })
    );
}
export function doctorresourcedetails(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "/v1/doctor/find_nearby/doctor/details", data)
        .then((res) => {
          dispatch({
            type: resources.details.success,
            data: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: resources.details.error,
            data: err,
          });
          reject(err);
        })
    );
}
export function resourcetypelist(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "/v1/customer/find_nearby/resources", data)
        .then((res) => {
          dispatch({
            type: resources.typelist.success,
            data: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: resources.typelist.error,
            data: err,
          });
          reject(err);
        })
    );
}
export function resourcelistdetails(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "/v1/customer/find_nearby/resources/details", data)
        .then((res) => {
          dispatch({
            type: resources.listdetails.success,
            data: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: resources.listdetails.error,
            data: err,
          });
          reject(err);
        })
    );
}
export function addbuyanimals(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "/v1/resource/buysell/sell", data)
        .then((res) => {
          dispatch({
            type: Animals.addbuy.success,
            data: res.data.data,
          });
          resolve(res.data.data);
        })
        .catch((err) => {
          dispatch({
            type: Animals.addbuy.error,
            data: err,
          });
          reject(err);
        })
    );
}
export function verifybuysell(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "/v1/resource/buysell/verify", data)
        .then((res) => {
          dispatch({
            type: Animals.verifysell.success,
            data: res.data.data,
          });
          resolve(res.data);
        })
        .catch((err) => {
          dispatch({
            type: Animals.verifysell.error,
            data: err,
          });
          reject(err);
        })
    );
}
export function animalsbuylist(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "/v1/resource/buysell", data)
        .then((res) => {
          dispatch({
            type: Animals.buy.success,
            data: res.data.data,
          });
          resolve(true);
        })
        .catch((err) => {
          dispatch({
            type: Animals.buy.error,
            data: err,
          });
          reject(err);
        })
    );
}
export function animalsadoptlist(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "/v1/resource/adoption", data)
        .then((res) => {
          dispatch({
            type: Animals.adoption.success,
            data: res.data.data,
          });
        })
        .catch((err) => {
          dispatch({
            type: Animals.adoption.error,
            data: err,
          });
          reject(err);
        })
    );
}
export function addadoptionanimals(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "/v1/resource/adoption/add", data)
        .then((res) => {
          dispatch({
            type: Animals.addadoption.success,
            data: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: Animals.addadoption.error,
            data: err,
          });
          reject(err);
        })
    );
}
export function animalslist(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("get", "/v1/doctor/animals")
        .then((res) => {
          dispatch({
            type: Animals.list.success,
            data: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: Animals.list.error,
            data: err,
          });
          reject(err);
        })
    );
}
export function addmatchfindanimals(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "/v1/resource/matchfind/add", data)
        .then((res) => {
          dispatch({
            type: Animals.addmatchfind.success,
            data: res.data.data,
          });
          resolve(true);
        })
        .catch((err) => {
          dispatch({
            type: Animals.addmatchfind.error,
            data: err,
          });
          reject(err);
        })
    );
}
export function animalsmatchfindlist(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "/v1/resource/matchfind", data)
        .then((res) => {
          dispatch({
            type: Animals.matchfind.success,
            data: res.data.data,
          });
        })
        .catch((err) => {
          dispatch({
            type: Animals.matchfind.error,
            data: err,
          });
          reject(err);
        })
    );
}
export function animalsbuylistdetails(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "/v1/resource/buysell/sell/detail", data)
        .then((res) => {
          dispatch({
            type: Animaldetails.buy.success,
            data: res.data.data,
          });
          resolve(true);
        })
        .catch((err) => {
          dispatch({
            type: Animaldetails.buy.error,
            data: err,
          });
          reject(err);
        })
    );
}
export function animalsadoptiondetails(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "/v1/resource/adoption/detail", data)
        .then((res) => {
          dispatch({
            type: Animaldetails.adoption.success,
            data: res.data.data,
          });
        })
        .catch((err) => {
          dispatch({
            type: Animaldetails.adoption.error,
            data: err,
          });
          reject(err);
        })
    );
}
export function animalsmatchdetails(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("post", "/v1/resource/matchfind/detail", data)
        .then((res) => {
          dispatch({
            type: Animaldetails.matchfind.success,
            data: res.data.data,
          });
        })
        .catch((err) => {
          dispatch({
            type: Animaldetails.matchfind.error,
            data: err,
          });
          reject(err);
        })
    );
}
export function RecentNewslist(data) {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("get", "/v1/customer/news/recent", data)
        .then((res) => {
          dispatch({
            type: RecentNews.get.success,
            data: res.data.data,
          });
        })
        .catch((err) => {
          dispatch({
            type: RecentNews.get.error,
            data: err,
          });
          reject(err);
        })
    );
}
export function Whatsuplink() {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("get", "/v1/doctor/forum/whtslink")
        .then((res) => {
          dispatch({
            type: Whatsup.get.success,
            data: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: Whatsup.get.error,
            data: err,
          });
          reject(err);
        })
    );
}
export function digipoultrylist() {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("get", "/v1/doctor/poultry_type")
        .then((res) => {
          dispatch({
            type: digirecords.polutrylist.success,
            data: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: digirecords.polutrylist.error,
            data: err,
          });
          reject(err);
        })
    );
}
export function Feedformulationlist() {
  return (dispatch) =>
    new Promise((resolve, reject) =>
      axiosInstance("get", "/v1/doctor/poultry/feed_formulation")
        .then((res) => {
          dispatch({
            type: FeedFormulation.getlist.success,
            data: res.data.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: FeedFormulation.getlist.error,
            data: err,
          });
          reject(err);
        })
    );
}
