import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Typography,
  Card,
  Container,
  Grid,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  Switch,
  Input,
} from "@mui/material";

import { AiOutlineMinus } from "react-icons/ai";
import { GrAdd } from "react-icons/gr";
import { useNavigate, Link } from "react-router-dom";
import cow from "../cow.jpg";
import PoultryForm from "../SubFeedFormulatn/PoultryForm";
function RuminantIndex() {
  const [count, setCount] = useState(1);
  const [counttwo, setCounttwo] = useState(1);
  const [checked, setChecked] = React.useState(true);
  //dynamiically add inputfields
  const [inputFields, setInputFields] = useState([
    {
      energysources: "",
    },
  ]);
  const [inputFields1, setInputFields1] = useState([
    {
      ProteinSources: "",
    },
  ]);
  const [inputFields2, setInputFields2] = useState([
    {
      Products: "",
    },
  ]);
  const [inputFields3, setInputFields3] = useState([
    {
      Weight: "",
    },
  ]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleAddone = () => {
    setCount(count + 1);
  };
  const handleAddTwo = () => {
    setCounttwo(counttwo + 1);
  };
  const handleSubone = () => {
    if (count === 1) {
      return;
    }
    setCount(count - 1);
  };

  const handleSubTwo = () => {
    if (counttwo === 1) {
      return;
    }
    setCounttwo(counttwo - 1);
  };
  const handleSwitch = () => {
    setChecked(!checked);
  };

  /// Array Test
  let a = localStorage.setItem("test", "One");
  console.log("a", a);
  const handleremove = async (index) => {
    const value = await Promise.all(
      inputFields.filter((data, fieldIndex) => index !== fieldIndex)
    );
    setInputFields(value);
  };

  const addInputField = () => {
    console.log("add");
    setInputFields([
      ...inputFields,
      {
        energysources: "",
      },
    ]);
  };
  const addInputField1 = () => {
    console.log("add1");
    setInputFields1([
      ...inputFields1,
      {
        ProteinSources: "",
      },
    ]);
  };
  const addInputField2 = () => {
    console.log("add2");
    setInputFields2([
      ...inputFields2,
      {
        products: "",
      },
    ]);
  };

  return (
    <div>
      <section
        className="section banner"
        style={{ backgroundImage: "url('../Assests/upload/vet_bg.jpg')" }}
        data-img-width="1688"
        data-img-height="470"
        data-diff="100"
      ></section>
      <div class="page-title grey">
        <div class="container">
          <div class="title-area pull-left">
            <h2> Ruminant</h2>
            <div class="bread">
              <ol class="breadcrumb">
                <li>
                  <a href="/">Home</a>
                </li>
                <li class="active">Ruminant</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <Container
        className="ml-5 mr-5 my-5 py-5"
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h2 style={{ fontWeight: "bold" }}>Ruminant</h2>
        <br />
        <img src={cow} style={{ width: "300px" }} />
        <br />
        <Typography variant="h5" component="h2">
          Calculate your Cow Weight
        </Typography>
        <Grid style={{ marginTop: "10px", textAlign: "center" }}>
          <span style={{ fontWeight: "bold" }}>Length ( L )</span>
          <br />
          <Button onClick={handleSubone}>
            <AiOutlineMinus />
          </Button>
          <span
            style={{
              fontSize: "30px",
              margin: " 0px 10px",
              verticalAlign: "center",
            }}
          >
            {count}
          </span>
          <Button onClick={handleAddone}>
            <GrAdd />
          </Button>
          <br />
          <br />
        </Grid>
        <Grid style={{ marginTop: "10px", textAlign: "center" }}>
          <span style={{ fontWeight: "bold" }}>Girth ( G )</span>
          <br />
          <Button onClick={handleSubTwo}>
            <AiOutlineMinus />
          </Button>
          <span
            style={{
              fontSize: "30px",
              margin: " 0px 10px",
              verticalAlign: "center",
            }}
          >
            {counttwo}
          </span>
          <Button onClick={handleAddTwo}>
            <GrAdd />
          </Button>
          <br />
          <br />
          <Button
            style={{
              width: "170px",
              backgroundColor: "#ED6C10",
              color: "white",
            }}
          >
            Submit
          </Button>
        </Grid>
        <br />
        <br />
        <Grid container spacing={2}>
          <Grid xs={12} sm={6} md={4} lg={3}>
            <Card className="my-5 mx-5 p-5">
              <Typography variant="h6" component="h2">
                Energy Sources
              </Typography>
              {inputFields.map((input, index) => {
                return (
                  <>
                    <FormControl fullWidth style={{ marginTop: "20px" }}>
                      <InputLabel id="daemo-simple-select-label">
                        Select Feed
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="species"
                        label="Select Feed"
                      >
                        <MenuItem>Select Value</MenuItem>
                        <MenuItem value="1">One</MenuItem>
                        <MenuItem value="2">Two</MenuItem>
                        <MenuItem value="3">Three</MenuItem>
                      </Select>
                    </FormControl>
                    {index >= 2 ? (
                      <div style={{ display: "flex", marginLeft: "10px" }}>
                        <span
                          style={{
                            fontSize: "20px",
                            marginTop: "10px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          Delete
                        </span>
                      </div>
                    ) : (
                      false
                    )}
                  </>
                );
              })}
              <br />
              <br />

              <br />
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  onClick={addInputField}
                  style={{
                    width: "150px",
                    backgroundColor: "white",
                    outline: "2px solid #ED6C10",
                    color: "#ED6C10",
                  }}
                >
                  Add feed
                </Button>
              </div>
            </Card>
          </Grid>
          <Grid xs={12} sm={6} md={4} lg={3}>
            <Card className="my-5 mx-5 p-5">
              <Typography variant="h6" component="h2">
                Protein Sources
              </Typography>
              {inputFields1.map((input, index) => {
                return (
                  <>
                    <FormControl fullWidth style={{ marginTop: "20px" }}>
                      <InputLabel id="daemo-simple-select-label">
                        Select Feed
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="species"
                        label="Select Feed"
                      >
                        <MenuItem>Select Value</MenuItem>
                        <MenuItem value="1">One</MenuItem>
                        <MenuItem value="2">Two</MenuItem>
                        <MenuItem value="3">Three</MenuItem>
                      </Select>
                    </FormControl>
                    {index >= 2 ? (
                      <div style={{ display: "flex", marginLeft: "10px" }}>
                        <span
                          style={{
                            fontSize: "20px",
                            marginTop: "10px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          Delete
                        </span>
                      </div>
                    ) : (
                      false
                    )}
                  </>
                );
              })}
              <br />
              <br />

              <br />
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  onClick={addInputField1}
                  style={{
                    width: "150px",
                    backgroundColor: "white",
                    outline: "2px solid #ED6C10",
                    color: "#ED6C10",
                  }}
                >
                  Add feed
                </Button>
              </div>
            </Card>
          </Grid>
          <Grid xs={12} sm={6} md={4} lg={3}>
            <Card className="my-5 mx-5 p-5">
              <Typography variant="h6" component="h2">
                By Products
              </Typography>
              <FormControl fullWidth>
                <InputLabel id="daemo-simple-select-label">
                  Select Feed
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="species"
                  label="Select Feed"
                >
                  <MenuItem value="2">Select Species</MenuItem>
                  <MenuItem value="21">Select Species</MenuItem>
                  <MenuItem value="1">Select Species</MenuItem>
                </Select>
              </FormControl>
              <br />
              <br />
              <br />
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  onClick={addInputField2}
                  style={{
                    width: "150px",
                    backgroundColor: "white",
                    outline: "2px solid #ED6C10",
                    color: "#ED6C10",
                  }}
                >
                  Add feed
                </Button>
              </div>
            </Card>
          </Grid>
          <Grid xs={12} sm={6} md={4} lg={3}>
            <Card className="my-5 mx-5 p-5">
              <Typography variant="h6" component="h2">
                Animal Weight
              </Typography>
              <FormControl fullWidth>
                <InputLabel id="daemo-simple-select-label">Weight</InputLabel>
                <Input type="text" placeholder="Weight" />
              </FormControl>
              <br />
              <br />
              <FormControl fullWidth>
                <InputLabel id="daemo-simple-select-label">
                  Animal Age
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="Animal Age"
                  label="Animal Age"
                >
                  <MenuItem value="2">0 - 6 Months</MenuItem>
                  <MenuItem value="21">6 - 1 Year</MenuItem>
                  <MenuItem value="1">1 Year and More</MenuItem>
                </Select>
              </FormControl>
              <br />
              <br />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Switch
                  value={checked}
                  onChange={handleSwitch}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <h5
                  style={{
                    display: "flex",
                    alignItems: "end",
                    fontWeight: "bold",
                  }}
                >
                  Lactating Animal
                </h5>
              </div>
            </Card>
          </Grid>
        </Grid>
        <Button
          style={{ width: "100%", background: "#ED6C10", color: "white" }}
        >
          Calculate
        </Button>
      </Container>
    </div>
  );
}

export default RuminantIndex;
