import React from "react";
import MainRoutes from "./Routes/MainRoutes"

function App() {
  return (
    <div >
 
  <MainRoutes />

    </div>
  );
}

export default App;