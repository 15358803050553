import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { BsFillStarFill } from "react-icons/bs";
import { doctorresourcelist } from "../Redux/Actions/actions";
import Loader from "../Loader/Loader";
const Doctor = () => {
  const [search, setSeacrh] = useState("erode");
  const [load, setLoad] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(doctorresourcelist({ district: "erode" })).then((res) => {
      setLoad(false);
    });
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    e.currentTarget.reset();
    dispatch(doctorresourcelist({ district: search }));
  };
  const doclist = useSelector(
    ({ doctorresourcelist }) => doctorresourcelist.payload
  );
  return (
    <div>
      <section
        className="section banner"
        style={{ backgroundImage: "url('../Assests/upload/vet_bg.jpg')" }}
        data-img-width="1688"
        data-img-height="470"
        data-diff="100"
      ></section>
      <div className="page-title grey">
        <div className="container">
          <div className="title-area pull-left">
            <h2>Veterinarians</h2>
            <div className="bread">
              <ol className="breadcrumb">
                <li>
                  <a href="#">Home</a>
                </li>
                <li className="active">Veterinarians</li>
              </ol>
            </div>
          </div>

          <div className="search pull-right">
            <form onSubmit={handleSubmit}>
              <div className="input-group">
                <input
                  className="form-control"
                  name="search"
                  type="search"
                  onChange={(e) => setSeacrh(e.target.value)}
                  placeholder=" Search Doctors"
                />
                <span className="input-group-btn">
                  <button type="submit" className="btn btn-primary">
                    <i className="fa fa-search"></i>
                  </button>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
      {load ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <section className="section grey">
            <div className="container ">
              <div className="row module-wrapper text-left">
                {Array.isArray(doclist) && doclist.length > 0 ? (
                  doclist.map((doc) => (
                    <>
                      <div className="col-md-4 col-sm-4 mb-5 mt-5">
                        <div className="team-member team-square">
                          <div className="entry">
                            <Link to={`/Doctordetails/${doc?.id}`}>
                              <img
                                className="img-responsive"
                                src={doc?.photo}
                                style={{ aspectRatio: "1", objectFit: "cover" }}
                                loading="lazy"
                                alt="DoctorImage"
                              />
                            </Link>
                          </div>

                          <h4 style={{ textTransform: "capitalize" }}>
                            <Link to={`/Doctordetails/${doc?.id}`}>
                              {doc?.name}
                            </Link>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <small
                                style={{ display: "flex", alignItems: "end" }}
                              >
                                {doc.designation}
                              </small>

                              <p
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span style={{ color: "orange" }}>
                                  <BsFillStarFill />
                                </span>
                                <span style={{ marginRight: "10px" }}>
                                  {doc?.rating}
                                </span>
                              </p>
                            </div>
                          </h4>
                          <p style={{ textTransform: "capitalize" }}>
                            {doc.street}, {doc.taluk}, {doc.district},{" "}
                            {doc.city} -{doc.pincode}.
                          </p>
                        </div>
                      </div>
                    </>
                  ))
                ) : (
                  <h1
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      fontWeight: "bold",
                    }}
                  >
                    No Doctor Found
                  </h1>
                )}
              </div>
            </div>
          </section>{" "}
        </>
      )}
    </div>
  );
};

export default Doctor;
