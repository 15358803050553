import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GoLocation } from "react-icons/go";
import { searchnewsdecription } from "../Redux/Actions/actions";
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
const Indicator = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        height: "50vh",
        alignItems: "center",
      }}
    >  
      <CircularProgress size={30} />
    </div>
  );
};

const Singlenews = () => {
  const [loader, setLoader] = useState(true);

  const { id } = useParams();
  const dispatch = useDispatch();
  const newssearch = useSelector(
    ({ searchnewsdecription }) => searchnewsdecription.payload
  );

  useEffect(() => {
    dispatch(searchnewsdecription({ id: id })).then((data) => {
      if (data) {
        setLoader(false);
      }
    });
  }, []);

  return (
    <div>
      {loader ? (
        <Indicator />
      ) : (
        <>
          <section
            className="section banner"
            style={{ backgroundImage: "url(../Assests/upload/blog_bg.jpg)" }}
            data-img-width="1688"
            data-img-height="470"
            data-diff="100"
          ></section>
          <div className="page-title grey">
            <div className="container">
              <div className="title-area pull-left">
                <h2 style={{ textTransform: "capitalize" }}>
                  {newssearch?.title}
                </h2>
                <div className="bread">
                  <ol className="breadcrumb">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li className="active">News Feeds</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <section className="section white">
            <div className="container">
              <div className="row">
                <div id="content" className="col-md-12 col-sm-12 col-xs-12">
                  <div className="blog-wrapper mt-5 ">
                    <div className="blog-image">
                      <a href="/news">
                        <img
                          src={newssearch?.photo}
                          alt="Newsfeed Images"
                          className="img-responsive"
                        />
                      </a>
                    </div>
                    <div className="blog-title">
                      <h2>
                        <a href="/news" style={{ textTransform: "capitalize" }}>
                          {newssearch?.title}
                        </a>
                      </h2>
                      <div className="post-meta">
                        <span>
                          <i className="fa fa-clock-o"></i>
                          <a>{newssearch?.updatedAt}</a>
                        </span>
                        <div style={{ display: "flex", marginTop: "5px" }}>
                          <div>
                            <GoLocation />
                          </div>
                          &nbsp;
                          <div>
                            {newssearch?.district}, {newssearch?.taluk}
                          </div>
                        </div>
                      </div>
                      <p style={{ textTransform: "capitalize" }}>
                        {newssearch?.description}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </div>
  );
};

export default Singlenews;
