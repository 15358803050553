import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Container from "@mui/material/Container";
import { BsFillCheckCircleFill } from "react-icons/bs";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { Link, useNavigate } from "react-router-dom";
import { animalslist } from "../../Redux/Actions/actions";
import { createdigirecords } from "../../Redux/Actions/actions";

const style = {
  position: "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #C0C0C0",
  boxShadow: 24,
  p: 5,
};
function Createdigirecords1() {
  const [search, setSearch] = useState("");
  const [error, setError] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [data, setData] = useState({

    HistoryofOwner: "",
    OwnerName: "",
    TentativeDiagnosis: "",
    ConfirmativeDiagnosis: "",
    TreatementGiven: "",
    Remark: "",

  });
  const [showResults, setShowResults] = React.useState(false);
  const onClick = () => setShowResults(true);
  const handlesearchsubmit = (e) => {
    e.preventDefault();
  };
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(true);
  };
  return (
    <div>
      <section
        className="section banner"
        style={{ backgroundImage: "url('../Assests/upload/vet_bg.jpg')" }}
        data-img-width="1688"
        data-img-height="470"
        data-diff="100"
      ></section>
      <div className="page-title grey">
        <div className="container">
          <div className="title-area pull-left">
            <h2>Add Animals</h2>
            <div className="bread">
              <ol className="breadcrumb">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="active">Add Animals</li>
              </ol>
            </div>
            {/* <!-- end bread --> */}
          </div>
          {/* <!-- /.pull-right --> */}
          <div className="search pull-right">
            <form>{/* <!-- /input-group --> */}</form>
          </div>
          {/* <!-- /.pull-right --> */}
          <div
            className="search pull-right mb-3 "
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            Animal Search
            <form onSubmit={handlesearchsubmit}>
              <div>
                <div className="input-group">
                  <input
                    className="form-control"
                    onChange={(e) => setSearch(e.target.value)}
                    name="search"
                    type="search"
                    placeholder=" Search..."
                  />
                  <span className="input-group-btn">
                    <button type="submit" className="btn btn-primary">
                      <i className="fa fa-search"></i>
                    </button>
                  </span>
                </div>
              </div>
              {/* <!-- /input-group --> */}
            </form>
          </div>
        </div>
      </div>

      <Container>

        <Box>

          <Container>
            <Box component="form" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box
                      sx={{
                        mt: 8,
                        mx: 4,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          fontWeight: "bold",
                          fontSize: "20px",
                          marginBottom: "20px",
                        }}
                      >

                        Treatement
                      </div>
                      <TextField
                        margin="normal"

                        fullWidth
                        onChange={handleChange}
                        id="History of Owner"
                        label="History of Owner"

                        name="HistoryofOwner"

                      />
                      <br />
                      <TextField
                        margin="normal"

           fullWidth
                        onChange={handleChange}
                        id="Owner Name"
                        label="Owner Name"

                        name="OwnerName"

                      />
                      <br />
                      <TextField
                        margin="normal"
                        fullWidth
                        onChange={handleChange}
                        id="Tentative Diagnosis"
                        label="Tentative Diagnosis"

                        name="TentativeDiagnosis"

                      />
                      <br />
                      <TextField
                        margin="normal"


                        fullWidth
                        onChange={handleChange}
                        id="Confirmative Diagnosis"
                        label="Confirmative Diagnosis"

                        name="ConfirmativeDiagnosis"

                      />
                      <br />
                      <TextField
                        margin="normal"

                        fullWidth
                        onChange={handleChange}
                        id="Treatement Given"
                        label="Treatement Given"
                        name="TreatementGiven"

                      />
                      <br />
                      <TextField
                        fullWidth
                        id="outlined-multiline-static"
                        label="Remark/Note"
                        multiline
                        rows={4}
                        name="Remark"
                        onChange={handleChange}

                      />
                      <br />
                      <Button
                        type="submit"
                        fullWidth
                        onClick={handleOpen}
                        style={{ backgroundColor: "#ED6C10", color: "white" }}
                      >
                        Save Changes
                      </Button>
                      <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"

                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            Success
                          </Typography>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <BsFillCheckCircleFill
                              style={{ color: "#ED6C10", fontSize: "130px" }}
                            />
                          </div>

                          <Typography
                            id="modal-modal-description"
                            sx={{ mt: 2 }}
                          >
                            Digi Record created to #953216-13525
                            <p>animal name - Jimmy.</p>
                            <p>Click Continue to Upload Records</p>

                            <Button
                              fullWidth
                              type="submit"
                              onClick={handleOpen}
                              style={{
                                backgroundColor: "#ED6C10",
                                color: "white",
                              }}
                            >
                              Continue
                            </Button>
                          </Typography>
                        </Box>
                      </Modal>

                      <span
                        style={{
                          color: "red",
                          marginTop: "10px",
                        }}
                      >

                        {/* {error ? (

                          <Stack direction="row" spacing={1}>
                            <Chip label={error} color="#ED6C10" />
                          </Stack>
                        ) : (
                          false

                        )} */}

                      </span>
                    </Box>
                  </Grid>
                </Grid>
                {/* <Grid item xs={12} sm={12} md={6}>
              <Grid item xs={12} sm={12} md={12}>
                <Box
                  sx={{
                    mt: 8,
                    mx: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                    <div style={{ fontWeight: "bold",fontSize:"20px" ,marginBottom:"20px"}}>Dewarming</div>
                    <FormControl fullWidth>
                  <InputLabel id="daemo-simple-select-label">
                    Select Drug Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="Select Drug Type"
                    label="Select Drug Type"
                    onChange={handleChange}
                  >
                    <MenuItem value="" selected>
                      Select Drug Type
                    </MenuItem>
                    <MenuItem value="Inj, Syrup, Tonic">
                      Inj, Syrup, Tonic
                    </MenuItem>
                    <MenuItem value="polus">Polus</MenuItem>
                    <MenuItem value="tab">Tab</MenuItem>
                  </Select>
                </FormControl>
                <br />

<TextField
        id="date"
        label="Date"
        type="date"
        defaultValue="2022-09-21"
        sx={{ width: 480,mb:2 ,mt:2}}
        InputLabelProps={{
          shrink: true,
        }}
      />
               <div style={{ fontWeight: "bold",fontSize:"20px" ,marginBottom:"20px",color:"#e67e22"}}>New Due</div>
             
               <FormControl fullWidth>
                  <InputLabel id="daemo-simple-select-label">
                    Select Drug Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="Select Drug Type"
                    label="Select Drug Type"
                    onChange={handleChange}
                  >
                    <MenuItem value="" selected>
                      Select Drug Type
                    </MenuItem>
                    <MenuItem value="Inj, Syrup, Tonic">
                      Inj, Syrup, Tonic
                    </MenuItem>
                    <MenuItem value="polus">Polus</MenuItem>
                    <MenuItem value="tab">Tab</MenuItem>
                  </Select>
                </FormControl>
                  <br />
                  <TextField
        id="date"
        label="Date"
        type="date"
        defaultValue="2022-09-21"
        sx={{ width: 480,mb:2 ,mt:2}}
        InputLabelProps={{
          shrink: true,
        }}
      />
       <div style={{ fontWeight: "bold",fontSize:"20px" ,marginBottom:"20px"}}>Vaccination</div>
                    <FormControl fullWidth>
                  <InputLabel id="daemo-simple-select-label">
                    Select Vaccine Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="Select Drug Type"
                    label="Select Drug Type"
                    onChange={handleChange}
                  >
                    <MenuItem value="" selected>
                      Select Vaccine Type
                    </MenuItem>
                    <MenuItem value="Inj, Syrup, Tonic">
                      Inj, Syrup, Tonic
                    </MenuItem>
                    <MenuItem value="polus">Polus</MenuItem>
                    <MenuItem value="tab">Tab</MenuItem>
                  </Select>
                </FormControl>
                <br />

<TextField
        id="date"
        label="Date"
        type="date"
        defaultValue="2022-09-21"
        sx={{ width: 480,mb:2 ,mt:2}}
        InputLabelProps={{
          shrink: true,
        }}
      />
               <div style={{ fontWeight: "bold",fontSize:"20px" ,marginBottom:"20px",color:"#e67e22"}}>New Due</div>
             
               <FormControl fullWidth>
                  <InputLabel id="daemo-simple-select-label">
                    Select Vaccine Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="Select Drug Type"
                    label="Select Drug Type"
                    onChange={handleChange}
                  >
                    <MenuItem value="" selected>
                      Select Vaccine Type
                    </MenuItem>
                    <MenuItem value="Inj, Syrup, Tonic">
                      Inj, Syrup, Tonic
                    </MenuItem>
                    <MenuItem value="polus">Polus</MenuItem>
                    <MenuItem value="tab">Tab</MenuItem>
                  </Select>
                </FormControl>
                  <br />
                  <TextField
        id="date"
        label="Date"
        type="date"
        defaultValue="2022-09-21"
        sx={{ width: 480,mb:2 ,mt:2}}
        InputLabelProps={{
          shrink: true,
        }}
      />
       <Link to="/DigiRecords/Createdigirecords1">   <Button
                    type="submit"
                    fullWidth
                    style={{ backgroundColor: "#ED6C10", color: "white" }}
                  >
                    Save Changes
                  </Button></Link>
                  <span
                    style={{
                      color: "red",
                      marginTop: "10px",
                    }}
                  >
                    {error ? (
                      <Stack direction="row" spacing={1}>
                        <Chip label={error} color="#ED6C10" />
                      </Stack>
                    ) : (
                      false
                    )}
                  </span>
                </Box>
              </Grid>
        </Grid> */}
              </Grid>
            </Box>
          </Container>
        </Box>
      </Container>
    </div>
  );
}

export default Createdigirecords1;
