import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { animalsmatchfindlist, animalslist } from "../../Redux/Actions/actions";

const MatchFind = () => {
  const [search, setSearch] = useState("erode");
  const [value, setValue] = useState("");
  const [load, setLoad] = useState(true);
  const [species, setSpecies] = useState("");
  const [breed, setBreed] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(animalslist()).then((res) => {
      setLoad(false);
      dispatch(
        animalsmatchfindlist({
          species: res?.data?.data[0]?.pet,
          district: search,
          breed: res?.data?.data[0]?.breed[0]?.breed,
        })
      );
    });
  }, []);
  const handleBreedChange = (e) => {
    setBreed(e.target.value);
    dispatch(
      animalsmatchfindlist({
        species: species,
        district: search,
        breed: e.target.value,
      })
    );
  };
  const handlesubmit = (e) => {
    e.preventDefault();
    e.currentTarget.reset();
    dispatch(
      animalsmatchfindlist({ species: species, district: search, breed: breed })
    );
  };
  const handleselectChange = (e) => {
    console.log(e.target.value);
    setValue(e.target.value);
    setSpecies(e.target.value);
  };
  const matchlist = useSelector(
    ({ animalsmatchfindlist }) => animalsmatchfindlist.payload
  );
  const specieslist = useSelector(({ animalslist }) => animalslist.payload);

  return (
    <div>
      <div>
        <section
          className="section banner"
          style={{ backgroundImage: "url('../Assests/upload/vet_bg.jpg')" }}
          data-img-width="1688"
          data-img-height="470"
          data-diff="100"
        ></section>
        <div className="page-title grey">
          <div className="container">
            <div className="title-area pull-left">
              <h2>Match Find</h2>
              {
                // <div className="bread">
                //   <ol className="breadcrumb">
                //     <li>
                //       <Link to="/">Home</Link>
                //     </li>
                //     <li className="active">MatchFind</li>
                //   </ol>
                // </div>
              }
              {/* <!-- end bread --> */}
            </div>
            {/* <!-- /.pull-right --> */}
            <div
              className="search pull-right mb-3 "
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Button style={{ color: "white", backgroundColor: "#ED6C10" }}>
                <Link
                  to="/animals/AddMatchfind"
                  style={{
                    color: "white",
                    padding: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Add MatchFind
                </Link>
              </Button>
              <form onSubmit={handlesubmit}>
                <div>
                  <div className="input-group">
                    <input
                      className="form-control"
                      onChange={(e) => setSearch(e.target.value)}
                      name="search"
                      type="search"
                      placeholder=" Search..."
                    />
                    <span className="input-group-btn">
                      <button type="submit" className="btn btn-primary">
                        <i className="fa fa-search"></i>
                      </button>
                    </span>
                  </div>
                </div>
                {/* <!-- /input-group --> */}
              </form>
            </div>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel id="daemo-simple-select-label">
                Select Species
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="species"
                value={species}
                label="Select Species"
                onChange={(e) => handleselectChange(e)}
              >
                <MenuItem selected>Select Species</MenuItem>
                {Array.isArray(specieslist) && specieslist.length > 0
                  ? specieslist.map((data) => (
                      <MenuItem value={data?.pet}> {data?.pet}</MenuItem>
                    ))
                  : false}
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth>
              <InputLabel id="daemo-simple-select-label">
                Select Breed
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="breed"
                value={breed}
                label="Select Breed"
                onChange={handleBreedChange}
              >
                <MenuItem selected>Select Breed</MenuItem>
                {Array.isArray(specieslist) && specieslist?.length > 0
                  ? specieslist
                      .filter((data) => data?.pet === value)
                      .map((dat) =>
                        dat?.breed?.map((data) => (
                          <MenuItem value={data?.breed}>{data?.breed}</MenuItem>
                        ))
                      )
                  : false}
              </Select>
            </FormControl>

            {/* <!-- /.pull-right --> */}
          </div>
        </div>
        {/* <!-- end page-title --> */}

        <section className="section white">
          <div className="container">
            <div className="shop-top clearfix">
              {/* <!-- /.pull-right --> */}

              {/* <!-- /.pull-right --> */}
            </div>
            {/* <!-- end shop top --> */}

            <div className="row module-wrapper shop-layout text-center">
              {Array.isArray(matchlist) && matchlist.length > 0 ? (
                matchlist.map((match) => (
                  <div className="col-md-3 col-sm-6 wdtShop grid cs-style-3">
                    <div className="img-wrap">
                      <Link to={`/MatchfindDetails/${match?.id}`}>
                        <img
                          alt=""
                          src={match?.photo}
                          className="img-responsive"
                        />
                      </Link>
                    </div>
                    <h4 style={{ padding: "10px  0 0px" }}>
                      <Link to={`/MatchfindDetails/${match?.id}`}>
                        {match?.name}
                      </Link>
                    </h4>

                    <p
                      className="text-center"
                      style={{ fontWeight: "bold", color: "#f79700" }}
                    >
                      ₹ {match?.price}
                    </p>
                  </div>
                ))
              ) : (
                <h3> No Animals Found</h3>
              )}
            </div>

            <hr className="invis" />
          </div>
        </section>
      </div>
    </div>
  );
};

export default MatchFind;
