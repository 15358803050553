import React, { useState } from "react";
import { Container, Button, Typography, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import chick from "./chick.jpg";
import cow from "./cow.jpg";

function AddFeedFormulation() {
  const [ishover, setIshover] = useState(false);
  const [a, setA] = useState(true);
  const [ruminantishover, setRuminamtishover] = useState(false);
  const backgroundcolrruminant = () => {
    setRuminamtishover(true);
    setA(false);
  };
  const backgroundcolr = () => {
    setIshover(true);
    setA(true);
  };
  const handleMouseLeave = () => {
    setIshover(false);
  };
  const handleMouseLeaveruminant = () => {
    setRuminamtishover(false);
  };

  return (
    <div>
      <section
        className="section banner"
        style={{ backgroundImage: "url('../Assests/upload/vet_bg.jpg')" }}
        data-img-width="1688"
        data-img-height="470"
        data-diff="100"
      ></section>
      <div class="page-title grey">
        <div class="container">
          <div class="title-area pull-left">
            <h2> Feed Formulation</h2>
            <div class="bread">
              <ol class="breadcrumb">
                <li>
                  <a href="/">Home</a>
                </li>
                <li class="active"> Feed Formulation</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <Container
        className="mt-5 mb-5 pt-5 pb-5"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" component="h2">
          Select Animal Type
        </Typography>
        {a ? (
          <img src={chick} style={{ width: "200px" }} />
        ) : (
          <img src={cow} style={{ width: "160px" }} className="mb-5 pb-5" />
        )}
        <Grid className=" mb-5  pb-5">
          <Link to="/guide/FeedFormulation/Poultry">
            <Button
              style={{
                backgroundColor: ishover ? "#ED6C10" : "#fff",
                color: ishover ? "#fff" : "#ED6C10",
                marginRight: "10px",
              }}
              onMouseEnter={backgroundcolr}
              onMouseLeave={handleMouseLeave}
              variant="contained"
            >
              Poultry
            </Button>
          </Link>
          <Link to="/guide/RuminantIndex">
            <Button
              style={{
                backgroundColor: ruminantishover ? "#ED6C10" : "#fff",
                color: ruminantishover ? "#fff" : "#ED6C10",
              }}
              onMouseEnter={backgroundcolrruminant}
              onMouseLeave={handleMouseLeaveruminant}
              variant="contained"
            >
              Ruminant
            </Button>
          </Link>
        </Grid>
      </Container>
    </div>
  );
}

export default AddFeedFormulation;
