import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { usergetprofile } from "../../../Redux/Actions/actions";
import { Link } from "react-router-dom";
import Loading from "../../../Loader/Loader";
export default function Profilepage() {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    dispatch(usergetprofile()).then((res) => {
      setLoading(false);
    });
  }, []);
  const profile = useSelector(({ usergetprofile }) => usergetprofile.payload);

  return (
    <>
      <section
        className="section banner"
        style={{ backgroundImage: "url('../Assests/upload/vet_bg.jpg')" }}
        data-img-width="1688"
        data-img-height="470"
        data-diff="100"
      ></section>
      <div class="page-title grey">
        <div class="container">
          <div class="title-area pull-left">
            <h2>Profile</h2>
            <div class="bread">
              <ol class="breadcrumb">
                <li>
                  <a href="/">Home</a>
                </li>
                <li href="/">Profile</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <Container className="my-5 mx-5">
        {loading ? (
          <Loading />
        ) : (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Card>
              <CardMedia
                component="img"
                height="140"
                image={profile?.photo}
                alt="Doctor Image"
              />
              <CardContent>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography gutterBottom variant="h5" component="div">
                    {profile?.name}
                  </Typography>
                  <Typography gutterBottom variant="h5" component="div">
                    <Link to={"/myposts/" + profile?.approval_status}>
                      <Chip
                        className="mr-3"
                        color="success"
                        label="My Posts"
                        variant="outlined"
                      />
                    </Link>
                    <Link to="/Profileeditrequest">
                      <Chip
                        color="error"
                        className="ml-3"
                        label="Edit Request"
                        variant="outlined"
                      />
                    </Link>
                  </Typography>
                </Grid>
              </CardContent>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Basic Details</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography gutterBottom variant="h6" component="h6">
                      Register Number
                    </Typography>
                    <Typography gutterBottom variant="h6" component="div">
                      {profile?.doc_reg_no.substring(0, 1).toUpperCase() +
                        profile?.doc_reg_no.substring(1)}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography gutterBottom variant="h6" component="h6">
                      Designation
                    </Typography>
                    <Typography gutterBottom variant="h6" component="div">
                      {profile?.designation.substring(0, 1).toUpperCase() +
                        profile?.designation.substring(1)}
                    </Typography>
                  </Grid>
                  {profile?.currently_working ? (
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography gutterBottom variant="h6" component="h6">
                        Currently Working
                      </Typography>
                      <Typography gutterBottom variant="h6" component="div">
                        {profile?.currently_working
                          .substring(0, 1)
                          .toUpperCase() +
                          profile?.currently_working.substring(1)}
                      </Typography>
                    </Grid>
                  ) : (
                    false
                  )}
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography gutterBottom variant="h6" component="h6">
                      Mobile
                    </Typography>
                    <Typography gutterBottom variant="h6" component="div">
                      {profile?.mobile}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography gutterBottom variant="h6" component="h6">
                      Approval Status
                    </Typography>
                    <Typography gutterBottom variant="h6" component="div">
                      <Chip
                        variant="outlined"
                        color="primary"
                        label={
                          profile?.approval_status
                            .substring(0, 1)
                            .toUpperCase() +
                          profile?.approval_status.substring(1)
                        }
                      />
                    </Typography>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>Address Details</Typography>
                </AccordionSummary>

                <CardContent>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography gutterBottom variant="h6" component="h6">
                      Taluk
                    </Typography>
                    <Typography gutterBottom variant="h6" component="div">
                      {profile?.taluk.substring(0, 1).toUpperCase() +
                        profile?.taluk.substring(1)}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography gutterBottom variant="h6" component="h6">
                      Street
                    </Typography>
                    <Typography gutterBottom variant="h6" component="div">
                      {profile?.street.substring(0, 1).toUpperCase() +
                        profile?.street.substring(1)}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography gutterBottom variant="h6" component="h6">
                      City
                    </Typography>
                    <Typography gutterBottom variant="h6" component="h6">
                      {profile?.city.substring(0, 1).toUpperCase() +
                        profile?.city.substring(1)}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography gutterBottom variant="h6" component="h6">
                      District
                    </Typography>
                    <Typography gutterBottom variant="h6" component="h6">
                      {profile?.district.substring(0, 1).toUpperCase() +
                        profile?.district.substring(1)}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography gutterBottom variant="h6" component="h6">
                      Pincode
                    </Typography>
                    <Typography gutterBottom variant="h6" component="h6">
                      {profile?.pincode.substring(0, 1).toUpperCase() +
                        profile?.pincode.substring(1)}
                    </Typography>
                  </Grid>
                </CardContent>
              </Accordion>
            </Card>
          </Grid>
        )}
      </Container>
    </>
  );
}
