import React from "react";
import { useDispatch, useSelector } from "react-redux";

function ProductDetails() {
  const dispatch = useDispatch();

  return (
    <div>
      <section class="section white">
        <div class="container">
          <div class="row">
            <div id="content" class="col-md-12 col-sm-12 col-xs-12">
              <div class="row">
                <div class="col-md-5 col-sm-5 col-xs-12">
                  <div class="product-images">
                    <a
                      data-rel="prettyPhoto"
                      href="upload/Duck-01.svg"
                      title=""
                    >
                      <img
                        class="img-polaroid"
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5EbJSvefM_STFH53JEUoRPR_iHFqB0DEjLA&usqp=CAU"
                        alt=""
                      />
                    </a>
                    <ul class="thumbnail">
                      <li>
                        <a data-rel="prettyPhoto[gallery]" href="#" title="">
                          <img
                            class="img-thumbnail"
                            src="https://static.toiimg.com/thumb/msid-90756556,imgsize-1216634,width-800,height-600,resizemode-75/90756556.jpg"
                            alt=""
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="col-md-7 col-sm-7 col-xs-12">
                  <div class="shop-desc">
                    <h3>T90 Cat Home Cortes </h3>
                    <small> ₹ &nbsp; 25,000</small>
                    <p>
                      Lorem iam nonummy nibh euismod tincidunt ut laoreet dolore
                      Lorem ipsum dolor sit amet orem iam nonummy nibh euismod
                      tincidunt ut laoreet dolore Lorem ipsum dolor sit amet
                      nibh euismod tincidunt ut laoreet dolore Lorem ipsum dolor
                      sit amet orem iam nonummy nibh euismod tincidunt ut
                      laoreet dolore Lorem ipsum dolor sit amet..
                    </p>
                    <div class="shopmeta">
                      <span style={{ fontWeight: "bold" }}>
                        <strong>Phone &nbsp; &nbsp; &nbsp; : &nbsp; </strong>
                        <a href="#"> 98765 43210</a>
                      </span>
                      <span style={{ fontWeight: "bold" }}>
                        <strong>Address &nbsp; : &nbsp;</strong>
                        <a style={{ textTransform: "capitalize" }}>
                          palayapalayam
                        </a>
                        ,<a style={{ textTransform: "capitalize" }}>erode </a>,
                        <a style={{ textTransform: "capitalize" }}>
                          643003-tamilnadu
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr class="invis" />
      </section>
    </div>
  );
}

export default ProductDetails;
