import React from "react";

function Profileindex() {
  return (
    <div>
      <section
        className="section banner"
        style={{ backgroundImage: "url('../Assests/upload/vet_bg.jpg')" }}
        data-img-width="1688"
        data-img-height="470"
        data-diff="100"
      ></section>
      <div class="page-title grey">
        <div class="container">
          <div class="title-area pull-left">
            <h2>Profile</h2>
            <div class="bread">
              <ol class="breadcrumb">
                <li>
                  <a href="/">Home</a>
                </li>
                <li href="/Profileindex">Profile</li>
              </ol>
            </div>
          </div>
        </div>
      </div>


      
    </div>
  );
}

export default Profileindex;
