import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import PetsIcon from "@mui/icons-material/Pets";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import catimage from "./cat.jpg";
import { usersendotp } from "../../Redux/Actions/actions";
const theme = createTheme();
function UserLogin() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userdata, setUserdata] = useState({
    phone: "",
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (userdata.phone.length != 10) {
      setError("Please enter valid phone number");
    } else {
      e.currentTarget.reset();

      dispatch(usersendotp(userdata))
        .then((res) => {
          navigate("/Userotp/" + userdata.phone);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };
  const handleChange = (e) => {
    setUserdata({
      ...userdata,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div>
      <>
        <ThemeProvider theme={theme}>
          <Grid container component="main" sx={{ height: "100vh" }}>
            <CssBaseline />
            <Grid
              item
              xs={false}
              sm={4}
              md={7}
              sx={{
                backgroundImage: `url(${catimage})`,
                backgroundRepeat: "no-repeat",
                backgroundColor: (t) =>
                  t.palette.mode === "light"
                    ? t.palette.grey[100]
                    : t.palette.grey[900],
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
            <Grid
              item
              xs={12}
              sm={8}
              md={5}
              component={Paper}
              elevation={6}
              square
              style={{ boxShadow: "none" }}
            >
              <Box
                sx={{
                  my: 8,
                  mx: 4,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                  <PetsIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  User Login
                </Typography>
                <Box
                  component="form"
                  Validate
                  onSubmit={handleSubmit}
                  sx={{ mt: 1 }}
                  style={{ width: "100%" }}
                >
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    type="number"
                    onChange={handleChange}
                    id="number"
                    label="Mobile Number"
                    name="phone"
                  />
                  <span style={{ color: "red" }}>{error}</span>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    {loading ? "loading" : "Send OTP "}
                  </Button>
                  <Grid container>
                    <Grid item xs></Grid>
                    <Grid item>
                      Don't have an account yet?&nbsp;
                      <Link
                        to="/usersignup"
                        style={{ color: "#FF8C00", fontWeight: "bold" }}
                      >
                        Sign Up
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </ThemeProvider>
      </>
    </div>
  );
}

export default UserLogin;
