import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import { GoLocation } from "react-icons/go";
import { BiPhoneCall } from "react-icons/bi";
import { FaStar } from "react-icons/fa";
import Button from "@mui/material/Button";
import {
  doctorresourcedetails,
  Adddoctorrating,
} from "../../Redux/Actions/actions";
import Loader from "../../Loader/Loader";
const adminToken =
  localStorage.getItem("AuthUserToken") || localStorage.getItem("AuthDoctoken");

export const Raating = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [valuer, setValuer] = useState("");
  const handlereview = (e) => {
    e.preventDefault();
    dispatch(Adddoctorrating({ doctorId: id, rating: valuer }));
  };
  return (
    <section
      style={{
        border: "1px solid lightgrey",
        borderRadius: "10px",
        padding: "15px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Stack spacing={1}>
        <h2> Your experience with his doctor</h2>
        <Rating
          name="half-rating-read"
          defaultValue={1}
          onChange={(e) => setValuer(e.target.value)}
        />
        <Button
          style={{ color: "white", backgroundColor: "orange" }}
          onClick={handlereview}
        >
          Submit review
        </Button>
      </Stack>
      <br />
    </section>
  );
};
const rating = true;
function Doctordetails() {
  const [show, setShow] = useState(false);
  const [load, setLoad] = useState(true);
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    dispatch(doctorresourcedetails({ id: id })).then((res) => {
      setLoad(false);
    });
  }, []);
  const doctordetails = useSelector(
    ({ doctorresourcedetails }) => doctorresourcedetails.payload
  );
  const handlereview = () => {
    setShow(true);
  };
  var tm = "tel:" + doctordetails?.mobile;
  return (
    <div>
      <section
        className="section banner"
        style={{ backgroundImage: "url('../Assests/upload/vet_bg.jpg')" }}
        data-img-width="1688"
        data-img-height="470"
        data-diff="100"
      ></section>
      <div class="page-title grey">
        <div class="container">
          <div class="title-area pull-left">
            <h2>Doctor</h2>
            <div class="bread">
              <ol class="breadcrumb">
                <li>
                  <a href="/">Home</a>
                </li>
                <li class="active">Doctor</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      {load ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <section class="section white">
            <div class="container">
              <div class="row">
                <div id="content" class="col-md-12 col-sm-12 col-xs-12">
                  <div class="row">
                    <div class="col-md-5 col-sm-5 col-xs-12">
                      <div class="product-images">
                        <a data-rel="prettyPhoto">
                          <img
                            class="img-polaroid"
                            src={doctordetails?.photo}
                            alt="Doctor Image"
                          />
                        </a>
                      </div>
                    </div>
                    <div class="col-md-7 col-sm-7 col-xs-12">
                      <div class="shop-desc">
                        <h3 style={{ textTransform: "capitalize" }}>
                          {doctordetails?.name}
                        </h3>
                        <small style={{ textTransform: "capitalize" }}>
                          {doctordetails?.currently_working}
                        </small>
                        <p style={{ textTransform: "capitalize" }}>
                          {doctordetails?.designation}
                        </p>
                        <p
                          style={{
                            textTransform: "capitalize",
                            display: "flex",
                          }}
                        >
                          <span style={{ marginRight: "5px" }}>
                            <BiPhoneCall />
                          </span>
                          <span style={{ fontvariantNumeric: "normal" }}>
                            <a href={tm} style={{ fontFamily: "none" }}>
                              {doctordetails?.mobile}
                            </a>
                          </span>
                        </p>
                        <p style={{ display: "flex" }}>
                          {Array.from({ length: 5 }, (data, i) =>
                            i < doctordetails?.overAllRating ? (
                              <span style={{ margin: "2px" }}>
                                <FaStar
                                  style={{
                                    color: "orange",
                                  }}
                                />
                              </span>
                            ) : (
                              false
                            )
                          )}
                          &nbsp;
                          <span
                            style={{
                              padding: "0px",
                              fontWeight: "bold",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {doctordetails?.overAllRating === "0"
                              ? doctordetails?.overAllRating
                              : false}
                          </span>
                        </p>
                      </div>
                      <div class="shopmeta">
                        <span style={{ fontWeight: "bold" }}>
                          <strong>Reg No &nbsp; &nbsp; &nbsp; : &nbsp; </strong>
                          <a> {doctordetails?.doc_reg_no} </a>
                        </span>
                        <div style={{ display: "flex" }}>
                          <span style={{ fontWeight: "bold" }}>
                            <GoLocation />
                          </span>
                          <a
                            style={{
                              textTransform: "capitalize",
                              display: "flex",
                              alignItems: "end",
                              marginLeft: "10px",
                            }}
                          >
                            {doctordetails?.taluk} {doctordetails?.city}&nbsp;
                            {doctordetails?.district} - {doctordetails?.pincode}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <section>
                  <div>
                    {Array.isArray(doctordetails?.ratingsList) &&
                    doctordetails?.ratingsList.length > 0
                      ? doctordetails.ratingsList.map((rat) => (
                          <h1 className="ml-5">Rating </h1>
                        ))
                      : false}
                  </div>
                  {Array.isArray(doctordetails?.ratingsList) &&
                  doctordetails?.ratingsList.length > 0
                    ? doctordetails.ratingsList.map((rat) => (
                        <div style={{ display: "flex" }}>
                          <div>
                            <img
                              src={rat?.user_photo}
                              alt="review image"
                              style={{
                                width: "50px",
                                borderRadius: "50%",
                                marginLeft: "20px",
                              }}
                            />
                          </div>

                          <div
                            style={{ marginLeft: "10px", marginBottom: "0px" }}
                          >
                            <p className="pb-1 ml-3">{rat?.user_name}</p>
                            <p style={{ display: "flex" }}>
                              {Array.from({ length: 5 }, (data, i) =>
                                i < rat?.review ? (
                                  <span style={{ margin: "2px" }}>
                                    <FaStar
                                      style={{
                                        color: "orange",
                                      }}
                                    />
                                  </span>
                                ) : (
                                  false
                                )
                              )}
                            </p>
                          </div>
                        </div>
                      ))
                    : false}
                </section>
                <hr class="invis" />
              </div>

              {adminToken ? (
                <Button
                  onClick={handlereview}
                  style={{
                    backgroundColor: "orange",
                    color: "white",
                    fontWeight: "bold",
                    marginBottom: "20px",
                  }}
                >
                  Add Ratings
                </Button>
              ) : (
                false
              )}

              <div>
                {show ? <Raating /> : false}
                <hr class="invis" />
              </div>

              <div class="row module-wrapper text-center"></div>
            </div>
          </section>
        </>
      )}
    </div>
  );
}

export default Doctordetails;
