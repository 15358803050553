import React from "react";
import logo from "../Assests/images/Vector.svg";
import { NavLink, Link } from "react-router-dom";

const token =
  localStorage.getItem("AuthUserToken") || localStorage.getItem("AuthDoctoken");

const Header = () => {
  const handlelogout = () => {
    localStorage.removeItem("AuthUserToken");
    localStorage.removeItem("AuthDoctoken");
    window.location.reload();
  };
  return (
    <div>
      <header className="header" style={{ padding: "0px" }}>
        <div className="container">
          <div className="row mb-5">
            <div className="col-md-12">
              <nav className="navbar navbar-default">
                <div
                  className="container-fluid"
                  style={{ marginBottom: "10px" }}
                >
                  <div className="navbar-table">
                    <div className="navbar-cell tight">
                      <div className="navbar-header">
                        <Link
                          to="/"
                          className="navbar-brand"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "15px",
                          }}
                        >
                          <img
                            src={logo}
                            alt="Logo"
                            style={{ width: "35px" }}
                          />
                          <span
                            style={{
                              display: "flex",
                              marginLeft: "20px",
                              alignItems: "center",
                              fontSize: "25px",
                              fontWeight: "bold",
                              color: "black",
                            }}
                          >
                            Vetsline
                          </span>
                        </Link>
                        <div>
                          <button
                            type="button"
                            className="navbar-toggle collapsed"
                            data-toggle="collapse"
                            data-target="#bs-example-navbar-collapse-2"
                          >
                            <span className="sr-only">Toggle navigation</span>
                            <span className="fa fa-bars"></span>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="navbar-cell stretch">
                      <div
                        className="collapse navbar-collapse"
                        id="bs-example-navbar-collapse-2"
                      >
                        <div className="navbar-cell">
                          <ul
                            className="nav navbar-nav navbar-right"
                            style={{ paddingTop: "15px" }}
                          >
                            <li>
                              <NavLink
                                // style={({ isActive }) => ({
                                //   color: isActive ? "red" : "black",
                                // })}
                                to="/"
                              >
                                Home
                              </NavLink>
                            </li>

                            <li className="dropdown has-submenu">
                              <a>
                                Resources
                                <span
                                  style={{ marginLeft: "5px" }}
                                  className="fa fa-angle-down"
                                ></span>
                              </a>
                              <ul
                                className="dropdown-menu start-left"
                                role="menu"
                              >
                                <li>
                                  <NavLink to="/Addresource">
                                    Add Resources
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink to="/Doctor">
                                    Veterinary Doctors
                                  </NavLink>
                                </li>

                                <li>
                                  <NavLink to="/Petclinic">Pet Clinic</NavLink>
                                </li>
                                <li>
                                  <NavLink to="/Lapslist">Lab</NavLink>
                                </li>
                                <li>
                                  <NavLink to="/FeedShop">Feed Shops</NavLink>
                                </li>
                                <li>
                                  <NavLink to="FarmEquipments">
                                    Farm Equipments
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink to="PoultryEquipments">
                                    Live Stocks / Poultry Equipments
                                  </NavLink>
                                </li>
                              </ul>
                            </li>
                            <li className="dropdown has-submenu">
                              <a
                                className="dropdown-toggle"
                                data-toggle="dropdown"
                                role="button"
                                aria-expanded="false"
                              >
                                Animals
                                <span
                                  style={{ marginLeft: "5px" }}
                                  className="fa fa-angle-down"
                                ></span>
                              </a>

                              <ul
                                className="dropdown-menu start-left"
                                role="menu"
                              >
                                <li>
                                  <NavLink to="/animals/matchfind">
                                    Match Find
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink to="/animals/adoption">
                                    Adoption
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink to="/animals/buysell">
                                    Buy & Sell
                                  </NavLink>
                                </li>
                              </ul>
                            </li>
                            <li className="dropdown has-submenu">
                              <a
                                className="dropdown-toggle"
                                data-toggle="dropdown"
                                role="button"
                                aria-expanded="false"
                              >
                                Doctors Guide
                                <span
                                  style={{ marginLeft: "5px" }}
                                  className="fa fa-angle-down"
                                ></span>
                              </a>

                              <ul
                                className="dropdown-menu start-left"
                                role="menu"
                              >
                                <li>
                                  <NavLink to="/guide/AddDoseCalculator">
                                    Dose Calculator
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink to="/guide/AddDrugIndex">
                                    Drug Index Calculator
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink to="/guide/FormProjectGuide">
                                    Form Project Guide
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink to="/guide/AddFeedFormulation">
                                    Feed Formulation
                                  </NavLink>
                                </li>
                              </ul>
                            </li>
                            <li className="dropdown has-submenu">
                              <a
                                className="dropdown-toggle"
                                data-toggle="dropdown"
                                role="button"
                                aria-expanded="false"
                              >
                                Digi Records
                                <span
                                  style={{ marginLeft: "5px" }}
                                  className="fa fa-angle-down"
                                ></span>
                              </a>
                              <ul
                                className="dropdown-menu start-left"
                                role="menu"
                              >
                                <li>
                                  <NavLink to="/digirecords/Createdigirecords">
                                    Create Digi Records
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink to="/digirecords/CreateExitingClient">
                                    Create Existing Clients
                                  </NavLink>
                                </li>
                                <li>
                                  <NavLink to="/digirecords/CreateExistingAnimals">
                                    Create Existing Animals
                                  </NavLink>
                                </li>

                                {/* <li>
                                  <NavLink to="/digirecords/EditDigiAnimals">Edit Animals</NavLink>
                                </li> */}
                              </ul>
                            </li>
                            <li>
                              <NavLink
                                style={({ isActive }) => ({
                                  color: isActive ? "red" : "",
                                })}
                                to="/news"
                              >
                                News
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                style={({ isActive }) => ({
                                  color: isActive ? "red" : "",
                                })}
                                to="/contact"
                              >
                                Contact
                              </NavLink>
                            </li>
                            {token ? (
                              <li>
                                <NavLink
                                  style={({ isActive }) => ({
                                    color: isActive ? "red" : "",
                                  })}
                                  to="/profilepage"
                                >
                                  Profile
                                </NavLink>
                              </li>
                            ) : (
                              false
                            )}
                            {!token ? (
                              <li className="dropdown has-submenu">
                                <a>
                                  Login
                                  <span
                                    style={{ marginLeft: "5px" }}
                                    className="fa fa-angle-down"
                                  ></span>
                                </a>
                                <ul
                                  className="dropdown-menu start-left"
                                  role="menu"
                                >
                                  <li>
                                    <NavLink
                                      style={({ isActive }) => ({
                                        color: isActive ? "white" : "white",
                                      })}
                                      to="/Doctorlogin"
                                    >
                                      Doctor
                                    </NavLink>
                                  </li>

                                  <li>
                                    <NavLink
                                      style={({ isActive }) => ({
                                        color: isActive ? "white" : "white",
                                      })}
                                      to="/userlogin"
                                    >
                                      User
                                    </NavLink>
                                  </li>
                                </ul>
                              </li>
                            ) : (
                              <a
                                style={{
                                  fontWeight: "500",
                                  letterSpacing: "1px",
                                  marginLeft: "10px",
                                }}
                                href="/"
                                onClick={handlelogout}
                              >
                                Logout
                              </a>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
