import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CardMedia from "@mui/material/CardMedia";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import CircularProgress from "@mui/material/CircularProgress";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { resourcelist } from "../../../Redux/Actions/actions";
import nopost from "./nopostsfound.svg";
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));
export default function Profile() {
  const [load, setLoad] = useState(true);
  const { status } = useParams();
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);
  console.log(status,"jhdegfuy");
  useEffect(() => {
    dispatch(resourcelist({ status: status })).then((res) => {
      setLoad(false);
    });
  }, []);
  const userrosourcelist = useSelector(
    ({ resourcelist }) => resourcelist.payload
  );
  return (
    <>
      <section
        className="section banner"
        style={{ backgroundImage: "url('../Assests/upload/vet_bg.jpg')" }}
        data-img-width="1688"
        data-img-height="470"
        data-diff="100"
      ></section>
      <div class="page-title grey">
        <div class="container">
          <div class="title-area pull-left">
            <h2>Profile</h2>
            <div class="bread">
              <ol class="breadcrumb">
                <li>
                  <a href="/">Home</a>
                </li>
                <li href="/">Profile</li>
                <li class="active">Our Posts</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <Container>
        <Box mt={5}>
          <Typography
            variant="h4"
            style={{ color: "orange", fontWeight: "600" }}
            component="h1"
          >
            My Posts
          </Typography>
        </Box>
        {load ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "60vh",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <Box m={5}>
            <Grid container spacing={2}>
              {Array.isArray(userrosourcelist) &&
              userrosourcelist.length > 0 ? (
                userrosourcelist.map((user) => (
                  <Grid item xs={12} sm={12} md={4} lg={3}>
                    <Card sx={{ maxWidth: 345 }} style={{ height: "450px" }}>
                      <CardHeader
                        style={{ textTransform: "capitalize" }}
                        title={user?.name}
                        subheader={user?.owner_name}
                      />
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <CardMedia
                          style={{ padding: "20px", width: "75%" }}
                          component="img"
                          image={user?.photos[0]}
                          alt="User Image"
                        />
                      </div>
                      <CardContent>
                        <Typography variant="body2" color="text.secondary">
                          <span style={{ color: "orange", marginRight: "5px" }}>
                            <LocalPhoneIcon />
                          </span>
                          {user?.mobile}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          <span style={{ color: "orange", marginRight: "5px" }}>
                            <LocationOnIcon />
                          </span>
                          {user?.street} {user?.taluk}&nbsp;
                          {user?.district} {user?.pincode}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))
              ) : (
                <>
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <img src={nopost} style={{ width: "300px" }} />
                    <Box mt={3}>
                      <Typography variant="h5" component="h6">
                        No Posts Found
                      </Typography>
                    </Box>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        )}
      </Container>
    </>
  );
}
