import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Grid,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Container,
  Card,
  Select,
  Button,
  Typography,
} from "@mui/material";
import { useNavigate, Link } from "react-router-dom";

function AddDoseCalculator() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [dosecalci, setDosecalci] = useState({
    mgperml: "",
    weight: "",
    Doserate: "",
  });
  const [error, setError] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    setShow(true);
    console.log(result);
  };
  const handleChange = (e) => {
    setDosecalci({
      ...dosecalci,
      [e.target.name]: e.target.value,
    });
  };
  const result = (dosecalci.Doserate * dosecalci.weight) / dosecalci.mgperml;

  return (
    <div>
      <section
        className="section banner"
        style={{ backgroundImage: "url('../Assests/upload/vet_bg.jpg')" }}
        data-img-width="1688"
        data-img-height="470"
        data-diff="100"
      ></section>
      <div class="page-title grey">
        <div class="container">
          <div class="title-area pull-left">
            <h2> Dose Calculator</h2>
            <div class="bread">
              <ol class="breadcrumb">
                <li>
                  <a href="/">Home</a>
                </li>
                <li class="active"> Dose Calculator</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <Container className="ml-5 mr-5 my-5 py-5">
        <Card>
          <Box component="form" onSubmit={handleSubmit}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                sx={{
                  mt: 8,
                  mx: 4,
                }}
              >
                <h2 style={{ fontWeight: "bold" }}>Dose Calculator</h2>
                <br />
                <FormControl fullWidth>
                  <InputLabel id="daemo-simple-select-label">
                    Select Drug Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="Select Drug Type"
                    label="Select Drug Type"
                    onChange={handleChange}
                  >
                    <MenuItem value="" selected>
                      Select Drug Type
                    </MenuItem>
                    <MenuItem value="Inj, Syrup, Tonic">
                      Inj, Syrup, Tonic
                    </MenuItem>
                    <MenuItem value="polus">Polus</MenuItem>
                    <MenuItem value="tab">Tab</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  type="text"
                  onChange={handleChange}
                  id="mgperml"
                  label="Mg per Ml"
                  name="mgperml"
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  type="text"
                  onChange={handleChange}
                  id="Doserate"
                  label="Dose Rate"
                  name="Doserate"
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  type="text"
                  onChange={handleChange}
                  id="weight"
                  label="Weight"
                  name="weight"
                />
                <br />
              </Box>
            </Grid>
            <Box
              className=" mb-5 mt-3 pt-3"
              sx={{
                mb: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Button
                type="submit"
                fullWidth
                style={{ backgroundColor: "#ED6C10", color: "white" }}
              >
                Calculate
              </Button>
              <Grid container className="mt-5">
                <Grid item xs>
                  <Typography variant="h5" component="h2">
                    {show ? (
                      <span>
                        <span style={{ fontWeight: "bold" }}> Result </span>:{" "}
                        {result} ml per {dosecalci.weight} body weight kg
                      </span>
                    ) : (
                      false
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Grid />
            </Box>
          </Box>
        </Card>
      </Container>
    </div>
  );
}

export default AddDoseCalculator;
