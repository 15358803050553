import {
  user,
  newsfeed,
  resources,
  Animals,
  Animaldetails,
  doctor,
  RecentNews,
  designation,
  district,
  taluk,
  Whatsup,
  digirecords,
  FeedFormulation,
} from "../Actionstype/Actionstype";

const initialState = {};
export const usersendotp = (state = initialState, actions = {}) => {
  switch (actions.type) {
    case user.usersendotp.success:
      return {
        status: true,
        payload: actions.data,
      };
    case user.usersendotp.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
export const userlogin = (state = initialState, actions = {}) => {
  switch (actions.type) {
    case user.login.success:
      return {
        status: true,
        payload: actions.data,
      };
    case user.login.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
export const usereditrequest = (state = initialState, actions = {}) => {
  switch (actions.type) {
    case user.editrequest.success:
      return {
        status: true,
        payload: actions.data,
      };
    case user.editrequest.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
export const doctorlogin = (state = initialState, actions = {}) => {
  switch (actions.type) {
    case doctor.login.success:
      return {
        status: true,
        payload: actions.data,
      };
    case doctor.login.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
export const doctorsignup = (state = initialState, actions = {}) => {
  switch (actions.type) {
    case doctor.signup.success:
      return {
        status: true,
        payload: actions.data,
      };
    case doctor.signup.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
export const docinstantapproval = (state = initialState, actions = {}) => {
  switch (actions.type) {
    case doctor.instantapproval.success:
      return {
        status: true,
        payload: actions.data,
      };
    case doctor.instantapproval.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
export const customersignup = (state = initialState, actions = {}) => {
  switch (actions.type) {
    case user.register.success:
      return {
        status: true,
        payload: actions.data,
      };
    case user.register.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
export const designationlist = (state = initialState, actions = {}) => {
  switch (actions.type) {
    case designation.list.success:
      return {
        status: true,
        payload: actions.data,
      };
    case designation.list.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
export const districtlist = (state = initialState, actions = {}) => {
  switch (actions.type) {
    case district.get.success:
      return {
        status: true,
        payload: actions.data,
      };
    case district.get.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
export const taluklist = (state = initialState, actions = {}) => {
  switch (actions.type) {
    case taluk.get.success:
      return {
        status: true,
        payload: actions.data,
      };
    case taluk.get.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
export const resourcelist = (state = initialState, actions = {}) => {
  switch (actions.type) {
    case user.resources.success:
      return {
        status: true,
        payload: actions.data,
      };
    case user.resources.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
export const usergetprofile = (state = initialState, actions = {}) => {
  switch (actions.type) {
    case user.getprofile.success:
      return {
        status: true,
        payload: actions.data,
      };
    case user.getprofile.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
export const searchnewsfeedlist = (state = initialState, actions = {}) => {
  switch (actions.type) {
    case newsfeed.search.success:
      return {
        status: true,
        payload: actions.data,
      };
    case newsfeed.search.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
export const searchnewsdecription = (state = initialState, actions = {}) => {
  switch (actions.type) {
    case newsfeed.desc.success:
      return {
        status: true,
        payload: actions.data,
      };
    case newsfeed.desc.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
export const addresource = (state = initialState, actions = {}) => {
  switch (actions.type) {
    case resources.add.success:
      return {
        status: true,
        payload: actions.data,
      };
    case resources.add.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
export const adddigianimals = (state = initialState, actions = {}) => {
  switch (actions.type) {
    case resources.add.success:
      return {
        status: true,
        payload: actions.data,
      };
    case resources.add.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
export const editdigianimals = (state = initialState, actions = {}) => {
  switch (actions.type) {
    case resources.add.success:
      return {
        status: true,
        payload: actions.data,
      };
    case resources.add.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
export const createdigirecords = (state = initialState, actions = {}) => {
  switch (actions.type) {
    case resources.create.success:
      return {
        status: true,
        payload: actions.data,
      };
    case resources.create.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
export const addbuyanimals = (state = initialState, actions = {}) => {
  switch (actions.type) {
    case Animals.addbuy.success:
      return {
        status: true,
        payload: actions.data,
      };
    case Animals.addbuy.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
export const verifybuysell = (state = initialState, actions = {}) => {
  switch (actions.type) {
    case Animals.verifysell.success:
      return {
        status: true,
        payload: actions.data,
      };
    case Animals.verifysell.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
export const addmatchfindanimals = (state = initialState, actions = {}) => {
  switch (actions.type) {
    case Animals.addmatchfind.success:
      return {
        status: true,
        payload: actions.data,
      };
    case Animals.addmatchfind.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
export const addadoptionanimals = (state = initialState, actions = {}) => {
  switch (actions.type) {
    case Animals.addadoption.success:
      return {
        status: true,
        payload: actions.data,
      };
    case Animals.addadoption.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
export const doctorresourcelist = (state = initialState, actions = {}) => {
  switch (actions.type) {
    case resources.list.success:
      return {
        status: true,
        payload: actions.data,
      };
    case resources.list.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
export const doctorresourcedetails = (state = initialState, actions = {}) => {
  switch (actions.type) {
    case resources.details.success:
      return {
        status: true,
        payload: actions.data,
      };
    case resources.details.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
export const Adddoctorrating = (state = initialState, actions = {}) => {
  switch (actions.type) {
    case doctor.addrating.success:
      return {
        status: true,
        payload: actions.data,
      };
    case doctor.addrating.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
export const resourcetypelist = (state = initialState, actions = {}) => {
  switch (actions.type) {
    case resources.typelist.success:
      return {
        status: true,
        payload: actions.data,
      };
    case resources.typelist.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
export const resourcelistdetails = (state = initialState, actions = {}) => {
  switch (actions.type) {
    case resources.listdetails.success:
      return {
        status: true,
        payload: actions.data,
      };
    case resources.listdetails.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
export const addresourcerating = (state = initialState, actions = {}) => {
  switch (actions.type) {
    case resources.addrating.success:
      return {
        status: true,
        payload: actions.data,
      };
    case resources.addrating.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
export const animalsbuylist = (state = initialState, actions = {}) => {
  switch (actions.type) {
    case Animals.buy.success:
      return {
        status: true,
        payload: actions.data,
      };
    case Animals.buy.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
export const animalslist = (state = initialState, actions = {}) => {
  switch (actions.type) {
    case Animals.list.success:
      return {
        status: true,
        payload: actions.data,
      };
    case Animals.list.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
export const animalsadoptlist = (state = initialState, actions = {}) => {
  switch (actions.type) {
    case Animals.adoption.success:
      return {
        status: true,
        payload: actions.data,
      };
    case Animals.adoption.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
export const animalsmatchfindlist = (state = initialState, actions = {}) => {
  switch (actions.type) {
    case Animals.matchfind.success:
      return {
        status: true,
        payload: actions.data,
      };
    case Animals.matchfind.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
export const animalsbuylistdetails = (state = initialState, actions = {}) => {
  switch (actions.type) {
    case Animaldetails.buy.success:
      return {
        status: true,
        payload: actions.data,
      };
    case Animaldetails.buy.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
export const animalsadoptiondetails = (state = initialState, actions = {}) => {
  switch (actions.type) {
    case Animaldetails.adoption.success:
      return {
        status: true,
        payload: actions.data,
      };
    case Animaldetails.adoption.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
export const animalsmatchdetails = (state = initialState, actions = {}) => {
  switch (actions.type) {
    case Animaldetails.matchfind.success:
      return {
        status: true,
        payload: actions.data,
      };
    case Animaldetails.matchfind.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
export const RecentNewslist = (state = initialState, actions = {}) => {
  switch (actions.type) {
    case RecentNews.get.success:
      return {
        status: true,
        payload: actions.data,
      };
    case RecentNews.get.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
export const Whatsuplink = (state = initialState, actions = {}) => {
  switch (actions.type) {
    case Whatsup.get.success:
      return {
        status: true,
        payload: actions.data,
      };
    case Whatsup.get.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
export const digipoultrylist = (state = initialState, actions = {}) => {
  switch (actions.type) {
    case digirecords.polutrylist.success:
      return {
        status: true,
        payload: actions.data,
      };
    case digirecords.polutrylist.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
export const Feedformulationlist = (state = initialState, actions = {}) => {
  switch (actions.type) {
    case FeedFormulation.getlist.success:
      return {
        status: true,
        payload: actions.data,
      };
    case FeedFormulation.getlist.error:
      return {
        status: false,
        payload: actions.data,
      };
    default:
      return state;
  }
};
