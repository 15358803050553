import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
//Basic pages
import Home from "../Pages/Home";
import Singlenews from "../Pages/Singlenews";
import Contact from "../Pages/Contact";
//Guide Start
import AddDoseCalculator from "../Pages/FeedFormulation/AddDoseCalculator";
import Poultry from "../Pages/FeedFormulation/SubFeedFormulatn/Poultry";
import Charts from "../Pages/FeedFormulation/SubFeedFormulatn/Charts";
import Ruminant from "../Pages/FeedFormulation/SubFeedFormulatn/Ruminant";
import AddDrugIndex from "../Pages/FeedFormulation/AddDrugIndex";
import CalculatedPrice from "../Pages/FeedFormulation/CalculatedPrice";
import AddFeedFormulation from "../Pages/FeedFormulation/AddFeedFormulation";
import RuminantIndex from "../Pages/FeedFormulation/Ruminant/RuminantIndex";
import FormProjectGuide from "../Pages/FeedFormulation/FormProjectGuide";
//Guide End
//Resource
import Addresource from "../Pages/Resources/Addresource";
import Petclinic from "../Pages/Resources/Petclinic";
import FeedShop from "../Pages/Resources/FeedShop";
import FarmEquipments from "../Pages/Resources/FarmEquipments";
import PoultryEquipments from "../Pages/Resources/PoultryEquipments";
import Lapslist from "../Pages/Resources/Lapslist";
//Resource End
//Animals start
import Adoption from "../Pages/Animals/Adoption";
import BuySell from "../Pages/Animals/BuySell";
import Addbuysell from "../Pages/Animals/Addbuysell";
import AddAdotion from "../Pages/Animals/AddAdoption";
import AddMatchfind from "../Pages/Animals/AddMatchfind";
import MatchFind from "../Pages/Animals/MatchFind";
import SuccessPage from "../Pages/Animals/SuccessPage";
import BuysellDetails from "../Pages/Animals/BuysellDetails";
import Adoptiondetails from "../Pages/Animals/Adoptiondetails";
import AnimalOtp from "../Pages/Animals/AnimalOtp";
import Animaladoption from "../Pages/Animals/Animaladoption";
import MatchfindDetails from "../Pages/Animals/MatchfindDetails";
//Animals End
import Resourcedetails from "../Pages/Resource/Resourcedetails";
import Doctordetails from "../Pages/Resources/Doctordetails";
import News from "../Pages/News";
import ProductDetails from "../Pages/ProductDetails";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import NotFound from "../Common/NotFound";
import Doctor from "../Pages/Doctor";

// Digi_Records Start
import AddDigiAnimals from "../Pages/DigiRecords/AddDigiAnimals";
import EditDigiAnimals from "../Pages/DigiRecords/EditDigiAnimals";
import Createdigirecords from "../Pages/DigiRecords/Createdigirecords";
import Createdigirecords1 from "../Pages/DigiRecords/Createdigirecords1";
import Createdigirecords2 from "../Pages/DigiRecords/Createdigirecords2";
import AddNewUser from "../Pages/DigiRecords/AddNewUser";
import CreateExistingClient from "../Pages/DigiRecords/CreateExitingClient";
import CreateExistingAnimals from "../Pages/DigiRecords/CreateExistingAnimal";
// Digi_Records End

//Login
import Usersignup from "../Pages/LoginD_U/Usersignup";
import Editprofile from "../Pages/LoginD_U/Profile/Editprofile";
import Profileindex from "../Pages/LoginD_U/Profile/Profileindex";
import DoctorSignup from "../Pages/LoginD_U/DoctorSignup";
import Profilepending from "../Pages/LoginD_U/Profile/Profilepending";
import InstantApproval from "../Pages/LoginD_U/Profile/InstantApproval";
import Profileeditrequest from "../Pages/LoginD_U/Profile/Profileeditrequest";
import Approvalsuccess from "../Pages/LoginD_U/Profile/Approvalsuccess";
import DoctorLogin from "../Pages/LoginD_U/DoctorLogin";
import Profile from "../Pages/LoginD_U/Profile/Profile";
import UserLogin from "../Pages/LoginD_U/UserLogin";
import Profilepage from "../Pages/LoginD_U/Profile/Profilepage";
import Doctorotp from "../Pages/LoginD_U/Doctorotp";
import Userotp from "../Pages/LoginD_U/Userotp";

const MainRoutes = () => {
  return (
    <div>
      <Router>
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route
            path="/guide/AddDoseCalculator"
            element={<AddDoseCalculator />}
          />
          <Route path="/guide/RuminantIndex" element={<RuminantIndex />} />
          <Route path="/guide/CalculatedPrice" element={<CalculatedPrice />} />
          <Route path="/guide/AddDrugIndex" element={<AddDrugIndex />} />
          <Route
            path="/guide/FormProjectGuide"
            element={<FormProjectGuide />}
          />
          <Route
            path="/guide/AddFeedFormulation"
            element={<AddFeedFormulation />}
          />
          <Route path="/guide/FeedFormulation/Poultry" element={<Poultry />} />
          <Route
            path="/guide/FeedFormulation/Poultry/Charts"
            element={<Charts />}
          />
          <Route
            path="/guide/FeedFormulation/Ruminant"
            element={<Ruminant />}
          />
          <Route path="/animals/matchfind" element={<MatchFind />} />

          <Route
            path="/digirecords/CreateExitingClient"
            element={<CreateExistingClient />}
          />
          <Route
            path="/digirecords/CreateExistingAnimals"
            element={<CreateExistingAnimals />}
          />
          <Route
            path="/digirecords/AddDigiAnimals"
            element={<AddDigiAnimals />}
          />
          <Route
            path="/digirecords/EditDigiAnimals"
            element={<EditDigiAnimals />}
          />
          <Route path="/digirecords/AddNewUser" element={<AddNewUser />} />
          <Route
            path="/digirecords/Createdigirecords"
            element={<Createdigirecords />}
          />
          <Route
            path="/digirecords/Createdigirecords2"
            element={<Createdigirecords2 />}
          />
          <Route
            path="/digirecords/Createdigirecords1"
            element={<Createdigirecords1 />}
          />

          <Route path="/animals/buysell" element={<BuySell />} />
          <Route path="/animals/AddMatchfind" element={<AddMatchfind />} />
          <Route path="/animals/AddAdoption" element={<AddAdotion />} />
          <Route path="/animals/AnimalOtp/:id" element={<AnimalOtp />} />
          <Route
            path="/animals/Animaladoption/:id"
            element={<Animaladoption />}
          />
          <Route path="/animals/Addbuysell" element={<Addbuysell />} />
          <Route path="/animals/SuccessPage" element={<SuccessPage />} />
          <Route path="/animals/adoption" element={<Adoption />} />
          <Route path="/Addresource" element={<Addresource />} />
          <Route path="/Doctor" element={<Doctor />} />
          <Route path="/myposts/:status" element={<Profile />} />
          <Route path="/Profileindex" element={<Profileindex />} />
          <Route path="/Profileeditrequest" element={<Profileeditrequest />} />
          <Route path="/Profilepage" element={<Profilepage />} />
          <Route path="/Editprofile" element={<Editprofile />} />
          <Route path="/Usersignup" element={<Usersignup />} />
          <Route path="/UserLogin" element={<UserLogin />} />
          <Route path="/Doctorsignup" element={<DoctorSignup />} />
          <Route path="/Approvalsuccess" element={<Approvalsuccess />} />
          <Route path="/Profilepending" element={<Profilepending />} />
          <Route path="/InstantApproval" element={<InstantApproval />} />
          <Route path="/DoctorLogin" element={<DoctorLogin />} />
          <Route path="/Doctorotp/:id" element={<Doctorotp />} />
          <Route path="/Userotp/:id" element={<Userotp />} />
          <Route path="/ProductDetails" element={<ProductDetails />} />
          <Route path="/Doctordetails/:id" element={<Doctordetails />} />
          <Route path="/Resourcedetails/:id" element={<Resourcedetails />} />
          <Route path="/Adoptiondetails/:id" element={<Adoptiondetails />} />
          <Route path="/BuysellDetails/:id" element={<BuysellDetails />} />
          <Route path="/MatchfindDetails/:id" element={<MatchfindDetails />} />
          <Route path="/Petclinic" element={<Petclinic />} />
          <Route path="/FarmEquipments" element={<FarmEquipments />} />
          <Route path="/PoultryEquipments" element={<PoultryEquipments />} />
          <Route path="/FeedShop" element={<FeedShop />} />
          <Route path="/Lapslist" element={<Lapslist />} />
          <Route path="/Singlenews/:id" element={<Singlenews />} />
          <Route path="/news" element={<News />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
};

export default MainRoutes;
