import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import { Link ,  useNavigate } from "react-router-dom";
import { animalslist } from "../../Redux/Actions/actions";
import {
  adddigianimals
} from "../../Redux/Actions/actions";
function AddNewUser() {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [search, setSearch] = useState("");
const [data,setData] = useState({
  mobile: "",
})
  useEffect(() => {
    dispatch(animalslist());
  }, []);
  const handlesubmit = (e) => {
    e.preventDefault();
  };
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const animallist = useSelector(({ animalslist }) => animalslist.payload);
  const err = useSelector(({ userlogin }) => userlogin.payload);
  return ( 
    <div>
      <section
        className="section banner"
        style={{ backgroundImage: "url('../Assests/upload/vet_bg.jpg')" }}
        data-img-width="1688"
        data-img-height="470"
        data-diff="100"
      ></section>
      <div className="page-title grey">
        <div className="container">
          <div className="title-area pull-left">
            <h2>Add Animals</h2>
            <div className="bread">
              <ol className="breadcrumb">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="active">Add New User</li>
              </ol>
            </div>
            {/* <!-- end bread --> */}
          </div>
          {/* <!-- /.pull-right --> */}
          <div className="search pull-right">
            <form>{/* <!-- /input-group --> */}</form>
          </div>
          {/* <!-- /.pull-right --> */}
          <div
            className="search pull-right mb-3 "
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
         Search Existing User
            <form onSubmit={handlesubmit}>
              <div>
                <div className="input-group">
                  <input
                    className="form-control"
                    onChange={(e) => setSearch(e.target.value)}
                    name="search"
                    type="search"
                    placeholder=" Search..."
                  />
                  <span className="input-group-btn">
                    <button type="submit" className="btn btn-primary">
                      <i className="fa fa-search"></i>
                    </button>
                  </span>
                </div>
              </div>
              {/* <!-- /input-group --> */}
            </form>
          </div>
        </div>
      </div>
      <Container>
        <Box component="form" >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <Grid item xs={12} sm={12} md={12}>
                <Box
                  sx={{
                    mt: 8,
                    mb:8,
                    mx: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
         <TextField
                    margin="normal"
                    required
                    fullWidth
                    
                    onChange={handleChange}
                    id="mobile"
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 10);
                    }}
                    label="Mobile Number"
                    name="mobile"
                  />
                  <span
                    style={{
                      color: "red",
                      marginTop: "10px",
                    }}
                  >
                    {error ? (
                      <Stack direction="row" spacing={1}>
                        <Chip label={error} color="#ED6C10" />
                      </Stack>
                    ) : (
                      false
                    )}
                  </span>
                  <Button
                    type="submit"
                    fullWidth
                    style={{ backgroundColor: "#ED6C10", color: "white" }}
                  >
                    Add User
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
}

export default AddNewUser;
