import { Typography } from "@mui/material";
import React from "react";
import successimage from "./success.svg";
function SuccessPage() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "60vh",
      }}
    >
      <Typography variant="h5">Post Published</Typography>
      <br />
      <img src={successimage} style={{ width: "200px" }} />
      <br />
      <Typography variant="h6" style={{ fontWeight: "bold" }}>
        Payment Received Rs.30
      </Typography>
      <Typography variant="h6">
        Your Animal Posted for <br />
        Buy & Sell - 20-05-2022
      </Typography>
      <Typography variant="h6">
        Your id #178938871939 Posts
        <br /> Will be automatically after 45 days
      </Typography>
    </div>
  );
}

export default SuccessPage;
