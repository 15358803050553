import React from "react";
import { Link } from "react-router-dom";
import Chip from "@mui/material/Chip";
import profile from "./approvalsuccess.svg";

const mystyle = {
  fontWeight: "bold",
};

function Approvalsuccess() {
  return (
    <div className="mt-5 mb-5 pb-5 pt-5">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "60vh",
        }}
      >
        <h1 style={mystyle}>
          Approval Request Sent Successfully, Please wait while
        </h1>
        <br />
        <img src={profile} style={{ width: "300px" }} />
        <h1 style={mystyle}>It Takes few hours to verify your details.</h1>
        <br />
        <br />
        <Link to="/">
          <Chip
            style={{
              padding: "10px",
              cursor: "pointer",
              color: "#ED6C10",
              background: "white",
              outline: "2px solid #ED6C10",
              fontWeight: "bold",
            }}
            label=" Back to Home"
          />
        </Link>
        <br />
      </div>
    </div>
  );
}
export default Approvalsuccess;
