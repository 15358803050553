import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { AiOutlineUpload } from "react-icons/ai";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Card from "@mui/material/Card"
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import { Link, useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  addresource,
  districtlist,
  taluklist,
} from "../../Redux/Actions/actions";

function Addresource() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [photo, setPhoto] = useState([""]);
  const [loading, setLoading] = useState(false);
  const [districtvalue, setDistrictvalue] = useState("");
  const [error, setError] = useState("");
  const [userdata, setUserdata] = useState({
    name: "",
    mobile: "",
    ownerName: "",
    photo: [],
    resourceType: "",
    doorno: "",
    street: "",
    taluk: "",
    city: "",
    district: "",
    pincode: "",
  });
  useEffect(() => {
    dispatch(districtlist());
    dispatch(taluklist());
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (userdata.photo.length > 0) {
      const formData = new FormData();
      Object.entries(userdata).map(([key, value]) => {
        if (key == "photo") {
          value.map((item, index) => {
            formData.append(`photo`, item);
          });
        } else {
          formData.append(key, value);
        }
      });
      setLoading(true);
      dispatch(addresource(formData))
        .then((res) => {
          setLoading(false);
          if (res.data.data === "Resource added successfully") {
            navigate("/");
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err.response);
        });
    } else {
      setError("Please upload photo");
    }
  };

  const resourceimage3 = (e, index) => {
    if (e.target.files && e.target.files.length > 0) {
      userdata[e.target.name][index] = e.target.files[0];
      const updatedPhoto = [...userdata[e.target.name]];
      setUserdata({
        ...userdata,
        [e.target.name]: updatedPhoto,
      });
      photo[index] = URL.createObjectURL(e.target.files[0]);
      const imageUrls = [...photo];
      setPhoto(imageUrls);
    }
  };

  const handleChange = (e) => {
    setUserdata({
      ...userdata,
      [e.target.name]: e.target.value,
    });
  };
  const handleAddImage = () => {
    if (photo[photo.length - 1]) {
      photo.push("");
      const imageUrls = [...photo];
      setPhoto(imageUrls);
    }
  };
  const handledistrictChange = (e) => {
    setUserdata({
      ...userdata,
      [e.target.name]: e.target.value,
    });
    setDistrictvalue(e.target.value);
  };
  const err = useSelector(({ userlogin }) => userlogin.payload);
  const districtlistt = useSelector(({ districtlist }) => districtlist.payload);
  const taluklistt = useSelector(({ taluklist }) => taluklist.payload);
  return (
    <div>
      <section
        className="section banner"
        style={{ backgroundImage: "url('../Assests/upload/vet_bg.jpg')" }}
        data-img-width="1688"
        data-img-height="470"
        data-diff="100"
      ></section>
      <div className="page-title grey">
        <div className="container">
          <div className="title-area pull-left">
            <h2>Add Resource</h2>
            <div className="bread">
              <ol className="breadcrumb">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="active">Add Resource</li>
              </ol>
            </div>
            {/* <!-- end bread --> */}
          </div>
          {/* <!-- /.pull-right --> */}
          <div className="search pull-right">
            <form>{/* <!-- /input-group --> */}</form>
          </div>
          {/* <!-- /.pull-right --> */}
        </div>
      </div>
      {/* <!-- end page-title --> */}
      <Container>
        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4}>
              <Grid item xs={12} sm={12} md={12}>
                <Box
                  sx={{
                    mt: 8,
                    mx: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div style={{ fontWeight: "bold" }}>Personal Information</div>

                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    type="text"
                    onChange={handleChange}
                    id="name"
                    label="Name"
                    name="name"
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    type="number"
                    onChange={handleChange}
                    id="mobile"
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 10);
                    }}
                    label="Mobile Number"
                    name="mobile"
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    type="text"
                    onChange={handleChange}
                    id="ownerName"
                    label="Owner Name"
                    name="ownerName"
                  />
                  <br />
                  <FormControl fullWidth>
                    <InputLabel id="daemo-simple-select-label">
                      Resource Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="resourceType"
                      label="Resource Type"
                      required
                      onChange={handleChange}
                    >
                      <MenuItem selected>Select Resource Type</MenuItem>
                      <MenuItem value="LABS">Labs</MenuItem>
                      <MenuItem value="FEED_SHOPS">Feed Shop</MenuItem>
                      <MenuItem value="PET_CLINIC">Pet Clinic</MenuItem>
                      <MenuItem value="POULTRY_EQUIPMENTS">
                        Poultry Equipments
                      </MenuItem>
                      <MenuItem value="FARM_EQUIPMENTS">
                        Farm Equipments
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <br />
                  <br />
                  <TextField
                    required
                    fullWidth
                    type="number"
                    onChange={handleChange}
                    id="doorno"
                    label="Door No"
                    name="doorno"
                  />
                  <span
                    style={{
                      color: "red",
                      marginTop: "10px",
                    }}
                  >
                    {error ? error : false}
                  </span>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Grid item xs={12} sm={12} md={12}>
                <Box
                  sx={{
                    mt: 8,
                    mx: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div style={{ fontWeight: "bold" }}>Address Information</div>

                  <TextField
                    margin="normal"
                    required
                    type="text"
                    fullWidth
                    onChange={handleChange}
                    id="street"
                    label="Street Name"
                    name="street"
                  />
                  <br />
                  <FormControl fullWidth>
                    <InputLabel id="daemo-simple-select-label">
                      District
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="district"
                      label="district"
                      required
                      onChange={(e) => handledistrictChange(e)}
                    >
                      <MenuItem selected>Select District</MenuItem>
                      {Array.isArray(districtlistt) && districtlistt.length > 0
                        ? districtlistt.map((Item) => (
                            <MenuItem value={Item?.district}>
                              {Item?.district}
                            </MenuItem>
                          ))
                        : false}
                    </Select>
                  </FormControl>
                  <br />
                  <br />
                  <FormControl fullWidth>
                    <InputLabel id="daemo-simple-select-label">
                      Taluk
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="taluk"
                      label="Taluk"
                      required
                      onChange={handleChange}
                    >
                      <MenuItem selected>Select Taluk</MenuItem>
                      {Array.isArray(taluklistt) && taluklistt.length > 0
                        ? taluklistt
                            .filter((data) => data.district === districtvalue)
                            .map((Item) => (
                              <MenuItem value={Item?.taluk}>
                                {Item?.taluk}
                              </MenuItem>
                            ))
                        : false}
                    </Select>
                  </FormControl>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    type="text"
                    onChange={handleChange}
                    id="city"
                    label="City Name"
                    name="city"
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    type="number"
                    onChange={handleChange}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 6);
                    }}
                    id="pincode"
                    label="Pincode "
                    name="pincode"
                  />

                  <span
                    style={{
                      color: "red",
                      marginTop: "10px",
                    }}
                  >
                    {err ? err : false}
                  </span>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Grid item xs={12} sm={12} md={12}>
                <Box
                  sx={{
                    mt: 20,
                    mx: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {photo.map((dataimage, index) => (
                    <label htmlFor={"upload-resource-image" + index}>
                      <input
                        id={"upload-resource-image" + index}
                        name="photo"
                        onChange={(e) => resourceimage3(e, index)}
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                      />
                  
                        <Button component="span"  style={{
                          padding: "60px",
                          border: "1px dashed black",
                          display:"flex",
                          flexDirection:"column",

                        }}>

                      <AiOutlineUpload style={{
                        fontSize:"25px",
                           }} />
                          {dataimage ? "Change Image" : "Select image"}
                        </Button>
                    

                      {photo[index] ? (
                        <div
                          style={{
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={photo[index]}
                            style={{
                              width: "100px",
                              height: "10vh",
                              marginTop: "20px",
                              color: "red",
                            }}
                            alt="example"
                          />
                        </div>
                      ) : (
                        false
                      )}
                    </label>
                  ))}
                  {photo.length < 3 && photo[photo.length - 1] && (
                    <Button
                      color="primary"
                      onClick={handleAddImage}
                      variant="contained"
                      component="span"
                    >
                      Add Another image
                    </Button>
                  )}
                  <span
                    style={{
                      color: "red",
                      marginTop: "10px",
                    }}
                  >
                    {err ? err : false}
                  </span>
                </Box>
              </Grid>
            </Grid>
            <Grid xs={12} sm={12} md={12}>
              <Box
                sx={{
                  mb: 8,
                  mx: 6,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {loading ? (
                  <Button
                    disabled
                    fullWidth
                    style={{ backgroundColor: "#ED6C10", color: "white" }}
                  >
                    loading
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    fullWidth
                    style={{ backgroundColor: "#ED6C10", color: "white" }}
                  >
                    Submit
                  </Button>
                )}

                <Grid />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
}

export default Addresource;
