import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import FileSaver from "file-saver";
import Container from "@mui/material/Container";
import { BsPencil, BsPlusLg, BsShareFill } from "react-icons/bs";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CardActions from "@mui/material/CardActions";
import { AiFillPrinter } from "react-icons/ai"
import { MdFileDownload } from "react-icons/md";
const style = {
  bgcolor: "#D3D3D3",
  p: 5,
};
function Createdigirecords2() {
  const theme = useTheme();
  const [search, setSearch] = useState("");
  const [error, setError] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [data, setData] = useState({
    HistoryofOwner: "",
    OwnerName: "",
    TentativeDiagnosis: "",
    ConfirmativeDiagnosis: "",
    TreatementGiven: "",
    Remark: "",
  });
  const [showResults, setShowResults] = React.useState(false);
  const onClick = () => setShowResults(true);
  const handlesearchsubmit = (e) => {
    e.preventDefault();
  };
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("data", data);
    setOpen(true);
  };
  return (
    <div>
      <section
        className="section banner"
        style={{ backgroundImage: "url('../Assests/upload/vet_bg.jpg')" }}
        data-img-width="1688"
        data-img-height="470"
        data-diff="100"
      ></section>
      <Container>
        <Box>
          <Container>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box
                      sx={{
                        mt: 8,
                        mx: 4,
                        mb: 5,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Card sx={{ display: "flex", width: 1050 }}>
                        <CardMedia
                          component="img"
                          sx={{ width: 400 }}
                          image="../../Assests/images/bg.png"
                          alt="Animal image"
                        />
                        <CardContent
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            p: 8,
                          
                          }}
                        >
                          <Typography component="div" variant="h4">
                            Mufasa
                          </Typography>
                          <br />
                          <Typography
                            variant="subtitle1"
                            color="text.secondary"
                            component="div"
                          >
                            2years 3 months
                          </Typography>
                          <br />
                          <Typography
                            variant="subtitle1"
                            color="text.secondary"
                            component="div"
                          >
                            Jerman Shepherd
                          </Typography>
                          <br />
                          <Typography
                            variant="subtitle1"
                            color="text.secondary"
                            component="div"
                          >
                            Male, Brown
                          </Typography>
                        </CardContent>

                        <CardActions
                          sx={{
                            p: 8,
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Button
                            size="small"
                            style={{
                              color: "#ED6C10",
                              backgroundColor: "white",
                              border: "1px solid #EEF5F4",
                              boxShadow: "5px 10px 18px  #E4E9E8 ",
                              fontWeight: 600,
                              marginBottom: "20px",
                            }}
                          >
                            <BsPencil
                              style={{ marginRight: "5px", fontWeight: 500 }}
                            />
                            Edit Detial
                          </Button>
                       <Link to="/DigiRecords/Createdigirecords1"  > <Button
                            size="small"
                            style={{
                              backgroundColor: "#ED6C10",
                              color: "white",
                              border: "1px solid #EEF5F4",
                              boxShadow: "5px 10px 18px  #E4E9E8 ",
                              fontWeight: 600,
                            }}
                          >
                            <BsPlusLg
                              style={{ marginRight: "5px", fontWeight: 500 }}
                            />
                            NewRecord
                          </Button></Link>
                        </CardActions>
                      </Card>
                    </Box>
                    <Box>
                      <Container style={{ display: 'flex', }}>
                        <Card sx={{ maxWidth: 340, m: 2 }}>
                          <Typography color="text.secondary" style={{ padding: "30px 20px 0px 20px" }}>
                            <p style={{ fontSize: "15px", padding: '0px' }}>24.09.22
                              Dewarming,vaccination,treatement
                              By Dr.Vignesh M.V.Sc.,</p>
                          </Typography>
                          <Grid container spacing={2} style={{ padding: "20px" }}>
                            <Grid item xs={12} sm={12} md={9}>
                              <Grid item xs={12} sm={12} md={12}>
                                <CardContent>

                                </CardContent>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                              <Grid item xs={12} sm={12} md={12}>
                                <CardActions style={{ padding: '0px', display: "flex", flexDirection: "column" }}>
                                  <Button><AiFillPrinter style={{ fontSize: "25px", color: "gray" }} /></Button>
                                  <Button><MdFileDownload style={{ fontSize: "25px", color: "gray" }} /></Button>
                                  <Button><BsShareFill style={{ fontSize: "18px", color: "gray" }} /></Button>
                                </CardActions>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Card>
                        <Card sx={{ maxWidth: 340, m: 2 }}>
                          <Typography color="text.secondary" style={{ padding: "30px 20px 0px 20px" }}>
                            <p style={{ fontSize: "15px", padding: '0px' }}>24.09.22
                              Dewarming,vaccination,treatement
                              By Dr.Vignesh M.V.Sc.,</p>
                          </Typography>
                          <Grid container spacing={2} style={{ padding: "20px" }}>
                            <Grid item xs={12} sm={12} md={9}>
                              <Grid item xs={12} sm={12} md={12}>
                                <CardContent>

                                </CardContent>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                              <Grid item xs={12} sm={12} md={12}>
                                <CardActions style={{ padding: '0px', display: "flex", flexDirection: "column" }}>
                                  <Button><AiFillPrinter style={{ fontSize: "25px", color: "gray" }} /></Button>
                                  <Button><MdFileDownload style={{ fontSize: "25px", color: "gray" }} /></Button>
                                  <Button><BsShareFill style={{ fontSize: "18px", color: "gray" }} /></Button>
                                </CardActions>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Card>
                        <Card sx={{ maxWidth: 340, m: 2 }}>
                          <Typography color="text.secondary" style={{ padding: "30px 20px 0px 20px" }}>
                            <p style={{ fontSize: "15px", padding: '0px' }}>24.09.22
                              Dewarming,vaccination,treatement
                              By Dr.Vignesh M.V.Sc.,</p>
                          </Typography>
                          <Grid container spacing={2} style={{ padding: "20px" }}>
                            <Grid item xs={12} sm={12} md={9}>
                              <Grid item xs={12} sm={12} md={12}>
                                <CardContent>

                                </CardContent>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                              <Grid item xs={12} sm={12} md={12}>
                                <CardActions style={{ padding: '0px', display: "flex", flexDirection: "column" }}>
                                  <Button><AiFillPrinter style={{ fontSize: "25px", color: "gray" }} /></Button>
                                  <Button><MdFileDownload style={{ fontSize: "25px", color: "gray" }} /></Button>
                                  <Button><BsShareFill style={{ fontSize: "18px", color: "gray" }} /></Button>
                                </CardActions>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Card>
                      </Container>
                    </Box>

                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <span
              style={{
                color: "red",
                marginTop: "10px",
              }}
            >
              {/* {error ? (
                          <Stack direction="row" spacing={1}>
                            <Chip label={error} color="#ED6C10" />
                          </Stack>
                        ) : (
                          false
                        )} */}
            </span>


          </Container>
        </Box>
      </Container>
    </div>
  );
}

export default Createdigirecords2;
