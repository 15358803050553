import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import { Link, useNavigate } from "react-router-dom";
import { animalslist } from "../../Redux/Actions/actions";
import { adddigianimals } from "../../Redux/Actions/actions";
import { AiFillCamera } from "react-icons/ai";
function EditDigiAnimals() {
  const dispatch = useDispatch();
  const [breed, setBreed] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [species, setSpecies] = useState("");
  const [photo, setPhoto] = useState([""]);
  const [data, setData] = useState({
    Animalname: "",
    age: "",
    gender: "",
    color: "",
    photo: [],
  });

  useEffect(() => {
    dispatch(animalslist());
  }, []);
  const handleAddImage = () => {
    if (photo[photo.length - 1]) {
      photo.push("");
      const imageUrls = [...photo];
      setPhoto(imageUrls);
    }
  };
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const handleselectChange = (e) => {
    setBreed(e.target.value.breed);
    setSpecies(e.target.value.pet);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (data.photo.length > 0) {
      const formData = new FormData();
      Object.entries(data).map(([key, value]) => {
        if (key == "photo") {
          value.map((item, index) => {
            formData.append(`photo`, item);
          });
        } else {
          formData.append(key, value);
        }
      });
      setLoading(true);
      dispatch(adddigianimals(formData))
        .then((res) => {
          setLoading(false);
          if (res.data.data === "Resource added successfully") {
            navigate("/");
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err.response);
        });
    } else {
      setError("Please upload photo");
    }
  };

  const resourceimage3 = (e, index) => {
    if (e.target.files && e.target.files.length > 0) {
      data[e.target.name][index] = e.target.files[0];
      const updatedPhoto = [...data[e.target.name]];
      setData({
        ...data,
        [e.target.name]: updatedPhoto,
      });
      photo[index] = URL.createObjectURL(e.target.files[0]);
      const imageUrls = [...photo];
      setPhoto(imageUrls);
    }
  };

  const animallist = useSelector(({ animalslist }) => animalslist.payload);
  const err = useSelector(({ userlogin }) => userlogin.payload);
  return (
    <div>
      <section
        className="section banner"
        style={{ backgroundImage: "url('../Assests/upload/vet_bg.jpg')" }}
        data-img-width="1688"
        data-img-height="470"
        data-diff="100"
      ></section>
      <div className="page-title grey">
        <div className="container">
          <div className="title-area pull-left">
            <h2>Add Animals</h2>
            <div className="bread">
              <ol className="breadcrumb">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="active">Add Animals</li>
              </ol>
            </div>
            {/* <!-- end bread --> */}
          </div>
          {/* <!-- /.pull-right --> */}
          <div className="search pull-right">
            <form>{/* <!-- /input-group --> */}</form>
          </div>
          {/* <!-- /.pull-right --> */}
        </div>
      </div>

      <Container>
        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <Grid item xs={12} sm={12} md={12}>
                <Box
                  sx={{
                    mt: 8,
                    mx: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    type="text"
                    onChange={handleChange}
                    id="Animalname"
                    label="AnimalName"
                    name="AnimalName"
                  />

                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    type="number"
                    onChange={handleChange}
                    id="age"
                    label="Age"
                    name="age"
                  />
                  <br />
                  <FormControl fullWidth>
                    <InputLabel id="daemo-simple-select-label">
                      Gender
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="gender"
                      label="Gender"
                      onChange={handleChange}
                    >
                      <MenuItem selected>Select Gender</MenuItem>
                      <MenuItem
                        value="Male"
                        style={{ textTransform: "capitalize" }}
                      >
                        Male
                      </MenuItem>
                      <MenuItem value="Female">Female</MenuItem>
                      <MenuItem value="others">Others</MenuItem>
                    </Select>
                  </FormControl>
                  <br />
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Species
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Species"
                      onChange={handleselectChange}
                    >
                      <MenuItem>Select Species</MenuItem>
                      {Array.isArray(animallist) && animallist.length > 0
                        ? animallist.map((list) => (
                            <MenuItem
                              style={{ textTransform: "capitalize" }}
                              value={list}
                            >
                              {list?.pet}
                            </MenuItem>
                          ))
                        : false}
                    </Select>
                  </FormControl>
                  <br />
                  <FormControl fullWidth style={{ marginTop: "10px" }}>
                    <InputLabel id="demo-simple-select-label">Breed</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="breed"
                      name="breed"
                      onChange={handleChange}
                    >
                      <MenuItem selected>Select Breed</MenuItem>
                      {breed?.length > 0
                        ? breed?.map((list) => (
                            <MenuItem
                              style={{ textTransform: "capitalize" }}
                              value={list?.breed}
                            >
                              {list?.breed}
                            </MenuItem>
                          ))
                        : false}
                    </Select>
                  </FormControl>
                  <br />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    type="text"
                    onChange={handleChange}
                    id="color"
                    label="Color"
                    name="color"
                  />
                  <br />
                  <span
                    style={{
                      color: "red",
                      marginTop: "10px",
                    }}
                  >
                    {error ? (
                      <Stack direction="row" spacing={1}>
                        <Chip label={error} color="#ED6C10" />
                      </Stack>
                    ) : (
                      false
                    )}
                  </span>
                </Box>
              </Grid>
              <Grid xs={12} sm={12} md={12}>
                <Box
                  sx={{
                    mb: 8,
                    mx: 6,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Button
                    type="submit"
                    fullWidth
                    style={{ backgroundColor: "#ED6C10", color: "white" }}
                  >
                    Save Changes
                  </Button>
                  <Grid />
                </Box>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <Grid item xs={12} sm={12} md={12}>
                <Box
                  sx={{
                    mt: 20,
                    mx: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {photo.map((dataimage, index) => (
                    <label htmlFor={"upload-resource-image" + index}>
                      <input
                        id={"upload-resource-image" + index}
                        name="photo"
                        onChange={(e) => resourceimage3(e, index)}
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                      />

                      <Button
                        component="span"
                        style={{
                          border: "1px solid black",
                          borderRadius: "60%",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          width: "60px",
                          height: "65px",
                          backgroundColor: "#C0C0C0",
                        }}
                      >
                        <AiFillCamera
                          style={{
                            fontSize: "51px",
                            color: "black",
                          }}
                        />
                        {dataimage ? "" : ""}
                      </Button>

                      {photo[index] ? (
                        <div
                          style={{
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={photo[index]}
                            style={{
                              width: "146px",
                              height: "129px",
                              marginTop: "20px",
                              color: "red",
                            }}
                            alt="example"
                          />
                        </div>
                      ) : (
                        false
                      )}
                    </label>
                  ))}

                  <span
                    style={{
                      color: "red",
                      marginTop: "10px",
                    }}
                  >
                    {err ? err : false}
                  </span>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
}

export default EditDigiAnimals;
