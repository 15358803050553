import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LinesEllipsis from "react-lines-ellipsis";
import ReadMoreReact from "read-more-react";
import blog from "../Assests/upload/blog_01.jpg";
import blog1 from "../Assests/upload/blog_02.jpg";
import { searchnewsfeedlist, RecentNewslist } from "../Redux/Actions/actions";

const News = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("erode");
  const handleSubmit = (e) => {
    e.preventDefault();
    e.currentTarget.reset();
    dispatch(searchnewsfeedlist({ district: search }));
  };
  const newssearch = useSelector(
    ({ searchnewsfeedlist }) => searchnewsfeedlist.payload
  );
  const recentnews = useSelector(
    ({ RecentNewslist }) => RecentNewslist.payload
  );
  useEffect(() => {
    dispatch(searchnewsfeedlist({ district: search }));
    dispatch(RecentNewslist());
  }, []);

  return (
    <div>
      <section
        className="section banner"
        style={{ backgroundImage: "url(../Assests/upload/blog_bg.jpg)" }}
        data-img-width="1688"
        data-img-height="470"
        data-diff="100"
      ></section>
      <div className="page-title grey">
        <div className="container">
          <div className="title-area pull-left">
            <h2>
              News Feeds
              <small>We shared best articles about vets and pets</small>
            </h2>
            <div className="bread">
              <ol className="breadcrumb">
                <li>
                  <a href="/">Home</a>
                </li>
                <li className="active">News Feeds</li>
              </ol>
            </div>
          </div>
          <div className="search pull-right">
            <form onSubmit={handleSubmit}>
              <div className="input-group">
                <input
                  className="form-control"
                  name="search"
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  type="search"
                  placeholder=" Search... "
                />
                <span className="input-group-btn">
                  <button type="submit" className="btn btn-primary">
                    <i className="fa fa-search"></i>
                  </button>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>

      {
        //Tailwind CSS
      }

      <section className="section white">
        <div className="container">
          <div className="row">
            <div id="content" className="col-md-8 col-sm-12 col-xs-12">
              {Array.isArray(newssearch) && newssearch.length > 0 ? (
                newssearch.map((newslist, i) => (
                  <div className="blog-wrapper mt-5 ">
                    <div className="blog-image">
                      <Link to={`/Singlenews/${newslist?.id}`}>
                        <img
                          src={newslist?.photo}
                          alt="Newsfeed Images"
                          className="img-responsive"
                        />
                      </Link>
                    </div>
                    <div className="blog-title">
                      {/* <!-- <a className="category_title"  title="">PET SUPPLIES</a> --> */}
                      <h2>
                        <a href="/news" style={{ textTransform: "capitalize" }}>
                          {newslist?.title}
                        </a>
                      </h2>
                      <div className="post-meta">
                        <span>
                          <i className="fa fa-clock-o"></i>
                          <a className="ml-1">{newslist?.updatedAt}</a>
                        </span>
                      </div>
                      <div className="mt-3">
                        <LinesEllipsis
                          text={newslist?.description}
                          maxLine="3"
                          ellipsis="..."
                          trimRight
                          basedOn="letters"
                        />
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <h1>No News feed Found</h1>
              )}

              <hr className="invis" />
            </div>

            <div id="sidebar" className="col-md-4 col-sm-12 col-xs-12">
              <div className="widget">
                <div className="widget-title">
                  <h4>Recent posts</h4>
                  <hr />
                </div>

                <div className="recent-post-widget">
                  <ul className="recent-posts">
                    {Array.isArray(recentnews) && recentnews.length > 0 ? (
                      recentnews.map((data) => (
                        <li>
                          <p>
                            <Link to={`/Singlenews/${data?.id}`}>
                              <img
                                src={data?.photo}
                                alt=""
                                className="alignleft"
                              />
                            </Link>
                            <div
                              style={{
                                display: "flex",
                                marginTop: "0px",
                                textTransform: "capitalize",
                                flexDirection: "column",
                                flexWrap: "wrap",
                                justifyContent: "space-around",
                              }}
                            >
                              <div>
                                <Link to={`/Singlenews/${data?.id}`}>
                                  {data?.title}
                                </Link>
                              </div>
                              <div>
                                <span
                                  style={{
                                    whiteSpace: "pre",
                                    fontWeight: "lighter",
                                  }}
                                >
                                  {data?.updatedAt}
                                </span>
                              </div>
                            </div>
                          </p>
                        </li>
                      ))
                    ) : (
                      <div>No News Found</div>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default News;
