import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  Card,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Box,
  TextField,
} from "@mui/material";
import { Button } from "react-bootstrap";
import { Feedformulationlist } from "../../../Redux/Actions/actions";
let TotalCP = [];
let TotalME = [];
let TotalPRICE = [];
function PoultryForm({ setPrice, setCP, setMe }) {
  const [disable, setDisable] = useState(false);
  const [percentage, setPercentage] = useState("");
  const [feeed, setFeeed] = useState("");
  const [savedata, setSaveData] = useState("");
  const [selectedpoultry, setSelectedpoultry] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(Feedformulationlist());
  }, []);
  const feed = useSelector(
    ({ Feedformulationlist }) => Feedformulationlist.payload
  );
  const handleselectchange = (e) => {
    setDisable(false);
    setFeeed(feed[e.target.value].ingredients);
    setSaveData(feed[e.target.value]);
    setSelectedpoultry(feed[e.target.value].ingredients);
    console.log(feed[e.target.value].ingredients);
  };
  let price = savedata?.price;
  let cp = savedata?.cp;
  let me = savedata?.me;
  let firstrange = savedata?.range?.slice(0, 2);
  let secondrange = savedata?.range?.slice(3, 5);
  let CP = (cp * percentage) / 100;
  let ME = (me * percentage) / 100;
  let RATE = ((percentage * 10) / 1000) * price;
  const handleSubmit = (e) => {
    e.preventDefault();
    setDisable(true);
    TotalCP.push(CP);
    TotalME.push(ME);
    TotalPRICE.push(Math.round(RATE));
    setMe(TotalME);
    setCP(TotalCP);
    setPrice(TotalPRICE);
  };

  return (
    <>
      <Box component="form" onSubmit={handleSubmit} style={{ margin: "10px" }}>
        <div>
          <Card
            className="my-5 mx-5 p-5"
            style={{ padding: "10px", marginBottom: "10px" }}
          >
            <Typography variant="h6" component="h2">
              Select Ingredient
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="daemo-simple-select-label">
                Select Feed
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="feeed"
                onChange={(e) => handleselectchange(e)}
                label="Select Feed"
              >
                <MenuItem>Select Feed</MenuItem>
                {feed?.map((data, i) => (
                  <MenuItem value={i}>{data?.ingredients}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <Typography variant="h6" component="h2">
              Input ( % )
            </Typography>
            {feed?.filter((data) => data?.ingredients === selectedpoultry)
              .length > 0 ? (
              feed
                ?.filter((data) => data?.ingredients === selectedpoultry)
                ?.map((data) => (
                  <>
                    <TextField
                      name="percentage"
                      label="Percentage %"
                      type="number"
                      required
                      onChange={(e) => setPercentage(e.target.value)}
                      style={{ width: "100%" }}
                      InputProps={{
                        inputProps: {
                          min: data?.range?.slice(0, 2),
                          max: data?.range?.slice(3, 5),
                        },
                      }}
                    />
                  </>
                ))
            ) : (
              <TextField
                name="percentage"
                label="Percentage %"
                type="number"
                disabled
                style={{ width: "100%" }}
                InputProps={{
                  inputProps: {
                    min: 0,
                    max: 0,
                  },
                }}
              />
            )}

            <hr />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                }}
              >
                <label>ME</label>
                <label>CP</label>
              </div>
              <div
                style={{
                  display: "flex",
                  padding: "10px 0px",
                  justifyContent: "space-evenly",
                }}
              >
                {feed?.filter((data) => data?.ingredients === selectedpoultry)
                  .length > 0 ? (
                  feed
                    ?.filter((data) => data?.ingredients === selectedpoultry)
                    ?.map((data) => (
                      <>
                        <Button style={{ fontSize: "15px" }}>{data?.cp}</Button>
                        <Button style={{ fontSize: "15px" }}>{data?.me}</Button>
                      </>
                    ))
                ) : (
                  <>
                    <Button style={{ fontSize: "15px" }}>0</Button>
                    <Button style={{ fontSize: "15px" }}>0</Button>
                  </>
                )}
              </div>
            </div>
          </Card>
        </div>
        {disable ? (
          <Button
            disabled={true}
            type="submit"
            style={{
              width: "95%",
              marginLeft: "10px",
              marginTop: "10px",
              background: "#ED6C10",
              color: "white",
            }}
          >
            Calculate
          </Button>
        ) : (
          <Button
            disabled={false}
            type="submit"
            style={{
              width: "90%",
              marginLeft: "15px",
              background: "#ED6C10",
              color: "white",
            }}
          >
            Calculate
          </Button>
        )}
      </Box>
    </>
  );
}

export default PoultryForm;
