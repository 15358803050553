import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LinesEllipsis from "react-lines-ellipsis";
import "react-responsive-carousel/lib/styles/carousel.min.css";
// requires a loader
import { Carousel } from "react-responsive-carousel";
import banner1 from "../Assests/upload/banner 1.jpg";
import banner2 from "../Assests/upload/banner 2.jpg";
import banner3 from "../Assests/upload/banner 3.jpg";
import buysell from "../Assests/Vetsline All icons/Buy or Sell.svg";
import Dose from "../Assests/Vetsline All icons/Dose Calculator.svg";
import Farm from "../Assests/Vetsline All icons/Farm Equipments.svg";
import Feed from "../Assests/Vetsline All icons/Feed Formulation Guide.svg";
import Lab from "../Assests/Vetsline All icons/Labs.svg";
import Livestock from "../Assests/Vetsline All icons/Livestock Poultry.svg";
import Shops from "../Assests/Vetsline All icons/Shop.svg";
import Doctors from "../Assests/Vetsline All icons/Vetrinary Doctors.svg";
import match from "../Assests/Vetsline All icons/Match Find.svg";
import adopt from "../Assests/Vetsline All icons/Adoption.svg";
import Pet from "../Assests/Vetsline All icons/Pet Clinics.svg";
import Drug from "../Assests/Vetsline All icons/Veterinary Drug Index.svg";
import { searchnewsfeedlist } from "../Redux/Actions/actions";
import "../style.css";
import Loader from "../Loader/Loader";
const Home = () => {
  const [load, setLoad] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(searchnewsfeedlist({ district: "erode" })).then((res) => {
      setLoad(false);
    });
  }, []);
  const newsfeedseacrh = useSelector(
    ({ searchnewsfeedlist }) => searchnewsfeedlist.payload
  );
  return (
    <div>
      <Carousel emulateTouch={true}>
        <div>
          <img src={banner1} />
        </div>
        <div>
          <img src={banner2} />
        </div>
        <div>
          <img src={banner3} />
        </div>
      </Carousel>
      {load ? (
        <>
          <Loader />
        </>
      ) : (
        <section className="section grey">
          <div className="container">
            <div className="general-title text-center">
              <h4>News Feeds</h4>
              <hr />
              <p className="lead">We shared best articles for pet lowers</p>
            </div>

            {Array.isArray(newsfeedseacrh) && newsfeedseacrh.length > 0 ? (
              newsfeedseacrh.slice(0, 3).map((data) => (
                <>
                  <div className="col-md-4 col-sm-6 mb-5">
                    <div className="blog-wrapper" style={{ height: "500px" }}>
                      <div className="blog-image">
                        <Link to={`/Singlenews/${data?.id}`}>
                          <img
                            src={data?.photo}
                            alt="news image"
                            className="img-responsive"
                          />
                        </Link>
                      </div>

                      <div className="blog-title">
                        {/* <!-- <a className="category_title" href="#" title="">PET MARKETING</a> --> */}
                        <h2>
                          <Link
                            to={`/Singlenews/${data?.id}`}
                            style={{ textTransform: "capitalize" }}
                          >
                            {data?.title}
                          </Link>
                        </h2>
                        <p style={{ textTransform: "capitalize" }}>
                          <LinesEllipsis
                            text={data?.description}
                            maxLine="2"
                            ellipsis="..."
                            trimRight
                            basedOn="letters"
                          />
                        </p>
                      </div>
                      {/* <!-- end desc --> */}
                    </div>
                    {/* <!-- end blog-wrapper --> */}
                  </div>
                </>
              ))
            ) : (
              <h1 style={{ display: "flex", justifyContent: "center" }}>
                No News Feed Found
              </h1>
            )}
            {/* <!-- end col --> */}
          </div>
          {/* <!-- end row --> */}
          {/* </div> */}
          <div className="text-center ">
            <Link to="/news">
              <button
                style={{
                  backgroundColor: "#FEA81C ",
                  marginTop: "50px",
                  height: "50px",
                  width: "350px",
                  fontSize: "larger",
                }}
                type="button"
                className="btn btn-warning active  d-grid gap-2 col-6 mx-auto "
              >
                View More
              </button>
            </Link>
          </div>
        </section>
      )}

      <section className="section white">
        <div className="container">
          <div className="general-title text-center">
            <h4>Our Resources</h4>
            <hr />
            <p className="lead">Listed below six to choose us!</p>
          </div>
          {/* <!-- end general title --> */}

          <div className="row module-wrapper text-center test">
            <div className="col-md-4 col-sm-3 why-us">
              <img src={Doctors} alt="" />
              <strong>
                <Link to="/doctor">Veterinary Doctors</Link>
              </strong>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Curabitur euismod enim a metus adipiscing aliquam.
              </p>
            </div>
            {/* <!-- end col --> */}

            <div className="col-md-4 col-sm-3 why-us">
              <img src={Pet} alt="" />
              <strong>
                <Link to="/Petclinic">Pet Clinic</Link>
              </strong>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Curabitur euismod enim a metus adipiscing aliquam.
              </p>
            </div>
            {/* <!-- end col --> */}

            <div className="col-md-4 col-sm-3 why-us">
              <img src={Lab} alt="" />
              <strong>
                <Link to="/Lapslist">Lab</Link>
              </strong>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Curabitur euismod enim a metus adipiscing aliquam.
              </p>
            </div>
            {/* <!-- end col --> */}
          </div>
          <div className="row module-wrapper text-center mt-5 test">
            <div className="col-md-4 col-sm-3 why-us mt-5">
              <img src={Shops} alt="" />
              <strong>
                <Link to="/FeedShop">Feed Shop</Link>
              </strong>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Curabitur euismod enim a metus adipiscing aliquam.
              </p>
            </div>
            {/* <!-- end col --> */}

            <div className="col-md-4 col-sm-3 why-us mt-5">
              <img src={Farm} alt="" />
              <strong>
                <Link to="/FarmEquipments ">Farm Equipments</Link>
              </strong>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Curabitur euismod enim a metus adipiscing aliquam.
              </p>
            </div>
            {/* <!-- end col --> */}

            <div className="col-md-4 col-sm-3 why-us mt-5">
              <img src={Livestock} alt="" />
              <strong>
                <Link to="/PoultryEquipments">
                  Live Stocks / Poultry Equipments
                </Link>
              </strong>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Curabitur euismod enim a metus adipiscing aliquam.
              </p>
            </div>
            {/* <!-- end col --> */}
          </div>
          {/* <!-- end module-wrapper --> */}
        </div>
        {/* <!-- end container --> */}
      </section>

      <section className="section grey">
        <div className="container">
          <div className="general-title text-center">
            <h4>Animals</h4>
            <hr />
            <p className="lead">Listed below Three to choose us!</p>
          </div>
          {/* <!-- end general title --> */}

          <div className="row module-wrapper text-center mt-5 test">
            <div className="col-md-4 col-sm-3 why-us mt-5">
              <img src={buysell} alt="" />
              <strong>
                <Link to="/animals/buysell">BUY SELL</Link>
              </strong>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Curabitur euismod enim a metus adipiscing aliquam.
              </p>
            </div>
            {/* <!-- end col --> */}

            <div className="col-md-4 col-sm-3 why-us mt-5">
              <img src={adopt} alt="" />
              <strong>
                <Link to="/animals/adoption">ADOPTION</Link>
              </strong>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Curabitur euismod enim a metus adipiscing aliquam.
              </p>
            </div>
            {/* <!-- end col --> */}

            <div className="col-md-4 col-sm-3 why-us mt-5">
              <img src={match} alt="" />
              <strong>
                <Link to="/animals/matchfind">MATCH FIND</Link>
              </strong>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Curabitur euismod enim a metus adipiscing aliquam.
              </p>
            </div>
            {/* <!-- end col --> */}
          </div>
          {/* <!-- end module-wrapper --> */}
        </div>
        {/* <!-- end container --> */}
      </section>
      <section className="section white">
        <div className="container">
          <div className="general-title text-center">
            <h4>Calculator</h4>
            <hr />
            <p className="lead">Listed below Three to choose us!</p>
          </div>
          {/* <!-- end general title --> */}

          <div className="row module-wrapper text-center mt-5 test">
            <div className="col-md-4 col-sm-3 why-us mt-5">
              <img src={Drug} alt="" />
              <strong>
                <Link to="/guide/AddDrugIndex">Veterinary Drug Index</Link>
              </strong>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Curabitur euismod enim a metus adipiscing aliquam.
              </p>
            </div>
            {/* <!-- end col --> */}

            <div className="col-md-4 col-sm-3 why-us mt-5">
              <img src={Dose} alt="" />
              <strong>
                <Link to="/guide/AddDoseCalculator">Dose Calculator</Link>
              </strong>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Curabitur euismod enim a metus adipiscing aliquam.
              </p>
            </div>
            {/* <!-- end col --> */}

            <div className="col-md-4 col-sm-3 why-us mt-5">
              <img src={Feed} alt="" />
              <strong>
                <Link to="/guide/AddFeedFormulation">
                  FEED Formulation Guide
                </Link>
              </strong>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Curabitur euismod enim a metus adipiscing aliquam.
              </p>
            </div>
            {/* <!-- end col --> */}
          </div>
          {/* <!-- end module-wrapper --> */}
        </div>
        {/* <!-- end container --> */}
      </section>
      {/* <!-- end section --> */}
    </div>
  );
};

export default Home;
