import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import { GoLocation } from "react-icons/go";
import { BiPhoneCall } from "react-icons/bi";
import { animalsadoptiondetails } from "../../Redux/Actions/actions";

export const Raating = () => {
  const [valuer, setValuer] = useState("3");
  return (
    <Stack spacing={1}>
      <h5> Your experience with his doctor</h5>
      <Rating
        name="half-rating-read"
        defaultValue={1}
        precision={0.5}
        onChange={(e) => setValuer(e.target.value)}
      />
      {valuer}
    </Stack>
  );
};
const rating = true;
function Adoptiondetails() {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    dispatch(animalsadoptiondetails({ id: id }));
  }, []);
  const animalbuydetails = useSelector(
    ({ animalsadoptiondetails }) => animalsadoptiondetails.payload
  );
  const handlereview = () => {
    setShow(true);
  };
  var tm = "tel:" + animalbuydetails?.mobile;
  return (
    <div>
      <section
        className="section banner"
        style={{ backgroundImage: "url('../Assests/upload/vet_bg.jpg')" }}
        data-img-width="1688"
        data-img-height="470"
        data-diff="100"
      ></section>
      <div class="page-title grey">
        <div class="container">
          <div class="title-area pull-left">
            <h2>Shop</h2>
            <div class="bread">
              <ol class="breadcrumb">
                <li>
                  <a href="#">Home</a>
                </li>
                <li class="active">Shop</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <section class="section white">
        <div class="container">
          <div class="row">
            <div id="content" class="col-md-12 col-sm-12 col-xs-12">
              <div class="row">
                <div class="col-md-5 col-sm-5 col-xs-12">
                  <div class="product-images">
                    <a data-rel="prettyPhoto">
                      <img
                        class="img-polaroid"
                        style={{ width: "70%" }}
                        src={animalbuydetails?.photo[0]}
                        alt="Doctor Image"
                      />
                    </a>
                  </div>
                </div>
                <div class="col-md-7 col-sm-7 col-xs-12">
                  <div class="shop-desc">
                    <h3
                      style={{
                        textTransform: "capitalize",
                      }}
                    >
                      {animalbuydetails?.name}
                    </h3>
                    <h4
                      style={{
                        textTransform: "capitalize",
                      }}
                    >
                      {animalbuydetails?.about}
                    </h4>
                    <h4>
                      {animalbuydetails?.species} / {animalbuydetails?.breed}
                    </h4>
                    <h4 style={{ textTransform: "capitalize" }}>
                      {animalbuydetails?.gender} / {animalbuydetails?.age}
                    </h4>
                    <div>
                      <div>
                        <p
                          style={{
                            textTransform: "capitalize",
                            display: "flex",
                          }}
                        >
                          <span style={{ marginRight: "5px" }}>
                            <BiPhoneCall />
                          </span>
                          <span style={{ fontvariantNumeric: "normal" }}>
                            <a href={tm}>{animalbuydetails?.mobile}</a>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="shopmeta">
                    <div style={{ display: "flex" }}>
                      <span style={{ fontWeight: "bold" }}>
                        <GoLocation />
                      </span>
                      <a
                        style={{
                          textTransform: "capitalize",
                          display: "flex",
                          alignItems: "end",
                          marginLeft: "10px",
                        }}
                      >
                        {animalbuydetails?.street}&nbsp;
                        {animalbuydetails?.taluk} {animalbuydetails?.city}&nbsp;
                        {animalbuydetails?.district} -
                        {animalbuydetails?.pincode}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Adoptiondetails;
