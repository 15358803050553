import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  BsFillCheckCircleFill,
  BsPlusLg,
  BsFileEarmarkText,
} from "react-icons/bs";
import Box from "@mui/material/Box";
import { Link, useNavigate } from "react-router-dom";

import Modal from "@mui/material/Modal";
import { animalslist } from "../../Redux/Actions/actions";
import { createdigirecords } from "../../Redux/Actions/actions";
const style = {
  position: "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #C0C0C0",
  boxShadow: 24,
  p: 5,
};

function Createdigirecords() {
  const dispatch = useDispatch();
  const [breed, setBreed] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [vaccination, setVaccination] = useState(false);
  const [treatment, setTreatement] = useState(false);
  const [species, setSpecies] = useState("");

  const [error, setError] = useState("");

  const [shown, setShown] = useState(false);
  const [open, setOpen] = useState();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // const handleShown = () => setShown(true);
  const handleHide = () => setShown(false);
  const [userdata, setUserdata] = useState({
    HistoryofOwner: "",
    OwnerName: "",
    TentativeDiagnosis: "",
    ConfirmativeDiagnosis: "",
    TreatementGiven: "",
    Remark: "",
    files_upload: [],
    date: "",
    AnimalName: "",

    age: "",
    gender: "",
    color: "",
    mobile: "",
    SelectDrugType: "",
  });

  const [file, setFile] = useState("");
  const onChange = (e) => {
    setFile(e.target.files[0]);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", file);
  };
  useEffect(() => {
    dispatch(animalslist());
  }, []);

  const handlechange = (event) => {
    setChecked(event.currentTarget.checked);
  };
  const handlechangevc = (event) => {
    setVaccination(event.currentTarget.checked);
  };
  const handlechangett = (event) => {
    setTreatement(event.currentTarget.checked);
  };
  const handleChange = (e) => {
    setUserdata({
      ...userdata,
      [e.target.name]: e.target.value,
    });
  };
  const handleselectChange = (e) => {
    setBreed(e.target.value.breed);
    setSpecies(e.target.value.pet);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setShown(true);

    console.log("Userdata", userdata);
  };

  const handleOnChange = (e) => {
    console.log(e.target.files[0]);
    setFile(e.target.files[0]);
  };
  useEffect(() => {
    setShown(true);
    return () => {
      setShown(false);
    };
  }, []);
  const err = useSelector(({ userlogin }) => userlogin.payload);
  const animallist = useSelector(({ animalslist }) => animalslist.payload);
  return (
    <div>
      <section
        className="section banner"
        style={{ backgroundImage: "url('../Assests/upload/vet_bg.jpg')" }}
        data-img-width="1688"
        data-img-height="470"
        data-diff="100"
      ></section>
      {/* <Button onClick={handleShown}>ss</Button> */}
      <Modal
        open={shown}
        onClose={handleHide}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        value="opens"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            Create New Digi Record For
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          ></div>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <FormGroup>
              <FormControlLabel
                onChange={handlechange}
                control={<Checkbox style={{ paddingLeft: "50px" }} />}
                label="Dewarming"
                value="Dewarming"
              />
              <FormControlLabel
                onChange={handlechangevc}
                control={<Checkbox style={{ paddingLeft: "50px" }} />}
                label="Vaccination"
                value="vaccination"
              />
              <FormControlLabel
                onChange={handlechangett}
                control={<Checkbox style={{ paddingLeft: "50px" }} />}
                label="Treatment"
                value="treatment"
              />
              <FormControlLabel
                control={<Checkbox style={{ paddingLeft: "50px" }} />}
                label="Upload"
              />
            </FormGroup>
            <Button
              fullWidth
              type="submit"
              onClick={handleHide}
              style={{
                backgroundColor: "#ED6C10",
                color: "white",
              }}
            >
              Save and Next
            </Button>
          </Typography>
        </Box>
      </Modal>

      <Container>
        <Box component="form">
          <Container>
            <Box component="form" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box
                      sx={{
                        mt: 8,
                        mx: 4,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          textAlign: "center",

                          fontWeight: "bold",
                          fontSize: "20px",
                          marginBottom: "20px",
                        }}
                      >
                        Basic Info
                      </div>
                      <TextField
                        id="date"
                        name="date"
                        label="Date"
                        type="date"
                        defaultValue="2022-09-21"
                        sx={{ width: 300, mb: 2 }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />

                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        onChange={handleChange}
                        id="Owner Name"
                        label="Owner Name"
                        name="OwnerName"
                      />
                      <br />
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        onChange={handleChange}
                        id="Animal Name"
                        label="Animal Name"
                        name="AnimalName"
                      />
                      <br />
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Species
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Species"
                          onChange={handleselectChange}
                        >
                          <MenuItem>Select Species</MenuItem>
                          {Array.isArray(animallist) && animallist.length > 0
                            ? animallist.map((list) => (
                                <MenuItem
                                  style={{ textTransform: "capitalize" }}
                                  value={list}
                                >
                                  {list?.pet}
                                </MenuItem>
                              ))
                            : false}
                        </Select>
                      </FormControl>
                      <br />
                      <FormControl fullWidth style={{ marginTop: "10px" }}>
                        <InputLabel id="demo-simple-select-label">
                          Breed
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="breed"
                          name="breed"
                          onChange={handleChange}
                        >
                          <MenuItem selected>Select Breed</MenuItem>
                          {breed?.length > 0
                            ? breed?.map((list) => (
                                <MenuItem
                                  style={{ textTransform: "capitalize" }}
                                  value={list?.breed}
                                >
                                  {list?.breed}
                                </MenuItem>
                              ))
                            : false}
                        </Select>
                      </FormControl>
                      <br />

                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        type="number"
                        onChange={handleChange}
                        id="age"
                        label="Age"
                        name="age"
                      />
                      <br />
                      <FormControl fullWidth>
                        <InputLabel id="daemo-simple-select-label">
                          Gender
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="gender"
                          label="Gender"
                          onChange={handleChange}
                        >
                          <MenuItem selected>Select Gender</MenuItem>
                          <MenuItem
                            value="Male"
                            style={{ textTransform: "capitalize" }}
                          >
                            Male
                          </MenuItem>
                          <MenuItem value="Female">Female</MenuItem>
                          <MenuItem value="others">Others</MenuItem>
                        </Select>
                      </FormControl>
                      <br />
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        type="text"
                        onChange={handleChange}
                        id="color"
                        label="Color"
                        name="color"
                      />
                      <br />
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        onChange={handleChange}
                        id="mobile"
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 10);
                        }}
                        label="Mobile Number"
                        name="mobile"
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box
                      sx={{
                        mt: 8,
                        mx: 4,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <>
                        {checked && (
                          <>
                            <div
                              style={{
                                textAlign: "center",
                                fontWeight: "bold",
                                fontSize: "20px",
                                marginBottom: "20px",
                              }}
                            >
                              Dewarming
                            </div>
                            <FormControl fullWidth>
                              <InputLabel id="daemo-simple-select-label">
                                Select Drug Type
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="SelectDrugType"
                                label="Select Drug Type"
                                onChange={handleChange}
                              >
                                <MenuItem value="" selected>
                                  Select Drug Type
                                </MenuItem>
                                <MenuItem value="Inj, Syrup, Tonic">
                                  Inj, Syrup, Tonic
                                </MenuItem>
                                <MenuItem value="polus">Polus</MenuItem>
                                <MenuItem value="tab">Tab</MenuItem>
                              </Select>
                            </FormControl>
                            <br />
                            <TextField
                              id="date"
                              label="Date"
                              type="date"
                              defaultValue="2022-09-21"
                              sx={{ width: 300, mb: 2, mt: 2 }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                            <div
                              style={{
                                textAlign: "center",
                                fontWeight: "bold",
                                fontSize: "20px",
                                marginBottom: "20px",
                                color: "#e67e22",
                              }}
                            >
                              New Due
                            </div>
                            <FormControl fullWidth>
                              <InputLabel id="daemo-simple-select-label">
                                Select Drug Type
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="Select Drug Type"
                                label="Select Drug Type"
                                onChange={handleChange}
                              >
                                <MenuItem value="" selected>
                                  Select Drug Type
                                </MenuItem>
                                <MenuItem value="Inj, Syrup, Tonic">
                                  Inj, Syrup, Tonic
                                </MenuItem>
                                <MenuItem value="polus">Polus</MenuItem>
                                <MenuItem value="tab">Tab</MenuItem>
                              </Select>
                            </FormControl>
                            <br />
                            <TextField
                              id="date"
                              label="Date"
                              type="date"
                              defaultValue="2022-09-21"
                              sx={{ width: 300, mb: 2, mt: 2 }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />{" "}
                          </>
                        )}
                      </>
                      <>
                        {vaccination && (
                          <>
                            <div
                              style={{
                                textAlign: "center",
                                fontWeight: "bold",
                                fontSize: "20px",
                                marginBottom: "20px",
                              }}
                            >
                              Vaccination
                            </div>
                            <FormControl fullWidth>
                              <InputLabel id="daemo-simple-select-label">
                                Select Vaccine Type
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="Select Drug Type"
                                label="Select Drug Type"
                                onChange={handleChange}
                              >
                                <MenuItem value="" selected>
                                  Select Vaccine Type
                                </MenuItem>
                                <MenuItem value="Inj, Syrup, Tonic">
                                  Inj, Syrup, Tonic
                                </MenuItem>
                                <MenuItem value="polus">Polus</MenuItem>
                                <MenuItem value="tab">Tab</MenuItem>
                              </Select>
                            </FormControl>
                            <br />
                            <TextField
                              id="date"
                              label="Date"
                              type="date"
                              defaultValue="2022-09-21"
                              sx={{ width: 300, mb: 2, mt: 2 }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                            <div
                              style={{
                                textAlign: "center",
                                fontWeight: "bold",
                                fontSize: "20px",
                                marginBottom: "20px",
                                color: "#e67e22",
                              }}
                            >
                              New Due
                            </div>
                            <FormControl fullWidth>
                              <InputLabel id="daemo-simple-select-label">
                                Select Vaccine Type
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="Select Drug Type"
                                label="Select Drug Type"
                                onChange={handleChange}
                              >
                                <MenuItem value="" selected>
                                  Select Vaccine Type
                                </MenuItem>
                                <MenuItem value="Inj, Syrup, Tonic">
                                  Inj, Syrup, Tonic
                                </MenuItem>
                                <MenuItem value="polus">Polus</MenuItem>
                                <MenuItem value="tab">Tab</MenuItem>
                              </Select>
                            </FormControl>
                            <br />
                            <TextField
                              id="date"
                              label="Date"
                              type="date"
                              defaultValue="2022-09-21"
                              sx={{ width: 300, mb: 2, mt: 2 }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />{" "}
                          </>
                        )}
                      </>
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box
                      sx={{
                        mt: 8,
                        mx: 2,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <>
                        {treatment && (
                          <>
                            {" "}
                            <div
                              style={{
                                fontWeight: "bold",
                                fontSize: "20px",
                                marginBottom: "5px",
                              }}
                            >
                              Treatement
                            </div>
                            <TextField
                              margin="normal"
                              fullWidth
                              onChange={handleChange}
                              id="History of Owner"
                              label="History of Owner"
                              name="HistoryofOwner"
                            />
                            <br />
                            <TextField
                              margin="normal"
                              fullWidth
                              onChange={handleChange}
                              id="Owner Name"
                              label="Owner Name"
                              name="OwnerName"
                            />
                            <br />
                            <TextField
                              margin="normal"
                              fullWidth
                              onChange={handleChange}
                              id="Tentative Diagnosis"
                              label="Tentative Diagnosis"
                              name="TentativeDiagnosis"
                            />
                            <br />
                            <TextField
                              margin="normal"
                              fullWidth
                              onChange={handleChange}
                              id="Confirmative Diagnosis"
                              label="Confirmative Diagnosis"
                              name="ConfirmativeDiagnosis"
                            />
                            <br />
                            <TextField
                              margin="normal"
                              fullWidth
                              onChange={handleChange}
                              id="Treatement Given"
                              label="Treatement Given"
                              name="TreatementGiven"
                            />
                            <br />
                            <TextField
                              fullWidth
                              id="outlined-multiline-static"
                              label="Remark/Note"
                              multiline
                              rows={4}
                              name="Remark"
                              onChange={handleChange}
                            />
                            <br />
                            <Button
                              type="submit"
                              fullWidth
                              onClick={handleSubmit}
                              style={{
                                backgroundColor: "#ED6C10",
                                color: "white",
                              }}
                            >
                              <BsPlusLg style={{ marginRight: "10px" }} />{" "}
                              Create Digi Record
                            </Button>
                            <Modal
                              open={open}
                              onClose={handleClose}
                              aria-labelledby="modal-modal-title"
                              aria-describedby="modal-modal-description"
                            >
                              <Box sx={style}>
                                <Typography
                                  id="modal-modal-title"
                                  variant="h6"
                                  component="h2"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  Success
                                </Typography>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <BsFillCheckCircleFill
                                    style={{
                                      color: "#ED6C10",
                                      fontSize: "130px",
                                    }}
                                  />
                                </div>
                                <Typography
                                  id="modal-modal-description"
                                  sx={{ mt: 2 }}
                                >
                                  Digi Record created to #953216-13525
                                  <p>animal name - Jimmy.</p>
                                  <p>Click Continue to Upload Records</p>
                                  <Button
                                    fullWidth
                                    type="submit"
                                    style={{
                                      backgroundColor: "#ED6C10",
                                      color: "white",
                                    }}
                                  >
                                    Continue
                                  </Button>
                                </Typography>
                              </Box>
                            </Modal>{" "}
                          </>
                        )}
                      </>

                      <span
                        style={{
                          color: "red",
                          marginTop: "10px",
                        }}
                      >
                        {/* {error ? (
                          <Stack direction="row" spacing={1}>
                            <Chip label={error} color="#ED6C10" />
                          </Stack>
                        ) : (
                          false
                        )} */}
                      </span>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Grid container spacing={2} onSubmit={onSubmit}>
              <Grid item xs={12} sm={12} md={4}>
                <Grid item xs={12} sm={12} md={12}>
                  <Box
                    sx={{
                      mt: 8,
                      mx: 2,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: "20px",
                        marginBottom: "25px",
                      }}
                    >
                      Upload Records Maximum
                    </div>
                    <Card>
                      <Box
                        sx={{
                          m: 5,
                          mx: 4,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <input type="file" onChange={handleOnChange} />

                        {/* <span
                          style={{
                            color: "red",
                            marginTop: "10px",
                          }}
                        >
                          {err ? err : false}
                        </span> */}
                      </Box>{" "}
                    </Card>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Container>
          <Grid xs={12} sm={12} md={12}>
            <Box
              sx={{
                mt: 2,
                mb: 8,
                mx: 6,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            ></Box>
          </Grid>
        </Box>
      </Container>
    </div>
  );
}

export default Createdigirecords;
