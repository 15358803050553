import { combineReducers } from "redux";
import {
  usersendotp,
  userlogin,
  doctorlogin,
  doctorsignup,
  searchnewsfeedlist,
  searchnewsdecription,
  customersignup,
  addresource,
  doctorresourcelist,
  doctorresourcedetails,
  resourcetypelist,
  animalsbuylist,
  animalsadoptlist,
  animalsmatchfindlist,
  animalsbuylistdetails,
  animalsadoptiondetails,
  animalsmatchdetails,
  RecentNewslist,
  resourcelistdetails,
  animalslist,
  resourcelist,
  addbuyanimals,
  addadoptionanimals,
  addmatchfindanimals,
  docinstantapproval,
  designationlist,
  districtlist,
  taluklist,
  Whatsuplink,
  verifybuysell,
  digipoultrylist,
  Feedformulationlist,
  usergetprofile,
  Adddoctorrating,
  addresourcerating,
  usereditrequest,
} from "./reducer";
export default combineReducers({
  usersendotp,
  userlogin,
  doctorlogin,
  doctorsignup,
  searchnewsfeedlist,
  searchnewsdecription,
  customersignup,
  addresource,
  doctorresourcelist,
  doctorresourcedetails,
  resourcetypelist,
  animalsbuylist,
  animalsadoptlist,
  animalsmatchfindlist,
  animalsbuylistdetails,
  animalsadoptiondetails,
  animalsmatchdetails,
  RecentNewslist,
  resourcelistdetails,
  animalslist,
  resourcelist,
  addbuyanimals,
  addadoptionanimals,
  addmatchfindanimals,
  docinstantapproval,
  designationlist,
  districtlist,
  taluklist,
  verifybuysell,
  Whatsuplink,
  digipoultrylist,
  Feedformulationlist,
  usergetprofile,
  Adddoctorrating,
  addresourcerating,
  usereditrequest,
});
