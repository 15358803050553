import React from "react";
import Container from "@mui/material/Container";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
  Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import faker from "faker";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Feed Formulation",
    },
  },
};

const labels = ["CP", "ME"];

export const data = {
  labels,
  datasets: [
    {
      label: "Standard",
      data: [10, 15],
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    {
      label: "Calculated",
      data: [1, 20],
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
};
function Charts() {
  let i = 0;

  const chartConfig = {
    responsive: true,
    legend: {
      display: true,
      position: "top",
      labels: {
        filter: function (legendItem, chartData) {
          i++;
          return i <= 6;
        },
      },
      onClick: (e) => e.stopPropagation(),
    },
    scales: {
      yAxes: [
        {
          barPercentage: 1.0,
          categoryPercentage: 1.0,
          ticks: {
            callback: function (value, index, values) {
              return value + " lei";
            },
            fontColor: "#75c0cc",
            beginAtZero: true,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            fontColor: "#75c0cc",
          },
          gridLines: {
            offsetGridLines: false,
          },
        },
      ],
    },
  };
  return (
    <div>
      <Container>
        <Bar options={options} data={data} />
      </Container>
    </div>
  );
}

export default Charts;
