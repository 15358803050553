import React from "react";
import { Chart } from "react-chartjs-2";
function CalculatedPrice() {
  const data = {
    datasets: [
      {
        label: "My First dataset",
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "rgb(255, 99, 132)",
        data: [0, 10, 5, 2, 20, 30, 45],
      },
    ],
  };
  return (
    <div>
      <Chart type="line" data={data} />
    </div>
  );
}

export default CalculatedPrice;
