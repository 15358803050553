import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import { Link ,  useNavigate } from "react-router-dom";
import { animalslist } from "../../Redux/Actions/actions";
import {
  adddigianimals
} from "../../Redux/Actions/actions";
import { BsPlusLg } from "react-icons/bs";
function CreateExistingClient() {
  const dispatch = useDispatch();
  const [breed, setBreed] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [species, setSpecies] = useState("");
  const [photo, setPhoto] = useState([""]);
  const [data, setData] = useState({

    photo: [],
  });
  const handlesubmit = (e) => {
    e.preventDefault();
  };
  useEffect(() => {
    dispatch(animalslist());
  }, []);
  const handleAddImage = () => {
    if (photo[photo.length - 1]) {
      photo.push("");
      const imageUrls = [...photo];
      setPhoto(imageUrls);
    }
  };
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const handleselectChange = (e) => {
    setBreed(e.target.value.breed);
    setSpecies(e.target.value.pet);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (data.photo.length > 0) {
      const formData = new FormData();
      Object.entries(data).map(([key, value]) => {
        if (key == "photo") {
          value.map((item, index) => {
            formData.append(`photo`, item);
          });
        } else {
          formData.append(key, value);
        }
      });
      setLoading(true);
      dispatch(adddigianimals(formData))
        .then((res) => {
          setLoading(false);
          if (res.data.data === "Resource added successfully") {
            navigate("/");
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err.response);
        });
    } else {
      setError("Please upload photo");
    }
  };

  const resourceimage3 = (e, index) => {
    if (e.target.files && e.target.files.length > 0) {
      data[e.target.name][index] = e.target.files[0];
      const updatedPhoto = [...data[e.target.name]];
      setData({
        ...data,
        [e.target.name]: updatedPhoto,
      });
      photo[index] = URL.createObjectURL(e.target.files[0]);
      const imageUrls = [...photo];
      setPhoto(imageUrls);
    }
  };

  const animallist = useSelector(({ animalslist }) => animalslist.payload);
  const err = useSelector(({ userlogin }) => userlogin.payload);
  return (
    <div>
      <section
        className="section banner"
        style={{ backgroundImage: "url('../Assests/upload/vet_bg.jpg')" }}
        data-img-width="1688"
        data-img-height="470"
        data-diff="100"
      ></section>
     <div className="page-title grey">
        <div className="container">
          <div className="title-area pull-left">
            <h2>Add Animals</h2>
            <div className="bread">
              <ol className="breadcrumb">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="active">Add New User</li>
              </ol>
            </div>
            {/* <!-- end bread --> */}
          </div>
          {/* <!-- /.pull-right --> */}
          <div className="search pull-right">
            <form>{/* <!-- /input-group --> */}</form>
          </div>
          {/* <!-- /.pull-right --> */}
          <div
            className="search pull-right mb-3 "
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
         Search Existing User
            <form onSubmit={handlesubmit}>
              <div>
                <div className="input-group">
                  <input
                    className="form-control"
                    onChange={(e) => setSearch(e.target.value)}
                    name="search"
                    type="search"
                    placeholder=" Search..."
                  />
                  <span className="input-group-btn">
                    <button type="submit" className="btn btn-primary">
                      <i className="fa fa-search"></i>
                    </button>
                  </span>
                </div>
              </div>
              {/* <!-- /input-group --> */}
            </form>
          </div>
        </div>
      </div>

      <Container>
        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
         
          
                   <Grid item xs={12} sm={4} md={6}>
              <Grid item xs={12} sm={12} md={12}>
                <Box
                  sx={{
                    mt:20,
                    mx: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
              
                    <Link to="/digirecords/AddNewUser"> <Button component="span"  style={{
                         border:"3px solid #e74c3c",
                          display:"flex",
                          justifyContent:"center",
                          flexDirection:"column",
                          width:'100px',height:"100px",

                        borderRadius:"20px",
                        }}>

                      <BsPlusLg style={{
                        fontSize:"51px",color:"#e74c3c",
                           }} />
                         
                        </Button>
                        </Link>
                    

                   
                  
                  <span
                    style={{
                      color: "red",
                      marginTop: "10px",
                    }}
                  >
                    {err ? err : false}
                  </span>
                  <h4 style={{color:"#e74c3c",fontWeight:"600"}}>Add new</h4>
                </Box>
              </Grid>
            </Grid>
                   
          </Grid>
        </Box>
      </Container>
    </div>
  );
}

export default CreateExistingClient;
