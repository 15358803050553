import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Grid,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Container,
  Card,
  Select,
  Button,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNavigate, Link } from "react-router-dom";

function AddDrugIndex() {
  const [search, setSearch] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    e.currentTarget.reset();
    console.log(search);
  };
  const handleChange = (e) => {};
  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }
  const rows = [
    createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
    createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
    createData("Eclair", 262, 16.0, 24, 6.0),
    createData("Cupcake", 305, 3.7, 67, 4.3),
    createData("Gingerbread", 356, 16.0, 49, 3.9),
  ];
  return (
    <div>
      <section
        className="section banner"
        style={{ backgroundImage: "url('../Assests/upload/vet_bg.jpg')" }}
        data-img-width="1688"
        data-img-height="470"
        data-diff="100"
      ></section>
      <div class="page-title grey">
        <div class="container">
          <div class="title-area pull-left">
            <h2> Drug Index</h2>
            <div class="bread">
              <ol class="breadcrumb">
                <li>
                  <a href="/">Home</a>
                </li>
                <li class="active"> Drug Index</li>
              </ol>
            </div>
          </div>
          <div className="search pull-right mb-3 ">
            <form onSubmit={handleSubmit}>
              <div>
                <div className="input-group">
                  <input
                    className="form-control"
                    name="search"
                    onChange={(e) => setSearch(e.target.value)}
                    type="search"
                    placeholder=" Search..."
                  />
                  <span className="input-group-btn">
                    <button type="submit" className="btn btn-primary">
                      <i className="fa fa-search"></i>
                    </button>
                  </span>
                </div>
              </div>
              {/* <!-- /input-group --> */}
            </form>
          </div>
        </div>
      </div>
      <Container className="ml-5 mr-5 my-5 py-5">
        <Card>
          <Box component="form" onSubmit={handleSubmit}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                sx={{
                  mt: 8,
                  mx: 4,
                }}
              >
                <div style={{ fontWeight: "bold" }}>Drug Index Calculator</div>
                <br />
                <label className="ml-3" style={{ fontWeight: "bold" }}>
                  Action
                </label>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  type="text"
                  onChange={handleChange}
                  id="Action"
                  label="Action"
                  name="Action"
                />

                <br />
                <br />
                <TableContainer component={Paper}>
                  <label className="ml-3" style={{ fontWeight: "bold" }}>
                    Dose
                  </label>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Species </TableCell>
                        <TableCell align="right">1/m</TableCell>
                        <TableCell align="right">1/v</TableCell>
                        <TableCell align="right">s/c</TableCell>
                        <TableCell align="right">oral</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow
                          key={row.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row.name}
                          </TableCell>
                          <TableCell align="right">{row.calories}</TableCell>
                          <TableCell align="right">{row.fat}</TableCell>
                          <TableCell align="right">{row.carbs}</TableCell>
                          <TableCell align="right">{row.protein}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <br />
                  <br />
                  <label className="ml-3" style={{ fontWeight: "bold" }}>
                    Availability
                  </label>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Brand</TableCell>
                        <TableCell align="right">Type</TableCell>
                        <TableCell align="right">Trade Name</TableCell>
                        <TableCell align="right">Presentative</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow
                          key={row.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row.name}
                          </TableCell>
                          <TableCell align="right">{row.calories}</TableCell>
                          <TableCell align="right">{row.fat}</TableCell>
                          <TableCell align="right">{row.carbs}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <label className="ml-3 mt-5" style={{ fontWeight: "bold" }}>
                  Note
                </label>
                <TextField
                  style={{ marginBottom: "50px" }}
                  margin="normal"
                  required
                  fullWidth
                  type="text"
                  onChange={handleChange}
                  id="Action"
                  label="Pregnancy Safe"
                  name="Action"
                />
              </Box>
            </Grid>
            {
              // <Box
              //   className="pb-5 mb-5 mt-3 pt-3"
              //   sx={{
              //     mb: 8,
              //     mx: 4,
              //     display: "flex",
              //     flexDirection: "column",
              //     alignItems: "center",
              //   }}
              // >
              //   <Button
              //     type="submit"
              //     fullWidth
              //     style={{ backgroundColor: "#ED6C10", color: "white" }}
              //   >
              //     Submit
              //   </Button>
              //   <Grid container className="mt-2">
              //     <Grid item xs></Grid>
              //   </Grid>
              //   <Grid />
              // </Box>
            }
          </Box>
        </Card>
      </Container>
    </div>
  );
}

export default AddDrugIndex;
