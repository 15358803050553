import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import PetsIcon from "@mui/icons-material/Pets";
import Typography from "@mui/material/Typography";
import { useParams, useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { verifybuysell } from "../../Redux/Actions/actions";
const theme = createTheme();

function Animaladoption() {
  let navigate = useNavigate();
  const { id } = useParams();
  const [load, setLoad] = useState(false);
  const dispatch = useDispatch();
  const sellId = localStorage.getItem("animalsellid");
  const [userdata, setUserdata] = useState({
    id: sellId,
    phone: id,
    otp: "",
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoad(true);
    e.currentTarget.reset();
    navigate("/animals/SuccessPage");
    // dispatch(verifybuysell(userdata))
    //   .then((res) => {
    //     setLoad(false);
    //     if (res.message === "success") {
    //     }
    //   })
    //   .catch((err) => {
    //     setLoad(false);
    //     console.log(err);
    //   });
  };
  const handleChange = (e) => {
    setUserdata({
      ...userdata,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div>
      <>
        <ThemeProvider theme={theme}>
          <Grid container component="main" sx={{ height: "100vh" }}>
            <CssBaseline />
            <Grid
              item
              xs={false}
              sm={4}
              md={7}
              sx={{
                backgroundImage: `url(${"https://image.cnbcfm.com/api/v1/image/106686172-1598966433320-gettyimages-1152439648-istockalypse-home-office-00062.jpeg?v=1599013160"})`,
                backgroundRepeat: "no-repeat",
                backgroundColor: (t) =>
                  t.palette.mode === "light"
                    ? t.palette.grey[100]
                    : t.palette.grey[900],
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
            <Grid
              item
              xs={12}
              sm={8}
              md={5}
              component={Paper}
              elevation={6}
              square
              style={{ boxShadow: "none" }}
            >
              <Box
                sx={{
                  my: 8,
                  mx: 4,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                  <PetsIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Animal Adoption
                </Typography>
                <Box
                  component="form"
                  Validate
                  onSubmit={handleSubmit}
                  sx={{ mt: 1 }}
                  style={{ width: "100%" }}
                >
                  {load ? (
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      type="number"
                      disabled
                      id="number"
                      label="Enter OTP"
                      name="otp"
                    />
                  ) : (
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      type="number"
                      onChange={handleChange}
                      id="number"
                      label="Enter OTP"
                      name="otp"
                    />
                  )}
                  {load ? (
                    <Button
                      disabled
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                    >
                      Loading
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                    >
                      Verify
                    </Button>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </ThemeProvider>
      </>
    </div>
  );
}

export default Animaladoption;
