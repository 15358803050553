import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MedicationIcon from "@mui/icons-material/Medication";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import docimage from "./doc.jpg";
import { doctorlogin } from "../../Redux/Actions/actions";

const theme = createTheme();
function Doctorlogin() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userdata, setUserdata] = useState({
    phone: "",
    otp: "",
  });
  const [error, setError] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    if (userdata.phone.length != 10) {
      setError("Please enter valid phone number");
    } else {
      e.currentTarget.reset();
      navigate("/Doctorotp/" + userdata.phone);
      // dispatch(userlogin(userdata)).then((res) => {
      //   console.log(res);
      // });
    }
  };
  const handleChange = (e) => {
    setUserdata({
      ...userdata,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div>
      <>
        <ThemeProvider theme={theme}>
          <Grid container component="main" sx={{ height: "100vh" }}>
            <CssBaseline />
            <Grid
              item
              xs={false}
              sm={4}
              md={7}
              sx={{
                backgroundImage: `url(${docimage})`,
                backgroundRepeat: "no-repeat",
                backgroundColor: (t) =>
                  t.palette.mode === "light"
                    ? t.palette.grey[100]
                    : t.palette.grey[900],
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
            <Grid
              item
              xs={12}
              sm={8}
              md={5}
              component={Paper}
              elevation={6}
              square
              style={{ boxShadow: "none" }}
            >
              <Box
                sx={{
                  my: 8,
                  mx: 4,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                  <MedicationIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Doctor Login
                </Typography>
                <Box
                  component="form"
                  Validate
                  onSubmit={handleSubmit}
                  sx={{ mt: 1 }}
                  style={{ width: "100%" }}
                >
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    type="number"
                    onChange={handleChange}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 10);
                    }}
                    id="number"
                    label="Mobile Number"
                    name="phone"
                  />
                  {error ? (
                    <span
                      style={{
                        color: "red",
                        marginTop: "10px",
                      }}
                    >
                      {error}
                    </span>
                  ) : (
                    false
                  )}
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Send OTP
                  </Button>
                  <Grid container>
                    <Grid item xs></Grid>
                    <Grid item>
                      Don't have an account yet?&nbsp;
                      <Link
                        to="/doctorsignup"
                        style={{ color: "#FF8C00", fontWeight: "bold" }}
                      >
                        Sign Up
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </ThemeProvider>
      </>
    </div>
  );
}

export default Doctorlogin;
