import React from "react";
import imagee from "./NotFoundimage.svg";
function NotFound() {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src="https://img.freepik.com/free-vector/group-different-cute-pets_52683-37216.jpg?w=2000"
          style={{
            width: "300px",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
        />
        <h1 style={{ textAlign: "center", fontWeight: "bold" }}>
          No Page Found
        </h1>
      </div>
    </>
  );
}

export default NotFound;
