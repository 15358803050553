import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import PetsIcon from "@mui/icons-material/Pets";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { Link, useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import catimage from "./cat.jpg";
import { customersignup, animalslist } from "../../Redux/Actions/actions";
const theme = createTheme();
function Usersignup() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [breed, setBreed] = useState([]);
  const [species, setSpecies] = useState("");
  const [userdata, setUserdata] = useState({
    name: "",
    phone: "",
    species: "",
    breed: "",
    petName: "",
  });
  useEffect(() => {
    dispatch(animalslist());
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    e.currentTarget.reset();
    dispatch(customersignup({ ...userdata, species: species })).then((res) => {
      if (res.data.data == "Sms sent to your mobile number") {
        navigate("/Userotp/" + userdata.phone);
      }
    });
  };
  const handleselectChange = (e) => {
    setBreed(e.target.value.breed);
    setSpecies(e.target.value.pet);
  };
  const handleChange = (e) => {
    setUserdata({
      ...userdata,
      [e.target.name]: e.target.value,
    });
  };
  const animallist = useSelector(({ animalslist }) => animalslist.payload);
  const usererror = useSelector(({ customersignup }) => customersignup.payload);
  let err = usererror?.response?.data?.message[0];
  return (
    <div>
      <>
        <ThemeProvider theme={theme}>
          <Grid container component="main" sx={{ height: "100vh" }}>
            <CssBaseline />
            <Grid
              item
              xs={false}
              sm={4}
              md={7}
              sx={{
                backgroundImage: `url(${catimage})`,
                backgroundRepeat: "no-repeat",
                backgroundColor: (t) =>
                  t.palette.mode === "light"
                    ? t.palette.grey[100]
                    : t.palette.grey[900],
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
            <Grid
              item
              xs={12}
              sm={8}
              md={5}
              component={Paper}
              elevation={6}
              square
              style={{ boxShadow: "none" }}
            >
              <Box
                sx={{
                  my: 8,
                  mx: 4,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                  <PetsIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  User Signup
                </Typography>
                <Box
                  component="form"
                  Validate
                  onSubmit={handleSubmit}
                  sx={{ mt: 1 }}
                >
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    onChange={handleChange}
                    id="name"
                    label="Name"
                    name="name"
                    autoComplete="name"
                    autoFocus
                  />
                  <TextField
                    margin="normal"
                    required
                    type="number"
                    fullWidth
                    onChange={handleChange}
                    id="phone"
                    label="Phone"
                    name="phone"
                    autoComplete="phone"
                    autoFocus
                  />

                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Species
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Species"
                      onChange={handleselectChange}
                    >
                      <MenuItem>Select Species</MenuItem>
                      {Array.isArray(animallist) && animallist.length > 0
                        ? animallist.map((list) => (
                            <MenuItem value={list}>{list?.pet}</MenuItem>
                          ))
                        : false}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth style={{ marginTop: "10px" }}>
                    <InputLabel id="demo-simple-select-label">Breed</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="breed"
                      name="breed"
                      onChange={handleChange}
                    >
                      <MenuItem selected>Select Breed</MenuItem>
                      {breed?.length > 0
                        ? breed?.map((list) => (
                            <MenuItem value={list?.breed}>
                              {list?.breed}
                            </MenuItem>
                          ))
                        : false}
                    </Select>
                  </FormControl>

                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    onChange={handleChange}
                    name="petName"
                    label="Pet Name"
                    type="petName"
                    id="petName"
                    autoComplete="current-password"
                  />
                  <span
                    style={{
                      color: "red",
                      marginTop: "10px",
                    }}
                  >
                    {err ? err : false}
                  </span>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Sign Up
                  </Button>
                  <Grid container>
                    <Grid item xs></Grid>
                    <Grid item>
                      If you already have an account? &nbsp;
                      <Link
                        to="/userlogin"
                        style={{ color: "#FF8C00", fontWeight: "bold" }}
                      >
                        Login here
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </ThemeProvider>
      </>
    </div>
  );
}

export default Usersignup;
