import React from "react";
import logo from "../Assests/images/Vector.svg";
import { Link } from "react-router-dom";
import { BsFacebook, BsWhatsapp, BsInstagram, BsTwitter } from "react-icons/bs";

const Footer = () => {
  const today = new Date();
  const year = today.getFullYear();
  const bolderText = {
    fontSize: "bold",
  };
  return (
    <div>
      <footer className="footer dark">
        <div className="container">
          <div className="row module-wrapper">
            <div className="col-md-3 col-sm-6">
              <div className="widget">
                <div className="text-center">
                  <a className="navbar-brand" href="/">
                    <img src={logo} alt="Ova" style={{ width: "50px" }} />
                    <span
                      style={{
                        display: "flex",
                        marginLeft: "20px",
                        alignItems: "center",
                        fontSize: "30px",
                        fontWeight: "bold",
                      }}
                    >
                      Vetsline
                    </span>
                  </a>
                  <p style={{ textAlign: "justify", fontWeight: "bold" }}>
                    Veterinarians provide treatment for animals that is similar
                    to the services a physician provides to humans.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="col-md-3 col-sm-6"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="widget">
                <div className="widget-title" style={{ marginBottom: "10px" }}>
                  <h4>Animals</h4>
                </div>
                <ul>
                  <li>
                    <Link style={{ fontWeight: "bold" }} to="/animals/buysell">
                      Buy or Sell
                    </Link>
                  </li>
                  <li>
                    <Link style={{ fontWeight: "bold" }} to="/animals/adoption">
                      Adoption
                    </Link>
                  </li>
                  <li>
                    <Link
                      style={{ fontWeight: "bold" }}
                      to="/animals/matchfind"
                    >
                      Match Find
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-md-3 col-sm-6 "
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="widget">
                <div className="widget-title" style={{ marginBottom: "10px" }}>
                  <h4>Calculator</h4>
                </div>
                <ul>
                  <li>
                    <Link
                      style={{ fontWeight: "bold" }}
                      to="/guide/AddDoseCalculator"
                    >
                      Dose Calculator
                    </Link>
                  </li>
                  <li>
                    <Link
                      style={{ fontWeight: "bold" }}
                      to="/guide/AddDrugIndex"
                    >
                      Drug Index Calculator{" "}
                    </Link>
                  </li>
                  <li>
                    <Link
                      style={{ fontWeight: "bold" }}
                      to="/guide/FormProjectGuide"
                    >
                      Form Project Guide
                    </Link>
                  </li>
                  <li>
                    <Link
                      style={{ fontWeight: "bold" }}
                      to="/guide/AddFeedFormulation"
                    >
                      Feed Formulation
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 footercontact">
              <div className="widget">
                <div className="widget-title">
                  <h4>Contact </h4>
                </div>
                <ul>
                  <li>
                    <a href="mailto:vetslinecommunity@gmail.com">
                      <i className="fa fa-envelope"></i>
                      vetslinecommunity@gmail.com
                    </a>
                  </li>
                  <li>
                    <a href="tel:9444830732" style={{ fontFamily: "none" }}>
                      <i className="fa fa-phone"></i>+91 94448 30732
                    </a>
                  </li>
                  <br />
                  <li
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      fontSize: "20px",
                    }}
                  >
                    <a>
                      <BsFacebook />
                    </a>
                    <a>
                      <BsInstagram />
                    </a>
                    <a>
                      <BsWhatsapp />
                    </a>
                    <a>
                      <BsTwitter />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <section className="copyright">
        <div className="container">
          <div className="row">
            <div className="col-md-6 text-left">
              <p>copyright © {year} Vetsline. All right reserved</p>
            </div>
            <div className="col-md-6 text-right">
              <ul className="list-inline">
                <li>
                  <a href="#">Terms & Condition</a>
                </li>
                <li>
                  <a href="#">Privacy Policy</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Footer;
