import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  Grid,
  Box,
  TextField,
  Container,
  Typography,
  Button,
} from "@mui/material";
import { docinstantapproval } from "../../../Redux/Actions/actions";
function InstantApproval() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const UserId = localStorage.getItem("Id");
  const [data, setData] = useState({
    id: UserId,
    message: "",
    email: "",
  });
  const [error, setError] = useState("");
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    e.currentTarget.reset();
    dispatch(docinstantapproval(data))
      .then((res) => {
        if (res.data.data === "Success") navigate("/");
      })
      .catch((err) => {
        setError(err.response.data.data);
      });
  };
  return (
    <div>
      <section
        className="section banner"
        style={{ backgroundImage: "url('../Assests/upload/vet_bg.jpg')" }}
        data-img-width="1688"
        data-img-height="470"
        data-diff="100"
      ></section>
      <div className="page-title grey">
        <div className="container">
          <div className="title-area pull-left">
            <h2> Approval Request</h2>
            <div className="bread">
              <ol className="breadcrumb">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="active"> Approval Request</li>
              </ol>
            </div>
            {/* <!-- end bread --> */}
          </div>
          {/* <!-- /.pull-right --> */}
          <div className="search pull-right">
            <form>{/* <!-- /input-group --> */}</form>
          </div>
          {/* <!-- /.pull-right --> */}
        </div>
      </div>
      <Container className="mb-5 pb-5">
        <Grid item xs={12} sm={12} md={12}>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              mt: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h4"> Approval Request</Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              type="email"
              onChange={handleChange}
              id="email"
              label="Email"
              name="email"
            />
            <TextField
              className="mb-5 pb-5"
              margin="normal"
              required
              fullWidth
              type="text"
              onChange={handleChange}
              id="email"
              label="Message"
              name="message"
            />
            <Button
              type="submit"
              style={{
                marginBottom: "10px",
                backgroundColor: "#ED6C10",
                fontWeight: "bold",
              }}
              fullWidth
              variant="contained"
            >
              Submit
            </Button>
          </Box>
        </Grid>
      </Container>
    </div>
  );
}

export default InstantApproval;
