import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Rating from "@mui/material/Rating";
import Button from "@mui/material/Button";

import { Carousel } from "react-responsive-carousel";
import Stack from "@mui/material/Stack";
import {
  resourcelistdetails,
  Adddoctorrating,
} from "../../Redux/Actions/actions";
import { CircularProgress } from "@mui/material";
import "swiper/css";
import "swiper/css/navigation";
const adminToken =
  localStorage.getItem("AuthUserToken") || localStorage.getItem("AuthDoctoken");

export const Raating = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [valuer, setValuer] = useState("");
  const [show, setShow] = useState(false);
  const handlereview = (e) => {
    e.preventDefault();
    // dispatch(Adddoctorrating({ doctorId: id, rating: valuer }));
    console.log("Review Added");
  };
  return (
    <section
      style={{
        border: "1px solid lightgrey",
        borderRadius: "10px",
        padding: "15px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Stack spacing={1}>
        <h2> Your experience with his doctor</h2>
        <Rating
          name="half-rating-read"
          defaultValue={1}
          onChange={(e) => setValuer(e.target.value)}
        />
        <Button
          style={{ color: "white", backgroundColor: "orange" }}
          onClick={handlereview}
        >
          Submit review
        </Button>
      </Stack>
      <br />
    </section>
  );
};

const rating = true;
function Resourcedetails() {
  const [show, setShow] = useState(false);
  const [ind, setInd] = useState(0);
  const [load, setLoad] = useState(true);
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    dispatch(resourcelistdetails({ id: id })).then((res) => {
      console.log(res);
      setLoad(false);
    });
  }, []);
  const handlereview = () => {
    setShow(true);
  };
  const doctordetails = useSelector(
    ({ resourcelistdetails }) => resourcelistdetails.payload
  );
  var tm = "tel:" + doctordetails?.mobile;
  return (
    <div>
      {load ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
            }}
          >
            <CircularProgress size={50} />
          </div>
        </>
      ) : (
        <>
          <div class="page-title grey">
            <div class="container">
              <div class="title-area pull-left">
                <h2>{doctordetails?.resource_type}</h2>
                <div class="bread">
                  <ol class="breadcrumb">
                    <li>
                      <a>Home</a>
                    </li>
                    <li class="active">{doctordetails?.resource_type}</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <section class="section white">
            <div class="container">
              <div class="row">
                <div id="content" class="col-md-12 col-sm-12 col-xs-12">
                  <div class="row">
                    <div class="col-md-5 col-sm-5 col-xs-12">
                      <div class="product-images">
                        <a>
                          {
                            // <Carousel emulateTouch={true} swipeable={true}>
                            //   {Array.isArray(doctordetails?.photos) &&
                            //   doctordetails?.photos?.length > 0
                            //     ? doctordetails?.photos?.map(
                            //         (dataimage, index) => (
                            //         )
                            //       )
                            //     : false}
                            // </Carousel>
                          }
                          <img
                            src={doctordetails?.photos[0]}
                            alt="Animal Image"
                            style={{ width: "100%" }}
                          />
                        </a>
                      </div>
                    </div>

                    <div class="col-md-7 col-sm-7 col-xs-12">
                      <div class="shop-desc">
                        <h3 style={{ textTransform: "capitalize" }}>
                          {doctordetails?.name}
                        </h3>
                        <small style={{ textTransform: "capitalize" }}>
                          {doctordetails?.owner_name}
                        </small>
                        <div class="shopmeta">
                          <span style={{ fontWeight: "bold" }}>
                            <strong>
                              Phone &nbsp; &nbsp; &nbsp; : &nbsp;{" "}
                            </strong>
                            <a href={tm} style={{ fontFamily: "none" }}>
                              {doctordetails?.mobile}
                            </a>
                          </span>
                          <span style={{ fontWeight: "bold" }}>
                            <strong>Address &nbsp; : &nbsp;</strong>
                            <a style={{ textTransform: "capitalize" }}>
                              {doctordetails?.street} {doctordetails?.taluk}
                              {doctordetails?.city} &nbsp;
                            </a>
                            ,
                            <a style={{ textTransform: "capitalize" }}>
                              erode{" "}
                            </a>
                            ,
                            <a style={{ textTransform: "capitalize" }}>
                              {doctordetails?.district} -
                              {doctordetails?.pincode}
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {adminToken ? (
                <Button
                  onClick={handlereview}
                  style={{
                    backgroundColor: "orange",
                    color: "white",
                    fontWeight: "bold",
                    marginBottom: "20px",
                  }}
                >
                  Add Ratings
                </Button>
              ) : (
                false
              )}
            </div>
            <div>
              {show ? <Raating /> : false}
              <hr class="invis" />
            </div>
            <hr class="invis" />
          </section>
        </>
      )}
    </div>
  );
}

export default Resourcedetails;
